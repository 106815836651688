import DatabaseObject, {
  DatabaseObjectConstructorType,
} from "./database-object";

export interface ContractorConstructorType
  extends DatabaseObjectConstructorType {
  companyName: string;
  contactPerson?: string;
  email?: string;
  address?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  phoneNumber?: string;
  trade?: string;
}

export class Contractor extends DatabaseObject {
  companyName: string;
  contactPerson?: string;
  email?: string;
  address?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  phoneNumber?: string;
  trade?: string;

  constructor(data: ContractorConstructorType) {
    super(data);

    this.companyName = data.companyName;

    if (data.contactPerson) this.contactPerson = data.contactPerson;
    if (data.email) this.email = data.email;
    if (data.address) this.address = data.address;
    if (data.city) this.city = data.city;
    if (data.state) this.state = data.state;
    if (data.zipcode) this.zipcode = data.zipcode;
    if (data.phoneNumber) this.phoneNumber = data.phoneNumber;
    if (data.trade) this.trade = data.trade;
    if (data.city) this.city = data.city;
  }
}
