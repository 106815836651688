import { useCallback } from "react";
import Auth from "@aws-amplify/auth";

async function getSessionTokens() {
  let session = null;
  try {
    session = await Auth.currentSession();
    if (!session) throw new Error("No session");

    const idToken = session.getIdToken().getJwtToken();
    const accessToken = session.getAccessToken().getJwtToken();
    const expiresAt = session.getAccessToken().getExpiration();

    return { idToken, accessToken, expiresAt };
  } catch (e) {
    console.log(e);
    throw new Error("Could not get session tokens");
  }
}

const baseUrl = process.env.REACT_APP_API_URL;

const baseHeaders = {
  "Content-Type": "application/json",
};

const fetchData = async (
  url: string,
  method: string,
  headers: { "Content-Type": string } | null,
  body: any
) => {
  const sessionTokens = await getSessionTokens();

  const response = await fetch(`${baseUrl}/${url}`, {
    method,
    headers: {
      Authorization: `Bearer ${sessionTokens.idToken}`,
      ...headers,
    },
    body,
  });

  // If the response is not ok, throw an error
  if (!response.ok) {
    // If the response is unauthorized, logout the user
    if (response.status === 401) {
      window.location.href = "/login";
    }
    const error = await response.json();
    if (response.status === 409) {
      setTimeout(() => {
        window.location.reload();
      }, 0);
      throw new Error(error.message);
    }
    throw new Error(error.message);
  }

  const data = await response.json();
  return data;
};

function fetchGETTyped<T>(url: string) {
  return fetchData(url, "GET", baseHeaders, undefined) as Promise<T>;
}

function fetchPOSTTyped<T>(url: string, body: any) {
  return fetchData(
    url,
    "POST",
    baseHeaders,
    JSON.stringify(body)
  ) as Promise<T>;
}

export function useApi() {
  const fetchGET = useCallback(
    (url: string) => fetchData(url, "GET", baseHeaders, undefined),
    [fetchData]
  );

  const fetchGETList = useCallback(
    (url: string, queryParams: QueryParams = {}) => {
      const searchParams = new URLSearchParams();

      Object.keys(queryParams).forEach((key) => {
        if (Array.isArray(queryParams[key])) {
          (queryParams[key] as (null | string | number | boolean)[]).forEach(
            (value) => {
              searchParams.append(
                key,
                value !== null ? value.toString() : "null"
              );
            }
          );
        } else {
          searchParams.append(key, queryParams[key].toString());
        }
      });
      const urlWithParams = [url, searchParams.toString()].join("?");

      return fetchGET(urlWithParams);
    },
    [fetchGET]
  );

  const fetchPOST = useCallback(
    (url: string, body = {}) =>
      fetchData(url, "POST", baseHeaders, JSON.stringify(body)),
    [fetchData]
  );
  const fetchPOSTMultiple = useCallback(
    (url: string, body: any) => fetchData(url, "POST", null, body),
    [fetchData]
  );
  const fetchPUT = useCallback(
    (url: string, body = {}) =>
      fetchData(url, "PUT", baseHeaders, JSON.stringify(body)),
    [fetchData]
  );
  const fetchPUTMultiple = useCallback(
    (url: string, body: any) => fetchData(url, "PUT", null, body),
    [fetchData]
  );
  const fetchDELETE = useCallback(
    (url: string) => fetchData(url, "DELETE", baseHeaders, undefined),
    [fetchData]
  );

  return {
    fetchGET,
    fetchGETList,
    fetchPOST,
    fetchPOSTMultiple,
    fetchPUT,
    fetchPUTMultiple,
    fetchDELETE,

    fetchGETTyped,
    fetchPOSTTyped,
  };
}

export interface QueryParams {
  [key: string]:
    | string
    | number
    | boolean
    | (null | string | number | boolean)[];
}

export interface GetListInputType {
  skip?: number;
  take?: number;
  orderBy?: string;
}

export type GetListOutputType<T> = {
  items: T[];
  total: number;
};
