import React, { useEffect, useState, useCallback } from "react";
import { GetListOutputType } from "../hooks";
import resizePhoto from "../utils/resize-photo";
import handleSaveImage from "../utils/save-image";

interface usePhotosProps<T> {
  id?: number;
  callback?: () => void;
  fetchPhotosFunc?: (residentId: number) => Promise<GetListOutputType<T>>;
  PhotoClass: new ({}: object) => T;
  saveNewPhoto: (photo: T) => Promise<T>;
  saveRemovePhoto: (photo: T) => Promise<void>;
  saveUpdatePhoto: (photo: T) => Promise<void>;
}

export function usePhotosGeneric<T extends { id?: number }>({
  id,
  callback,
  fetchPhotosFunc,
  PhotoClass,
  saveNewPhoto,
  saveRemovePhoto,
  saveUpdatePhoto,
}: usePhotosProps<T>) {
  const [loading, setLoading] = useState(false);
  const [photos, _setPhotos] = useState<T[]>([]);
  const [removePhotos, _setRemovePhotos] = useState<T[]>([]);

  const setPhotos: React.Dispatch<React.SetStateAction<T[]>> = (_photos) => {
    _setPhotos(_photos);
  };

  const setRemovePhotos: React.Dispatch<React.SetStateAction<T[]>> = (
    _removePhotos
  ) => {
    _setRemovePhotos(_removePhotos);
  };

  const fetchPhotos = useCallback(async () => {
    setPhotos([]);
    setRemovePhotos([]);

    if (!id) return;
    if (!fetchPhotosFunc) return;

    setLoading(true);

    const _photos = await fetchPhotosFunc(id);

    if (_photos) _setPhotos(_photos.items);

    setLoading(false);
  }, [fetchPhotosFunc, id]);

  useEffect(() => {
    fetchPhotos();
  }, [fetchPhotos]);

  const addPhoto = async (e: React.ChangeEvent<HTMLInputElement>) => {
    let file = e.target.files?.[0];

    if (!file) return;

    if (file.type.includes("image")) {
      file = await resizePhoto(file);
      handleSaveImage(file);
    }

    const url = URL.createObjectURL(file);

    const photo = new PhotoClass({
      file,
      fileName: file.name,
      fileType: file.type,
      url,
    });

    const newPhoto = await saveNewPhoto(photo);

    setPhotos([...photos, newPhoto]);

    callback && callback();
  };

  const removePhoto = async (photo: T) => {
    await saveRemovePhoto(photo);

    const newPhotos = photos.filter((p) => p !== photo);
    setPhotos(newPhotos);

    callback && callback();
  };

  const updatePhoto = async (photo: T) => {
    await saveUpdatePhoto(photo);

    const newPhotos = photos.map((p) => (p.id === photo.id ? photo : p));
    setPhotos(newPhotos);

    callback && callback();
  };

  return {
    loading,

    photos,
    removePhotos,

    addPhoto,
    removePhoto,
    updatePhoto,
  };
}
