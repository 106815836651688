import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Managers from "./Managers";
import AddManager from "./AddManager";
import EditManager from "./EditManager";

export default function ManagersRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Managers />} />
      <Route path="/add" element={<AddManager />} />
      <Route path="/view/:id" element={<EditManager />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}
