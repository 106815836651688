import React, { useState, useEffect } from "react";
import { User } from "../classes";
import { useMe } from "../store/me";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";
import { CommentModal } from "./CommentModal";
import DatabaseObject from "../classes/database-object";
import { Delete, Edit, MoreHoriz } from "@mui/icons-material";
import format from "date-fns/format";

interface CommentCardProps<T extends DatabaseObject & { creator?: User }> {
  item: T;
  onEdit: (item: T) => void;
  onDelete: (item: T) => void;
  getTextFieldValue: (item: T) => string;
}

function CommentCard<T extends DatabaseObject & { creator?: User }>({
  item,
  onEdit: _onEdit,
  onDelete: _onDelete,
  getTextFieldValue,
}: CommentCardProps<T>) {
  const { me } = useMe();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const onEdit = (_item: T) => {
    handleCloseMenu();
    _onEdit(_item);
  };

  const onDelete = (_item: T) => {
    handleCloseMenu();

    _onDelete(_item);
  };

  return (
    <Card
      sx={{
        width: "100%",
      }}
    >
      <CardContent>
        <Grid container justifyContent="space-between" wrap="nowrap">
          <Grid item container spacing={1}>
            {item.creator && (
              <Grid item>
                <Typography variant="body1">
                  {item.creator?.firstName} {item.creator?.lastName}
                </Typography>
              </Grid>
            )}
            <Grid item>
              <Typography variant="caption">
                {item.createdAt && format(new Date(item.createdAt), "PPpp")}

                {item.updatedAt !== item.createdAt && " (Edited)"}
              </Typography>
            </Grid>
          </Grid>

          {me?.id === item.creator?.id && (
            <Grid item>
              <IconButton
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <MoreHoriz fontSize="small" />
              </IconButton>
            </Grid>
          )}
        </Grid>

        <Typography variant="body2">{getTextFieldValue(item)}</Typography>

        <Menu
          id={`basic-menu-${item.id}`}
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
          MenuListProps={{
            "aria-labelledby": `basic-button-${item.id}`,
          }}
        >
          <MenuItem onClick={() => onEdit(item)}>
            <ListItemIcon>
              <Edit fontSize="small" />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>

          <Divider />

          <MenuItem onClick={() => onDelete(item)}>
            <ListItemIcon>
              <Delete fontSize="small" />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        </Menu>
      </CardContent>
    </Card>
  );
}

interface CommentBaseProps<T extends DatabaseObject & { creator?: User }> {
  labelPlural: string;
  labelSingular: string;
  id?: number;
  disabled?: boolean;
  items: T[];
  fetchItems: () => void;
  onDelete: (item: T) => Promise<void>;
  onSave: (item: T) => Promise<void>;
  newClass: (item: T, text?: string) => T;
  getTextFieldValue: (item: T) => string;
}

export function CommentBase<T extends DatabaseObject & { creator?: User }>({
  labelPlural,
  labelSingular,
  id,
  disabled,
  items,
  fetchItems,
  onDelete,
  onSave,
  newClass,
  getTextFieldValue,
}: CommentBaseProps<T>) {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  const [item, setItem] = useState<T>(newClass({} as T));

  const onAdd = () => {
    if (!id) return;
    setItem(newClass({} as T));
    setShowModal(true);
  };

  const onEdit = (_item: T) => {
    setItem(_item);
    setShowModal(true);
  };

  return (
    <Grid
      item
      container
      direction="column"
      spacing={1}
      sx={{
        paddingBottom: 1,
      }}
    >
      <Grid item>
        <Typography variant="h6">{labelPlural}</Typography>
      </Grid>

      {items?.map((i) => (
        <Grid item key={i.id}>
          <CommentCard
            item={i}
            onEdit={onEdit}
            onDelete={onDelete}
            getTextFieldValue={getTextFieldValue}
          />
        </Grid>
      ))}

      {items.length === 0 && (
        <Grid item>
          <Typography variant="h6" color="lightgray">
            No {labelPlural.toLowerCase()}
          </Typography>
        </Grid>
      )}

      {!disabled && (
        <Grid item container justifyContent="center" alignItems="center">
          <Button color="primary" variant="contained" onClick={onAdd}>
            Add {labelSingular}
          </Button>
        </Grid>
      )}

      {id && (
        <CommentModal<T>
          open={showModal}
          closeModal={() => setShowModal(false)}
          item={item}
          callback={fetchItems}
          onSave={onSave}
          newClass={newClass}
          label={labelSingular}
          name={labelSingular.toLowerCase()}
          getTextFieldValue={getTextFieldValue}
        />
      )}
    </Grid>
  );
}
