import React, { useState, useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { HeaderLink, LocationTable } from "../../../components";
import HomeIcon from "@mui/icons-material/Home";
import {
  useLocationsApi,
  GetLocationsInputType,
  GetLocationsOutputType,
} from "../../../apis/locations";
import { useMe } from "../../../store/me";
import { sortLocations } from "../../../utils/sortLocations";

export default function Locations() {
  const { isAdmin } = useMe();
  const { getLocations } = useLocationsApi();
  const [locations, setLocations] = useState<GetLocationsOutputType>({
    items: [],
    total: 0,
    totalAvailableBeds: 0,
  });

  const [page, setPage] = useState(0);
  const [take] = useState(100);
  const [searchParams, setSearchParams] = useSearchParams();

  // If user previously entered a search string, use that value for "search" variable below
  // When user navigates back to the activity page, their search string will be preserved in the UI
  const [search, setSearch] = useState(searchParams.get("search") ?? "");

  const fetchData = useCallback(async () => {
    const customQuery: GetLocationsInputType = {
      skip: page * take,
      take,
    };

    if (search.trim()) {
      customQuery.search = search.trim();
      setSearchParams({ search: search.trim() });
    } else {
      setSearchParams({});
    }

    const _locations = await getLocations({ ...customQuery });
    _locations.items = sortLocations(_locations.items);
    if (page === 0) {
      setLocations(_locations);
    } else {
      setLocations({
        ..._locations,
        items: [...locations.items, ..._locations.items],
      });
    }
  }, [page, take, search]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const rows = locations.items.map((location) => ({
    location,
  }));

  return (
    <>
      {isAdmin && (
        <HeaderLink icon={<HomeIcon />} text="Add Location" to="add" />
      )}
      <LocationTable
        rows={rows}
        total={locations.total}
        totalAvailableBeds={locations.totalAvailableBeds}
        setPage={setPage}
        search={search}
        setSearch={setSearch}
      />
    </>
  );
}
