import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Curfew from "./Curfew";

export default function ActivityRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Curfew />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}
