import { useCallback } from "react";
import {
  QueryParams,
  useApi,
  GetListInputType,
  GetListOutputType,
} from "../hooks";
import {
  WorkOrder,
  WorkOrderComment,
  WorkOrderConstructorType,
  WorkOrderPhoto,
} from "../classes";

export function useWorkOrdersApi() {
  const {
    fetchGETList,
    fetchGET,
    fetchPOST,
    fetchPOSTMultiple,
    fetchPUT,
    fetchDELETE,
  } = useApi();

  const getWorkOrder = useCallback(
    async (id: number) => {
      const wo = await fetchGET(`work-orders/${id}`);
      return new WorkOrder(wo);
    },
    [fetchGET]
  );

  const getWorkOrders = useCallback(
    async (queryParams?: QueryParams) => {
      const wos = await fetchGETList("work-orders", queryParams);
      wos.items = wos.items.map(
        (wo: WorkOrderConstructorType) => new WorkOrder(wo)
      );
      return wos;
    },
    [fetchGETList]
  );

  const addWorkOrder = useCallback(
    async (data: object) => {
      const wo = await fetchPOST("work-orders", data);
      return new WorkOrder(wo);
    },
    [fetchPOST]
  );

  const updateWorkOrder = useCallback(
    async (id: number, data: object) => {
      const wo = await fetchPUT(`work-orders/${id}`, data);
      return new WorkOrder(wo);
    },
    [fetchPUT]
  );

  const deleteWorkOrder = useCallback(
    (id: number) => fetchDELETE(`work-orders/${id}`),
    [fetchDELETE]
  );

  const getWorkOrderPhotos = useCallback(
    (id: number) => fetchGET(`work-orders/${id}/photos`),
    [fetchGET]
  );

  const addWorkOrderPhoto = useCallback(
    (id: number, data: object) =>
      fetchPOSTMultiple(`work-orders/${id}/photos`, data),
    [fetchPOSTMultiple]
  );

  const getWorkOrderComments = useCallback(
    async (id: number) => {
      const cs = (await fetchGET(
        `work-orders/${id}/comments`
      )) as GetWorkOrderCommentsOutputType;

      cs.items = cs.items.map((c) => new WorkOrderComment(c));

      return cs;
    },
    [fetchGET]
  );

  const addWorkOrderComment = useCallback(
    async (id: number, data: object) => {
      const c = (await fetchPOST(
        `work-orders/${id}/comments`,
        data
      )) as WorkOrderComment;

      return new WorkOrderComment(c);
    },
    [fetchPOST]
  );

  return {
    getWorkOrder,
    getWorkOrders,
    addWorkOrder,
    updateWorkOrder,
    deleteWorkOrder,
    getWorkOrderPhotos,
    addWorkOrderPhoto,
    getWorkOrderComments,
    addWorkOrderComment,
  };
}

export interface GetWorkOrdersInputType extends GetListInputType {
  locations?: number | number[] | string | string[] | (number | string)[];
  priorities?: string | string[];
  statuses?: string | string[];
  categories?: string | string[];
  search?: string;
}

export type GetWorkOrdersOutputType = GetListOutputType<WorkOrder>;

export type GetWorkOrderPhotosOutputType = GetListOutputType<WorkOrderPhoto>;

export type GetWorkOrderCommentsOutputType =
  GetListOutputType<WorkOrderComment>;
