const formatter = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export default function formatMoney(cents, hideCurrency = false) {
  if (hideCurrency) {
    return (cents / 100).toFixed(2);
  }
  return formatter.format(cents / 100);
}
