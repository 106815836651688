import { useCallback } from "react";
import {
  QueryParams,
  useApi,
  GetListInputType,
  GetListOutputType,
} from "../hooks";
import { Contractor, ContractorConstructorType } from "../classes/contractor";

export function useContractorsApi() {
  const { fetchGETList, fetchGET, fetchPOST, fetchPUT, fetchDELETE } = useApi();

  const getContractor = useCallback(
    async (id: number) => {
      const c = await fetchGET(`contractors/${id}`);
      return new Contractor(c);
    },
    [fetchGET]
  );

  const getContractors = useCallback(
    async (queryParams?: QueryParams): Promise<GetContractorsOutputType> => {
      const cs = await fetchGETList("contractors", queryParams);
      cs.items = cs.items.map(
        (c: ContractorConstructorType) => new Contractor(c)
      );
      return cs;
    },
    [fetchGETList]
  );

  const addContractor = useCallback(
    async (data: object) => {
      const c = await fetchPOST("contractors", data);
      return new Contractor(c);
    },
    [fetchPOST]
  );

  const updateContractor = useCallback(
    async (id: number, data: object) => {
      const c = await fetchPUT(`contractors/${id}`, data);
      return new Contractor(c);
    },
    [fetchPUT]
  );

  const deleteContractor = useCallback(
    (id: number) => fetchDELETE(`contractors/${id}`),
    [fetchDELETE]
  );

  return {
    getContractor,
    getContractors,
    addContractor,
    updateContractor,
    deleteContractor,
  };
}

export interface GetContractorsInputType extends GetListInputType {
  search?: string;
}

export type GetContractorsOutputType = GetListOutputType<Contractor>;
