import React from "react";
import format from "date-fns/format";
import { Grid, Typography } from "@mui/material";
import {
  RowType,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  TableSearchField,
  TableSelectedSectionButton,
} from "./index";
import { useCollectionsApi } from "../apis";

import { Collection } from "../classes/collection";
import { Location } from "../classes/location";
import { Resident } from "../classes/resident";

import { colors, tabHeight, bottomNavigationHeight } from "../constants";
import {
  faEye,
  faThumbsUp,
  faAlignLeft,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AuditFilter,
  CollectionLocationFilter,
  CollectionManagersFilter,
  TableFilterModal,
} from "./TableFilter";
import { formatMoney } from "../utils";

interface RecordProps {
  resident: Resident;
  location?: Location;
  collection: Collection;
}

interface HeaderStatsProps {
  total: number;
  paymentSum: number;
}

const HeaderStats = ({ total, paymentSum }: HeaderStatsProps) => (
  <Grid
    container
    justifyContent="space-between"
    sx={{ backgroundColor: colors.grey[6], px: 1 }}
  >
    <Typography variant="body2">Count: {total}</Typography>

    <Typography variant="body2">Total: {formatMoney(paymentSum)}</Typography>
  </Grid>
);

const Header = () => (
  <>
    <TableCell basis="200px">Name</TableCell>
    <TableCell basis="100px">Date Paid</TableCell>
    <TableCell basis="66px">Location</TableCell>
    <TableCell basis="57px">Check #</TableCell>
    <TableCell basis="100px">Collection</TableCell>
    <TableCell basis="43px">Notes</TableCell>
    <TableCell basis="49px">Delete</TableCell>
  </>
);

const EmptyView = () => (
  <Typography
    variant="h5"
    align="center"
    sx={{
      color: colors.grey[5],
      marginTop: 8,
    }}
  >
    No Collections
  </Typography>
);

interface RowProps {
  row: RowType;
  selected: boolean;
  onClick: () => void;
  tableBodyRef: any;
}

const Row = ({ row, ...props }: RowProps) => (
  <TableRow row={row} SelectedSectionElem={SelectedSection} {...props}>
    <TableCell basis="200px" semiBold>
      {row.resident && `${row.resident.lastName}, ${row.resident.firstName}`}
    </TableCell>
    <TableCell basis="100px" color={colors.grey[3]}>
      {row.collection &&
        row.collection.datePaid &&
        format(new Date(row.collection.datePaid), "P")}
    </TableCell>
    <TableCell basis="66px" color={colors.grey[3]}>
      {row.location && row.location.name}
    </TableCell>
    <TableCell basis="57px" color={colors.grey[3]}>
      {row.collection &&
        row.collection.payments &&
        row.collection.payments
          .filter((payment) => payment.checkNumber)
          .map((payment) => payment.checkNumber)
          .join(", ")}
    </TableCell>
    <TableCell basis="100px" color={colors.grey[3]}>
      {row.collection && formatMoney(row.collection.amountPaidOnlyCashCheck())}
    </TableCell>
    <TableCell basis="43px" color={colors.grey[3]}>
      {row.collection && row.collection.notes && (
        <FontAwesomeIcon icon={faAlignLeft} />
      )}
    </TableCell>
    <TableCell basis="49px" color={colors.grey[3]}>
      {row.collection && row.collection.isPendingDelete() ? (
        <FontAwesomeIcon icon={faTrash} />
      ) : (
        ""
      )}
    </TableCell>
  </TableRow>
);

interface SelectedSectionProps {
  row: RecordProps;
  onView: (row: RecordProps) => void;
  fetchData: () => Promise<void>;
}

const SelectedSection = ({ row, onView, fetchData }: SelectedSectionProps) => {
  const { updateCollection } = useCollectionsApi();

  const handleView = () => onView(row);

  const handleApprove = async () => {
    const newCollection = new Collection(row.collection);
    newCollection.approve();
    await updateCollection(
      newCollection.id as number,
      newCollection.prepareForApi()
    );

    await fetchData();
  };

  return (
    <>
      <TableSelectedSectionButton icon={faEye} onClick={handleView}>
        View
      </TableSelectedSectionButton>

      {!row.collection.isPendingDelete() && (
        <TableSelectedSectionButton icon={faThumbsUp} onClick={handleApprove}>
          Approve
        </TableSelectedSectionButton>
      )}
    </>
  );
};

interface AuditTableProps {
  rows: RecordProps[];
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  total: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  paymentSum: number;
  onView: (row: {
    resident?: Resident;
    location?: Location;
    collection?: Collection;
  }) => void;
  fetchData: () => Promise<void>;
}

export const AuditTable = ({
  rows,
  search,
  setSearch,
  total,
  setPage,
  paymentSum,
  onView,
  fetchData,
}: AuditTableProps) => {
  const filterContent = [
    AuditFilter,
    CollectionLocationFilter,
    CollectionManagersFilter,
  ];

  return (
    <Table
      style={{
        height: `calc(100vh - ${tabHeight} - ${bottomNavigationHeight})`,
      }}
    >
      <TableFilterModal
        filterContent={filterContent}
        callback={() => setPage(0)}
      />
      <TableSearchField
        value={search}
        onChange={(e) => {
          setPage(0);
          // If user selects from the existing search list,
          // value will be empty and textContent will have the search string
          e.target.value
            ? setSearch(e.target.value)
            : setSearch(e.target.textContent ?? "");
        }}
        filterContent={filterContent}
      />
      <HeaderStats total={total} paymentSum={paymentSum} />
      <TableHeader>
        <Header />
      </TableHeader>
      <TableBody
        rows={rows}
        total={total}
        RowElem={Row}
        EmptyViewElem={EmptyView}
        onLoadMore={() => setPage((page) => page + 1)}
        onView={onView}
        fetchData={fetchData}
      />
    </Table>
  );
};
