import DatabaseObject, {
  DatabaseObjectConstructorType,
} from "./database-object";
import { User } from "./user";

export interface UserPhotoConstructorType
  extends DatabaseObjectConstructorType {
  s3Key?: string;
  url?: string;
  caption?: string;
  user?: User;
  userId?: number;
  file?: File;
}

export class UserPhoto extends DatabaseObject {
  s3Key?: string;
  url?: string;
  caption = "";
  user?: User;
  userId?: number;

  file?: File;

  constructor(data?: UserPhotoConstructorType) {
    super(data);

    if (!data) return;

    if (data.s3Key) this.s3Key = data.s3Key;
    if (data.url) this.url = data.url;
    if (data.caption) this.caption = data.caption;
    if (data.user) this.user = new User(data.user);
    if (data.userId) this.userId = data.userId;
    if (data.file) this.file = data.file;
  }
}
