import React from "react";
import styled, { css } from "styled-components";
import { colors, roundCorners } from "../constants";
import { Typography } from "@mui/material";

const RadioButtonStyles = styled(({ className, children, active, onClick }) => {
  const newClassName = `${className} ${active ? "active" : ""}`;
  return (
    <div className={newClassName} onClick={onClick}>
      {children}
    </div>
  );
})`
  padding: 0 0.125rem;
  transition: 0.1s;
  color: ${colors.grey[2]};
  border: 1px solid transparent;
  border-radius: ${roundCorners[0]};
  cursor: pointer;
  font-size: 0.8rem;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  border-color: ${colors.grey[4]};

  &:hover {
    border-color: ${colors.grey[2]};
  }

  &.active {
    border-color: ${colors.green[3]};
    background-color: ${colors.green[3]};
    color: ${colors.font.light};
  }
`;

const RadioButton = ({
  children,
  active,
  onClick,
  value,
}: {
  children: JSX.Element | string;
  active: boolean;
  onClick: (arg0: string) => void;
  value: string;
}) => (
  <RadioButtonStyles
    onClick={() => {
      onClick(value);
    }}
    active={active}
  >
    <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
      {children}
    </Typography>
  </RadioButtonStyles>
);

export default RadioButton;
