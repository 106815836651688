import { useCallback } from "react";
import { useApi } from "../hooks";

export function useResidentReportsApi() {
  const { fetchPUT, fetchDELETE } = useApi();

  const updateResidentReport = useCallback(
    (id: number, body: object) => fetchPUT(`resident-reports/${id}`, body),
    [fetchPUT]
  );

  const deleteResidentReport = useCallback(
    (id: number) => fetchDELETE(`resident-reports/${id}`),
    [fetchDELETE]
  );

  return {
    updateResidentReport,
    deleteResidentReport,
  };
}
