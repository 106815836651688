import DatabaseObject, {
  DatabaseObjectConstructorType,
} from "./database-object";

import { Resident } from "./resident";

export interface DistrictConstructorType extends DatabaseObjectConstructorType {
  name: string;
  residents?: Resident[];
}

export class District extends DatabaseObject {
  name = "";
  residents: Resident[] = [];

  constructor(data?: DistrictConstructorType) {
    super(data);

    if (!data) return;

    this.name = data.name;
    if (data.residents)
      this.residents = data.residents.map((r) => new Resident(r));
  }
}
