import DatabaseObject, {
  DatabaseObjectConstructorType,
} from "./database-object";
import { User } from "./user";

export interface LocationConstructorType extends DatabaseObjectConstructorType {
  name: string;
  address: string;
  city: string;
  state: string;
  zipcode: string;
  phoneNumber: string;
  gender: string;
  code: string;
  notes?: string;
  houseManagers: User[];
  totalBeds: number;
  availableBeds: number;
  usedBeds: number;
  totalTopBunkBeds: number;
  isOutlander: boolean;
  includeAvailableBeds: boolean;
}

export class Location extends DatabaseObject {
  name = "";
  address = "";
  city = "";
  state = "";
  zipcode = "";
  phoneNumber = "";
  gender = "";
  code = "";
  notes?: string = "";
  houseManagers: User[] = [];
  totalBeds = 0;
  availableBeds = 0;
  usedBeds = 0;
  totalTopBunkBeds = 0;
  isOutlander = false;
  includeAvailableBeds = true;

  constructor(data?: LocationConstructorType) {
    super(data);

    if (!data) return;

    if (data.name) this.name = data.name;
    if (data.address) this.address = data.address;
    if (data.city) this.city = data.city;
    if (data.state) this.state = data.state;
    if (data.zipcode) this.zipcode = data.zipcode;
    if (data.phoneNumber) this.phoneNumber = data.phoneNumber;
    if (data.gender) this.gender = data.gender;
    if (data.code) this.code = data.code;
    if (data.notes) this.notes = data.notes;
    if (data.houseManagers) this.houseManagers = data.houseManagers;
    if (data.totalBeds) this.totalBeds = data.totalBeds;
    if (data.availableBeds) this.availableBeds = data.availableBeds;
    if (data.usedBeds) this.usedBeds = data.usedBeds;
    if (data.totalTopBunkBeds) this.totalTopBunkBeds = data.totalTopBunkBeds;
    if (data.isOutlander) this.isOutlander = data.isOutlander;
    if (data.includeAvailableBeds)
      this.includeAvailableBeds = data.includeAvailableBeds;
  }

  addHouseManager(houseManager: User) {
    this.houseManagers.push(houseManager);
  }
  removeHousemanagerIndex(index: number) {
    this.houseManagers.splice(index, 1);
  }
}
