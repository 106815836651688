import React, { useState, useCallback, useEffect } from "react";
import ManagerForm from "../../../components/ManagerForm";
import { useUsersApi } from "../../../apis";
import { useParams, useNavigate } from "react-router-dom";
import { User } from "../../../classes";
import { useGlobalLoading } from "../../../components";

export default function EditManager() {
  const { getUser, updateUser, deleteUser } = useUsersApi();
  const { id } = useParams();
  const navigate = useNavigate();

  const { setGlobalLoading } = useGlobalLoading();

  const [manager, setManager] = useState(new User());

  const fetchManager = useCallback(async () => {
    setGlobalLoading(true);
    const manager = await getUser(Number(id));
    setManager(new User(manager));
    setGlobalLoading(false);
  }, [id, getUser]);

  useEffect(() => {
    fetchManager();
  }, [fetchManager]);

  const onSave = async () => {
    try {
      await updateUser(Number(id), manager);
      navigate(-1);
    } catch (e) {
      if (e instanceof Error) {
        alert(e.message);
      }
    }
  };

  const onDelete = async () => {
    try {
      if (window.confirm("Are you sure you want to delete this manager?")) {
        await deleteUser(Number(id));
        navigate(-1);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <ManagerForm
      title="Edit Manager"
      manager={manager}
      setManager={setManager}
      onSave={onSave}
      onDelete={onDelete}
    />
  );
}
