import React, { useState, useEffect, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { Resident } from "../../classes/resident";
import format from "date-fns/format";
import { useNavigate } from "react-router-dom";

import styles from "./print.module.css";
import { useGlobalLoading } from "../../components";
import { Curfew } from "../../classes";
import {
  GetCurfewsInputType,
  GetCurfewsOutputType,
  useCurfewsApi,
} from "../../apis";

export default function PrintCurfewReportPage() {
  const navigate = useNavigate();
  const { getCurfews } = useCurfewsApi();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const { setGlobalLoading } = useGlobalLoading();

  const [curfews, setCurfews] = useState<GetCurfewsOutputType>({
    items: [],
    total: 0,
  });

  const search = searchParams.get("search") ?? "";
  const date = searchParams.get("date") ?? "";
  const isGeo = searchParams.get("isGeo") === "true";
  const hasNoRentDueDate = searchParams.get("hasNoRentDueDate") === "true";
  const sortLastUaDate = searchParams.get("sortLastUaDate") === "true";
  const locations = searchParams.getAll("locations") ?? [];
  const districts = searchParams.getAll("districts") ?? [];

  const fetchData = useCallback(async () => {
    setLoading(true);

    const customQuery: GetCurfewsInputType = {
      take: 1000, // just set to a large number to get all activities
      date,
    };

    if (locations.length > 0) {
      customQuery.locationIds = locations.map(Number);
    }

    if (districts.length > 0) {
      customQuery.districtIds = districts.map(Number);
    }

    if (isGeo) {
      customQuery.isGeo = isGeo;
    }

    if (hasNoRentDueDate) {
      customQuery.hasNoRentDueDate = hasNoRentDueDate;
    }

    if (search.trim()) {
      customQuery.search = search.trim();
    }

    if (sortLastUaDate) {
      customQuery.lastUaDate__order = "asc";
    }

    const _curfews = await getCurfews({ ...customQuery });

    setCurfews(_curfews);

    setLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const rows = curfews.items
    .map((curfew) => {
      // Only show Curfews. No need to show residents that are still home.
      if (!curfew.eta && !curfew.destination) return;

      return {
        curfew: new Curfew(curfew),
        resident: new Resident(curfew.resident),
      };
    })
    .filter((value): value is { curfew: Curfew; resident: Resident } => {
      return value !== undefined;
    });

  useEffect(() => {
    setGlobalLoading(loading);
    if (loading) return;

    setTimeout(() => {
      window.print();
    }, 200);
  }, [loading]);

  if (loading) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.hide}>
        <button onClick={() => navigate(-1)}>Go Back</button>
        <button onClick={() => window.print()}>Print</button>
        <p>
          To remove the header and footer, click the &quot;More settings&quot;
          button below and uncheck the &quot;Headers and footers&quot; option.
        </p>
      </div>
      <h1>Curfew Report</h1>
      <div className={styles.details}>
        <div>
          <div>
            <strong>Date:</strong> {format(new Date(date), "PPP")}
          </div>
          {search && (
            <div>
              <strong>Search:</strong> {search}
            </div>
          )}
          {locations.length > 0 && (
            <div>
              <strong>Locations:</strong> {locations.join(", ")}
            </div>
          )}
          {districts.length > 0 && (
            <div>
              <strong>Districts:</strong> {districts.join(", ")}
            </div>
          )}
        </div>
        <div>
          <strong>Total Curfews:</strong> {rows.length}
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <th>Resident</th>
            <th>Destination</th>
            <th>ETA</th>
          </tr>
        </thead>
        <tbody>
          {rows.map(({ curfew, resident }) => (
            <tr key={curfew.id}>
              <td>
                {[resident.firstName, resident.lastName]
                  .filter(Boolean)
                  .join(" ")}
              </td>
              <td>{curfew.destination}</td>
              <td>{curfew.eta}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
