// eslint-disable-file no-console
import { spacings, breakpoints } from "../constants";

const parseSpacingName = (spacingString) => {
  const isNegative = spacingString.startsWith("-");

  // If the spacing string is negative, remove the - and continue as normal
  const alwaysPositiveSpacingString = isNegative
    ? spacingString.substring(1)
    : spacingString;

  const spacingStringCharacters = alwaysPositiveSpacingString.split("");
  const spacingStringSegments = alwaysPositiveSpacingString.split("-");
  const objectToReturn = {
    property: "",
    direction: "",
    amount: "",
    breakpoint: "",
  };

  // find property
  switch (spacingStringCharacters[0]) {
    case "m":
      objectToReturn.property = "margin";
      break;
    case "p":
      objectToReturn.property = "padding";
      break;
    default:
      console.warn('Spacing prop property doesnt match possible cases "m, p"');
      return null;
  }

  // find direction
  switch (spacingStringCharacters[1]) {
    case "-":
      objectToReturn.direction = "all";
      break;
    case "x":
      objectToReturn.direction = "x";
      break;
    case "y":
      objectToReturn.direction = "y";
      break;
    case "t":
      objectToReturn.direction = "top";
      break;
    case "r":
      objectToReturn.direction = "right";
      break;
    case "b":
      objectToReturn.direction = "bottom";
      break;
    case "l":
      objectToReturn.direction = "left";
      break;
    default:
      console.warn("Spacing prop direction doesnt match possible cases");
      return null;
  }

  // find breakpoint
  if (
    spacingStringSegments.length > 2 &&
    breakpoints[spacingStringSegments[1]]
  ) {
    objectToReturn.breakpoint = breakpoints[spacingStringSegments[1]];
  } else {
    objectToReturn.breakpoint = null;
  }

  // find ammount
  const amountString = spacingStringSegments[2] || spacingStringSegments[1];
  const amountStringSegments = amountString.split("+");
  const amount =
    amountStringSegments.reduce((accumulator, currentValue) => {
      if (spacings[currentValue] || spacings[currentValue] === 0) {
        return accumulator + spacings[currentValue];
      }
      console.error(
        `Spacing name "${currentValue}" from prop not found in global spacings`
      );
      return 0;
    }, 0) + "rem";

  objectToReturn.amount = isNegative ? `-${amount}` : amount;

  return objectToReturn;
};

const makeStyleString = (parsedSpacing) => {
  let stylesString = "";

  if (!parsedSpacing) return "";

  if (parsedSpacing.direction === "all") {
    stylesString = `${parsedSpacing.property}: ${parsedSpacing.amount} !important;`;
  } else if (parsedSpacing.direction === "y") {
    stylesString = `${parsedSpacing.property}-top: ${parsedSpacing.amount} !important;
                    ${parsedSpacing.property}-bottom: ${parsedSpacing.amount} !important;`;
  } else if (parsedSpacing.direction === "x") {
    stylesString = `${parsedSpacing.property}-left: ${parsedSpacing.amount} !important;
                    ${parsedSpacing.property}-right: ${parsedSpacing.amount} !important;`;
  } else {
    stylesString = `${parsedSpacing.property}-${parsedSpacing.direction}: ${parsedSpacing.amount} !important;`;
  }

  if (parsedSpacing.breakpoint) {
    return `@media(min-width: ${parsedSpacing.breakpoint}){${stylesString}}`;
  }

  return stylesString;
};

export default function useSpacingProps(props) {
  if (!props.spacing || typeof props.spacing !== "string") return null;

  const styles = props.spacing
    .trim()
    .split(" ")
    .map((value) => {
      const parsedSpacing = parseSpacingName(value);
      return makeStyleString(parsedSpacing);
    });

  return styles;
}
