import React, { useState, useCallback, useEffect } from "react";
import { HeaderLink, RowType, useTableFilter } from "../../../components";
import HomeIcon from "@mui/icons-material/Home";
import {
  GetWorkOrdersInputType,
  GetWorkOrdersOutputType,
  useWorkOrdersApi,
} from "../../../apis";
import WorkOrderTable from "../../../components/WorkOrderTable";
import WorkOrderModal from "../../../components/WorkOrderModal";
import { WorkOrder } from "../../../classes/work-order";
import { useMe } from "../../../store";

export default function WorkOrders() {
  const { me } = useMe();
  const { getWorkOrders } = useWorkOrdersApi();
  const {
    locations,
    workOrderPriorities,
    workOrderStatuses,
    workOrderCategories,
  } = useTableFilter();
  const [workOrder, setWorkOrder] = useState(
    new WorkOrder({
      priority: "LOW",
      status: "PENDING",
      category: "PREVENTATIVE",
      locations: [],
    })
  );
  const [active, setActive] = useState(false);
  const [workOrders, setWorkOrders] = useState<GetWorkOrdersOutputType>({
    items: [],
    total: 0,
  });

  const [page, setPage] = useState(0);
  const [take] = useState(100);
  const [search, setSearch] = useState("");

  const fetchData = useCallback(async () => {
    const customQuery: GetWorkOrdersInputType = {
      skip: page * take,
      take,
    };

    if (locations.length > 0) {
      customQuery.locations = locations;
    }

    if (workOrderPriorities.length > 0) {
      customQuery.priorities = workOrderPriorities;
    }

    if (workOrderStatuses.length > 0) {
      customQuery.statuses = workOrderStatuses;
    }

    if (workOrderCategories.length > 0) {
      customQuery.categories = workOrderCategories;
    }

    if (search) {
      customQuery.search = search;
    }

    const _workOrders = await getWorkOrders({ ...customQuery });

    if (page === 0) {
      setWorkOrders(_workOrders);
    } else {
      setWorkOrders({
        items: [...workOrders.items, ..._workOrders.items],
        total: _workOrders.total,
      });
    }
  }, [
    page,
    take,
    locations,
    workOrderPriorities,
    workOrderStatuses,
    workOrderCategories,
    search,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const rows = workOrders.items.map((workOrder) => ({
    workOrder,
  }));

  const handleNewWorkOrder = () => {
    setActive(true);
    setWorkOrder(
      new WorkOrder({
        priority: "LOW",
        status: "PENDING",
        category: "PREVENTATIVE",
        creatorId: me?.id,
        locations: [],
      })
    );
  };

  const handleView = async (record: RowType) => {
    setWorkOrder(record.workOrder as WorkOrder);
    setActive(true);
  };

  return (
    <>
      <HeaderLink
        icon={<HomeIcon />}
        text="Add Work Order"
        onClick={handleNewWorkOrder}
      />

      <WorkOrderTable
        rows={rows}
        search={search}
        setSearch={setSearch}
        setPage={setPage}
        total={workOrders.total}
        onView={handleView}
      />

      <WorkOrderModal
        active={active}
        closeModal={() => setActive(false)}
        workOrder={workOrder}
        onCallback={fetchData}
      />
    </>
  );
}
