import { useLocationPhotosApi, useLocationsApi } from "../apis";
import { LocationPhoto } from "../classes";
import { usePhotosGeneric } from "./usePhotosGeneric";
interface UseLocationPhotosProps {
  locationId?: number;
  callback?: () => void;
}

export function useLocationPhotos(props?: UseLocationPhotosProps) {
  const { getLocationPhotos, addLocationPhoto } = useLocationsApi();
  const { deleteLocationPhoto, updateLocationPhoto } = useLocationPhotosApi();

  const saveNewLocationPhoto = (photo: LocationPhoto) => {
    if (props?.locationId === undefined)
      throw new Error("Location ID is required");

    if (!photo.file) throw new Error("File is required");

    const photoData = new FormData();

    photoData.append("photo", photo.file);
    photoData.append("caption", photo.caption);

    return addLocationPhoto(props.locationId, photoData);
  };

  const saveRemoveLocationPhoto = async (photo: LocationPhoto) =>
    deleteLocationPhoto(photo.id as number);

  const saveUpdateLocationPhoto = async (photo: LocationPhoto) =>
    updateLocationPhoto(photo.id as number, photo);

  const {
    photos,

    addPhoto,
    removePhoto,
    updatePhoto,
  } = usePhotosGeneric<LocationPhoto>({
    id: props?.locationId,
    fetchPhotosFunc: getLocationPhotos,
    PhotoClass: LocationPhoto,

    saveNewPhoto: saveNewLocationPhoto,
    saveRemovePhoto: saveRemoveLocationPhoto,
    saveUpdatePhoto: saveUpdateLocationPhoto,
  });

  return {
    photos,

    addPhoto,
    removePhoto,
    updatePhoto,
  };
}
