import React, { useEffect, useState, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { HeaderLink, ResidentTable, useTableFilter } from "../../../components";
import {
  Dialog,
  DialogTitle,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { useNavigate } from "react-router-dom";
import {
  useResidentsApi,
  GetResidentsInputType,
  GetResidentsOutputType,
} from "../../../apis/residents";
import {
  GetLocationsInputType,
  GetLocationsOutputType,
  useLocationsApi,
} from "../../../apis/locations";

export default function Residents() {
  const navigate = useNavigate();
  const { getResidents } = useResidentsApi();
  const { getLocations } = useLocationsApi();
  const {
    status,
    locations: locationsFilter,
    districts: districtsFilter,
    isGeo,
    hasNoRentDueDate,
    sortLastUaDate,
  } = useTableFilter();
  const [modalActive, setModalActive] = useState(false);
  const [residents, setResidents] = useState<GetResidentsOutputType>({
    items: [],
    total: 0,
  });

  const [locations, setLocations] = useState<GetLocationsOutputType>({
    items: [],
    total: 0,
    totalAvailableBeds: 0,
  });

  const [page, setPage] = useState(0);
  const [take] = useState(100);
  const [searchParams, setSearchParams] = useSearchParams();

  // If user previously entered a search string, use that value for "search" variable below
  // When user navigates back to the activity page, their search string will be preserved in the UI
  const [search, setSearch] = useState(searchParams.get("search") ?? "");

  const fetchData = useCallback(async () => {
    const customQuery: GetResidentsInputType = {
      skip: page * take,
      take,
      statuses: status,
    };

    // Only used for "Total Available Beds" count
    const customLocationQuery: GetLocationsInputType = {};

    if (locationsFilter.length > 0) {
      customQuery.locations = locationsFilter;
      customLocationQuery.ids = locationsFilter;
    }

    if (districtsFilter.length > 0) {
      customQuery.districts = districtsFilter;
    }

    if (isGeo) {
      customQuery.isGeo = isGeo;
    }

    if (hasNoRentDueDate) {
      customQuery.hasNoRentDueDate = hasNoRentDueDate;
    }

    if (search.trim()) {
      customQuery.search = search.trim();
      setSearchParams({ search: search.trim(), status });
    } else {
      setSearchParams({ status });
    }

    if (sortLastUaDate) {
      customQuery.lastUaDate__order = "asc";
    }

    const _residents = await getResidents({ ...customQuery });
    const _locations = await getLocations({ ...customLocationQuery });

    // If we're on the first page, clear the list.
    // This only happens when the user clicks a new Status tab.
    if (page === 0) {
      setResidents(_residents);
    } else {
      setResidents({
        items: [...residents.items, ..._residents.items],
        total: _residents.total,
      });
    }

    setLocations(_locations);
  }, [
    page,
    take,
    status,
    locationsFilter,
    districtsFilter,
    isGeo,
    hasNoRentDueDate,
    search,
    sortLastUaDate,
    getResidents,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const rows = residents.items.map((resident) => ({
    resident,
    location: resident.location,
  }));

  const handleCloseModal = () => setModalActive(false);
  const handleNewResident = () => setModalActive(true);
  const handleAddPending = () => navigate("pending");
  const handleAddReqMoveIn = () => navigate("request-move-in");

  return (
    <>
      <HeaderLink
        icon={<PersonAddAlt1Icon />}
        onClick={handleNewResident}
        text="Add Resident"
      />

      <ResidentTable
        rows={rows}
        total={residents.total}
        totalAvailableBeds={locations.totalAvailableBeds}
        setPage={setPage}
        search={search}
        setSearch={setSearch}
      />

      <Dialog onClose={handleCloseModal} open={modalActive}>
        <DialogTitle>Add Resident</DialogTitle>
        <List>
          <ListItemButton onClick={handleAddPending}>
            <ListItemIcon>
              <PersonAddAltIcon />
            </ListItemIcon>
            <ListItemText primary="Pending" />
          </ListItemButton>
          <ListItemButton onClick={handleAddReqMoveIn}>
            <ListItemIcon>
              <PersonAddAlt1Icon />
            </ListItemIcon>
            <ListItemText primary="Req Move-in" />
          </ListItemButton>
        </List>
      </Dialog>
    </>
  );
}
