import { Contractor } from "./contractor";
import DatabaseObject, {
  DatabaseObjectConstructorType,
} from "./database-object";
import { User } from "./user";
import { Location } from "./location";

export interface WorkOrderConstructorType
  extends DatabaseObjectConstructorType {
  priority: "LOW" | "MEDIUM" | "HIGH" | "EMERGENCY";
  status: "PENDING" | "ACTIVE" | "COMPLETED";
  category:
    | "APPLIANCE"
    | "CABINETRY"
    | "CLEANING"
    | "CONCRETE"
    | "DOORS"
    | "ELECTRICAL"
    | "EXCAVATION"
    | "EXTERMINATION"
    | "FINISH_WORK"
    | "FLOORING"
    | "FRAMING"
    | "FURNISHING"
    | "HVAC"
    | "LANDSCAPING"
    | "MEDIA"
    | "PAINTING"
    | "PLUMBING"
    | "PREVENTATIVE"
    | "ROOFING"
    | "TILE"
    | "TURNOVER"
    | "UTILITIES"
    | "WINDOWS";

  cost?: number;
  scheduledDate?: Date | string;
  description?: string;
  locations: Location[];
  locationIds?: number[];
  contractor?: Contractor;
  contractorId?: number;
  creator?: User;
  creatorId?: number;
}

export class WorkOrder extends DatabaseObject {
  priority: "LOW" | "MEDIUM" | "HIGH" | "EMERGENCY";
  status: "PENDING" | "ACTIVE" | "COMPLETED";
  category:
    | "APPLIANCE"
    | "CABINETRY"
    | "CLEANING"
    | "CONCRETE"
    | "DOORS"
    | "ELECTRICAL"
    | "EXCAVATION"
    | "EXTERMINATION"
    | "FINISH_WORK"
    | "FLOORING"
    | "FRAMING"
    | "FURNISHING"
    | "HVAC"
    | "LANDSCAPING"
    | "MEDIA"
    | "PAINTING"
    | "PLUMBING"
    | "PREVENTATIVE"
    | "ROOFING"
    | "TILE"
    | "TURNOVER"
    | "UTILITIES"
    | "WINDOWS";
  cost?: number;
  scheduledDate?: Date;
  description?: string;
  locations: Location[] = [];
  contractor?: Contractor;
  contractorId?: number;
  creator?: User;
  creatorId?: number;

  constructor(data: WorkOrderConstructorType) {
    super(data);

    this.priority = data.priority;
    this.status = data.status;
    this.category = data.category;

    if (data.cost) this.cost = data.cost;
    if (data.scheduledDate) this.scheduledDate = new Date(data.scheduledDate);
    if (data.description) this.description = data.description;
    if (data.locations)
      this.locations = data.locations.map((l) => new Location(l));
    if (data.contractor) this.contractor = new Contractor(data.contractor);
    if (data.contractorId) this.contractorId = data.contractorId;
    if (data.creator) this.creator = new User(data.creator);
    if (data.creatorId) this.creatorId = data.creatorId;
  }

  prepareForApi() {
    const obj = super.prepareForApi() as Partial<WorkOrder>;

    delete obj.contractor;
    delete obj.creator;
    delete obj.locations;

    const locationIds = this.locations.map((l) => l.id as number);

    const payload = {
      ...obj,
      locationIds,
    };

    return payload;
  }
}

export const WORKORDER_PRIORITIES = {
  LOW: "LOW",
  MEDIUM: "MEDIUM",
  HIGH: "HIGH",
  EMERGENCY: "EMERGENCY",
};

export const WORKORDER_STATUSES = {
  PENDING: "PENDING",
  ACTIVE: "ACTIVE",
  COMPLETED: "COMPLETED",
};

export const WORKORDER_CATEGORIES = {
  APPLIANCE: "APPLIANCE",
  CABINETRY: "CABINETRY",
  CLEANING: "CLEANING",
  CONCRETE: "CONCRETE",
  DOORS: "DOORS",
  ELECTRICAL: "ELECTRICAL",
  EXCAVATION: "EXCAVATION",
  EXTERMINATION: "EXTERMINATION",
  FINISH_WORK: "FINISH_WORK",
  FLOORING: "FLOORING",
  FRAMING: "FRAMING",
  FURNISHING: "FURNISHING",
  HVAC: "HVAC",
  LANDSCAPING: "LANDSCAPING",
  MEDIA: "MEDIA",
  PAINTING: "PAINTING",
  PLUMBING: "PLUMBING",
  PREVENTATIVE: "PREVENTATIVE",
  ROOFING: "ROOFING",
  TILE: "TILE",
  TURNOVER: "TURNOVER",
  UTILITIES: "UTILITIES",
  WINDOWS: "WINDOWS",
};
