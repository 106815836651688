import React from "react";
import propTypes from "prop-types";
import styled, { css } from "styled-components";
import { spacings, fontSizes, fontWeights, fonts, colors } from "../constants";
import useSpacingProps from "../utils/spacing-props";

const typeMap = {
  h1: "h1",
  h2: "h3",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  body: "div",
  default: "div",
};

const Typography = styled(
  ({ children, className, tag, link, type, onClick, ...otherProps }) => {
    const Tag = tag || typeMap[type];
    const linkIsPath = link && typeof link === "string";
    return (
      <Tag className={className} onClick={onClick} {...otherProps}>
        {link ? (
          <StyledLink href={linkIsPath ? link : null}>{children}</StyledLink>
        ) : (
          children
        )}
      </Tag>
    );
  }
)`
  margin-bottom: ${(props) => (props.type ? spacings[2] + "em" : "0em")};
  margin-top: ${(props) => (props.type ? spacings[1] + "em" : "0em")};
  ${useSpacingProps}
  line-height: inherit;
  font-family: ${fonts.bodyFontFamily};

  ${({ align }) =>
    align &&
    css`
      text-align: ${align};
    `}

  ${({ noWrap }) =>
    noWrap &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}

  ${({ inline }) =>
    inline &&
    css`
      display: inline;
    `}

  ${({ inlineBlock }) =>
    inlineBlock &&
    css`
      display: inline-block;
    `}

  ${({ type }) =>
    type === "body" &&
    css`
      font-size: ${fontSizes.body};
    `}

  ${({ type }) =>
    type === "default" &&
    css`
      font-size: inherit;
    `}

  ${({ type }) =>
    type === "h1" &&
    css`
      font-size: ${fontSizes.h1};
      font-weight: ${fontWeights.semiBold};
    `}

  ${({ type }) =>
    type === "h2" &&
    css`
      color: ${colors.font.dark};
      font-size: ${fontSizes.h2};
      font-weight: ${fontWeights.semiBold};
    `}

  ${({ type }) =>
    type === "h3" &&
    css`
      color: ${colors.font.dark};
      font-size: ${fontSizes.h3};
      font-weight: ${fontWeights.semiBold};
    `}

  ${({ type }) =>
    type === "h4" &&
    css`
      color: ${colors.font.dark};
      font-size: ${fontSizes.h4};
      font-weight: ${fontWeights.semiBold};
    `}

  ${({ type }) =>
    type === "h5" &&
    css`
      color: ${colors.font.dark};
      font-size: ${fontSizes.h5};
      font-weight: ${fontWeights.semiBold};
    `}

  ${({ type }) =>
    type === "h6" &&
    css`
      color: ${colors.font.dark};
      font-size: ${fontSizes.h6};
      font-weight: ${fontWeights.semiBold};
    `}

  ${({ type }) =>
    type === "small" &&
    css`
      font-size: ${fontSizes.small};
    `}

  ${({ type }) =>
    type === "body" &&
    css`
      font-size: ${fontSizes.body};
    `}

  ${({ type }) =>
    type === "body-l" &&
    css`
      font-size: ${fontSizes.bodyLarge};
    `}

  ${({ weight, bold }) =>
    (bold || weight === "bold") &&
    css`
      font-weight: ${fontWeights.bold};
    `}

  ${({ weight, semiBold }) =>
    (semiBold || weight === "semi-bold") &&
    css`
      font-weight: ${fontWeights.semiBold};
    `}

  ${({ weight }) =>
    weight === "regular" &&
    css`
      font-weight: ${fontWeights.regular};
    `}

  ${({ italic }) =>
    italic &&
    css`
      font-style: italic;
    `}

  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
`;

const StyledLink = styled("a")`
  color: inherit;
  font-weight: inherit;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

// const StyledRouterLink = styled(RouterLink)`
//   color: inherit;
//   font-weight: inherit;
//   &:hover {
//     text-decoration: underline;
//   }
// `;

Typography.defaultProps = {
  tag: "div",
  align: "left",
  type: "default",
};

Typography.propTypes = {
  /** Which tag to apply  */
  tag: propTypes.oneOf([
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "p",
    "div",
    "span",
  ]),
  /** Which type to apply  */
  type: propTypes.oneOf([
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "body",
    "body-l",
    "small",
    "default",
  ]),
  /** Set text align */
  align: propTypes.oneOf(["inherit", "left", "center", "right", "justify"]),
  /** Set as display inline-block */
  inlineBlock: propTypes.bool,
  /** Set as display inline */
  inline: propTypes.bool,
  /** Prevent from line breaks & truncate with ... */
  noWrap: propTypes.bool,
  /** Pass a specific font weight */
  weight: propTypes.oneOf(["bold", "semi-bold", "regular"]),
  /** Spacing property (see Layout component) */
  spacing: propTypes.string,
  children: propTypes.node,
  /** onClick function */
  onClick: propTypes.func,
  /** Deprecated fontWeight booleans, please try using weight when possible */
  bold: propTypes.bool,
  semiBold: propTypes.bool,
  /** Italic font-style */
  italic: propTypes.bool,
  /** Any valid path to navigate to onClick */
  link: propTypes.oneOfType([propTypes.string, propTypes.bool]),
  /** Any valid color (e.g., hex or hsl) */
  color: propTypes.string,
};

/** @component */
export default Typography;
