import React, { useContext, createContext, useState } from "react";
import { Backdrop, CircularProgress, Grid, Typography } from "@mui/material";

interface GlobalLoadingContextProps {
  globalLoading: boolean | string;
  setGlobalLoading: React.Dispatch<React.SetStateAction<boolean | string>>;
}

const GlobalLoadingContext = createContext<GlobalLoadingContextProps>({
  globalLoading: false,
  setGlobalLoading: () => console.warn("no global loading provider"),
});

export const useGlobalLoading = () => useContext(GlobalLoadingContext);

interface GlobalLoadingProviderProps {
  children: React.ReactNode;
}

export function GlobalLoadingProvider({
  children,
}: GlobalLoadingProviderProps) {
  const [globalLoading, setGlobalLoading] = useState<boolean | string>(false);

  return (
    <GlobalLoadingContext.Provider value={{ globalLoading, setGlobalLoading }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal + 1 }}
        open={!!globalLoading}
      >
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={2}
        >
          {typeof globalLoading === "string" && (
            <Grid item>
              <Typography>{globalLoading}</Typography>
            </Grid>
          )}
          <Grid item>
            <CircularProgress color="inherit" />
          </Grid>
        </Grid>
      </Backdrop>
      {children}
    </GlobalLoadingContext.Provider>
  );
}
