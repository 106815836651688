import { useCallback } from "react";
import {
  QueryParams,
  useApi,
  GetListInputType,
  GetListOutputType,
} from "../hooks";
import { User, UserComment, UserPhoto } from "../classes";

export function useUsersApi() {
  const {
    fetchGETList,
    fetchGET,
    fetchPOST,
    fetchPUT,
    fetchDELETE,
    fetchPOSTMultiple,
  } = useApi();

  const getUser = useCallback(
    (id: number) => fetchGET(`users/${id}`),
    [fetchGET]
  );

  const getUsers = useCallback(
    (queryParams?: QueryParams): Promise<GetUsersOutputType> =>
      fetchGETList("users", queryParams),
    [fetchGETList]
  );

  const addUser = useCallback(
    (body: object) => fetchPOST("users", body),
    [fetchPOST]
  );

  const updateUser = useCallback(
    (id: number, body: object) => fetchPUT(`users/${id}`, body),
    [fetchPUT]
  );

  const deleteUser = useCallback(
    (id: number) => fetchDELETE(`users/${id}`),
    [fetchDELETE]
  );

  const getUserPhotos = useCallback(
    (id: number) => fetchGET(`users/${id}/photos`),
    [fetchGET]
  );

  const addUserPhoto = useCallback(
    (id: number, data: object) => fetchPOSTMultiple(`users/${id}/photos`, data),
    [fetchPOSTMultiple]
  );

  const getUserComments = useCallback(
    async (id: number) => {
      const cs = (await fetchGET(
        `users/${id}/comments`
      )) as GetUserCommentsOutputType;

      cs.items = cs.items.map((c) => new UserComment(c));

      return cs;
    },
    [fetchGET]
  );

  const addUserComment = useCallback(
    async (id: number, data: object) => {
      const c = (await fetchPOST(`users/${id}/comments`, data)) as UserComment;

      return new UserComment(c);
    },
    [fetchPOST]
  );

  return {
    getUser,
    getUsers,
    addUser,
    updateUser,
    deleteUser,
    getUserPhotos,
    addUserPhoto,
    getUserComments,
    addUserComment,
  };
}

export interface GetUsersInputType extends GetListInputType {
  search?: string;
}

export type GetUsersOutputType = GetListOutputType<User>;

export type GetUserPhotosOutputType = GetListOutputType<UserPhoto>;

export type GetUserCommentsOutputType = GetListOutputType<UserComment>;
