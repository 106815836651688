import React, { useState, useCallback } from "react";
import { useResidentCommentsApi, useResidentsApi } from "../apis";
import { ResidentComment } from "../classes";
import { CommentBase } from "./CommentsBase";

export function ResidentComments({
  residentId,
  disabled,
}: {
  residentId?: number;
  disabled?: boolean;
}) {
  const [comments, setComments] = useState<ResidentComment[]>([]);

  const { getResidentComments } = useResidentsApi();
  const { deleteResidentComment } = useResidentCommentsApi();
  const { addResidentComment } = useResidentsApi();
  const { updateResidentComment } = useResidentCommentsApi();

  const fetchComments = useCallback(async () => {
    if (!residentId) return;
    const _comments = await getResidentComments(residentId);
    setComments(_comments.items);
  }, [getResidentComments, residentId]);

  const onDelete = async (comment: ResidentComment) => {
    if (window.confirm("Are you sure you want to delete this?")) {
      await deleteResidentComment(comment.id as number);
      fetchComments();
    }
  };

  const onSave = async (item: ResidentComment) => {
    if (!residentId) return;

    if (item.id === undefined) {
      await addResidentComment(residentId, item.prepareForApi());
    } else {
      await updateResidentComment(item.id, item.prepareForApi());
    }
  };

  return (
    <CommentBase<ResidentComment>
      labelPlural="Comments"
      labelSingular="Comment"
      id={residentId}
      disabled={disabled}
      items={comments}
      fetchItems={fetchComments}
      onDelete={onDelete}
      onSave={onSave}
      newClass={(item, text) =>
        new ResidentComment({
          ...item,
          comment: text ?? "",
          residentId: residentId ?? 0,
        })
      }
      getTextFieldValue={(item) => item.comment}
    />
  );
}
