import React from "react";
import { Typography } from "@mui/material";
import {
  ActivityTypeFilter,
  LocationFilter,
  RowType,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  TableSearchField,
  TableSelectedSectionButton,
} from "./index";

import { useNavigate } from "react-router-dom";
import format from "date-fns/format";
import subHours from "date-fns/subHours";
import { colors, bottomNavigationHeight } from "../constants";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { DistrictFilter, TableFilterModal } from "./TableFilter";
import { Resident, Activity, Location, ResidentReport } from "../classes";

interface RecordProps {
  resident: Resident;
  activity: Activity;
  location?: Location;
  residentReport?: ResidentReport;
}

const Header = () => (
  <>
    <TableCell basis="200px">Resident Name</TableCell>
    <TableCell basis="105px">Date</TableCell>
    <TableCell basis="160px">Activity Type</TableCell>
    <TableCell basis="80px">Location</TableCell>
    <TableCell basis="100px">Supervision</TableCell>
    <TableCell basis="150px">Description</TableCell>
  </>
);

const EmptyView = () => (
  <Typography
    variant="h6"
    align="center"
    sx={{
      color: colors.grey[5],
      marginTop: 8,
    }}
  >
    No Activities
  </Typography>
);

interface RowProps {
  row: RowType;
  selected: boolean;
  onClick: () => void;
  tableBodyRef: any;
}

const Row = ({ row, ...props }: RowProps) => (
  <TableRow row={row} SelectedSectionElem={SelectedSection} {...props}>
    <TableCell basis="200px" semiBold>
      {row.resident && `${row.resident.firstName} ${row.resident.lastName}`}
    </TableCell>
    <TableCell basis="105px" color={colors.grey[3]}>
      {row.activity &&
        row.activity.createdAt &&
        // Instead of the literal Activity Time, we only display the date
        // and the Activity timeframe is from 7am to 7am.
        // So we subtract 7 hours from the Activity Time to get the correct date.
        //
        // e.g. a 3am Activity Time will be displayed as the previous day's date.
        format(subHours(new Date(row.activity.createdAt), 7), "P")}
    </TableCell>
    <TableCell basis="160px" color={colors.grey[3]}>
      {row.activity &&
        row.activity.type
          .split("_")
          .map((s) => s[0].toUpperCase() + s.slice(1).toLowerCase())
          .join(" ")}
    </TableCell>
    <TableCell basis="80px" color={colors.grey[3]}>
      {row.location && row.location.name}
    </TableCell>
    <TableCell basis="100px" color={colors.grey[3]}>
      {row.resident && row.resident.supervision}
    </TableCell>
    <TableCell basis="150px" color={colors.grey[3]}>
      {/* Description */}

      {row.activity?.type === "CURFEW" &&
        row.curfew &&
        [row.curfew.destination, row.curfew.eta].join(", ")}

      {row.activity?.type === "RESIDENT_REPORT" && row.residentReport?.report}

      {row.activity?.type === "LOCATION_CHANGE" && row.location?.name}

      {row.activity?.type === "MOVED_OUT" && row.location?.name}
    </TableCell>
  </TableRow>
);

interface SelectedSectionProps {
  row: RecordProps;
  tableBodyScrollTop: number;
}

const SelectedSection = ({ row, tableBodyScrollTop }: SelectedSectionProps) => {
  const navigate = useNavigate();

  const goTo = (path: string) => () =>
    navigate(`/dashboard/residents/${path}/${row.resident.id}`, {
      state: { tableBodyScrollTop },
    });

  return (
    <TableSelectedSectionButton icon={faEye} onClick={goTo("view")}>
      View
    </TableSelectedSectionButton>
  );
};

interface ActivityTableProps {
  rows: RecordProps[];
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  total: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

const ActivityTable = ({
  rows,
  search,
  setSearch,
  total,
  setPage,
}: ActivityTableProps) => {
  const filterContent = [LocationFilter, DistrictFilter, ActivityTypeFilter];

  return (
    <Table style={{ height: `calc(100vh - ${bottomNavigationHeight})` }}>
      <TableFilterModal
        filterContent={filterContent}
        callback={() => setPage(0)}
      />
      <TableSearchField
        value={search}
        onChange={(e) => {
          // If user selects from the existing search list,
          // value will be empty and textContent will have the search string
          e.target.value
            ? setSearch(e.target.value)
            : setSearch(e.target.textContent ?? "");
        }}
        filterContent={filterContent}
      />
      <TableHeader>
        <Header />
      </TableHeader>
      <TableBody
        rows={rows}
        total={total}
        RowElem={Row}
        EmptyViewElem={EmptyView}
        onLoadMore={() => setPage((page) => page + 1)}
      />
    </Table>
  );
};

export default ActivityTable;
