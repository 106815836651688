import DatabaseObject, {
  DatabaseObjectConstructorType,
} from "./database-object";
import { Resident, ResidentConstructorType } from "./resident";
import { Collection, CollectionConstructorType } from "./collection";
import { Location, LocationConstructorType } from "./location";
import { District, DistrictConstructorType } from "./district";
import { Curfew, CurfewConstructorType } from "./curfew";
import {
  ResidentReport,
  ResidentReportConstructorType,
} from "./resident-report";

export interface ActivityConstructorType extends DatabaseObjectConstructorType {
  type: ActivityType;
  resident?: ResidentConstructorType;
  residentId?: number;
  collection?: CollectionConstructorType;
  collectionId?: number;
  location?: LocationConstructorType;
  locationId?: number;
  district?: DistrictConstructorType;
  districtId?: number;
  curfew?: CurfewConstructorType;
  curfewId?: number;
  residentReport?: ResidentReportConstructorType;
  residentReportId?: number;
  log?: string;
  creatorId?: number;
  diff?: string;
}
export class Activity extends DatabaseObject {
  type: ActivityType = "RESIDENT_CHANGE";
  resident?: Resident;
  residentId?: number;
  collection?: Collection;
  collectionId?: number;
  location?: Location;
  locationId?: number;
  district?: District;
  districtId?: number;
  curfew?: Curfew;
  curfewId?: number;
  residentReport?: ResidentReport;
  residentReportId?: number;
  log?: string;
  creatorId?: number;
  diff?: string;

  constructor(data?: ActivityConstructorType) {
    super(data);

    if (!data) return;

    this.type = data.type;

    if (data.resident) this.resident = new Resident(data.resident);
    if (data.residentId) this.residentId = data.residentId;
    if (data.collection) this.collection = new Collection(data.collection);
    if (data.collectionId) this.collectionId = data.collectionId;
    if (data.location) this.location = new Location(data.location);
    if (data.locationId) this.locationId = data.locationId;
    if (data.district) this.district = new District(data.district);
    if (data.districtId) this.districtId = data.districtId;
    if (data.curfew) this.curfew = new Curfew(data.curfew);
    if (data.curfewId) this.curfewId = data.curfewId;
    if (data.residentReport)
      this.residentReport = new ResidentReport(data.residentReport);
    if (data.residentReportId) this.residentReportId = data.residentReportId;
    if (data.log) this.log = data.log;
    if (data.creatorId) this.creatorId = data.creatorId;
    if (data.diff) this.diff = data.diff;
  }
}

export type ActivityType =
  | "RESIDENT_CHANGE"
  | "PENDING"
  | "REQUEST_MOVE_IN"
  | "MOVED_IN"
  | "MOVED_OUT"
  | "LOCATION_CHANGE"
  | "PAYMENT_SUBMITTED"
  | "PAYMENT_REJECTED"
  | "PAYMENT_APPROVED"
  | "UA_NEGATIVE"
  | "UA_POSITIVE"
  | "UA_CHANGE_NEGATIVE"
  | "UA_CHANGE_POSITIVE"
  | "UA_DELETE"
  | "RESIDENT_PHOTO_ADDED"
  | "CURFEW"
  | "RESIDENT_REPORT";

export const ACTIVITY_TYPES = {
  RESIDENT_CHANGE: "RESIDENT_CHANGE",
  PENDING: "PENDING",
  REQUEST_MOVE_IN: "REQUEST_MOVE_IN",
  MOVED_IN: "MOVED_IN",
  MOVED_OUT: "MOVED_OUT",
  LOCATION_CHANGE: "LOCATION_CHANGE",
  PAYMENT_SUBMITTED: "PAYMENT_SUBMITTED",
  PAYMENT_REJECTED: "PAYMENT_REJECTED",
  PAYMENT_APPROVED: "PAYMENT_APPROVED",
  UA_NEGATIVE: "UA_NEGATIVE",
  UA_POSITIVE: "UA_POSITIVE",
  UA_CHANGE_NEGATIVE: "UA_CHANGE_NEGATIVE",
  UA_CHANGE_POSITIVE: "UA_CHANGE_POSITIVE",
  UA_DELETE: "UA_DELETE",
  RESIDENT_PHOTO_ADDED: "RESIDENT_PHOTO_ADDED",
  CURFEW: "CURFEW",
  RESIDENT_REPORT: "RESIDENT_REPORT",
};
