import React from "react";
import styled from "styled-components";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { Button } from "./index";

const TextField = styled(
  ({
    className,
    label,
    name,
    type = "text",
    value,
    onChange,
    disabled,
    clearable,
  }) => {
    return (
      <div className={className}>
        <label>
          {label}
          <input
            type={type}
            name={name}
            onChange={onChange}
            value={value}
            disabled={disabled}
          />
        </label>
        {clearable && value && value.length > 0 && (
          <Button
            iconOnly
            icon={faTimesCircle}
            onClick={() => onChange({ target: { value: "" } })}
          />
        )}
      </div>
    );
  }
)`
  position: relative;
  font-size: 12px;
  margin: 8px 0;

  & input {
    display: block;
    outline: none;
    width: calc(100% - 1em);
    font-size: 1rem;
    border: 1px solid #eeeeee;
    border-radius: 0.25rem;
    padding: 0.5em 0.5em;
  }

  & input:disabled {
    background-color: #eeeeee;
    cursor: not-allowed;
  }

  & button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  }
`;

export default TextField;
