import React from "react";

export default function keyCodeToNumberChangeArray(
  e: React.KeyboardEvent,
  array: string[]
): void {
  // Is a number 0 - 9
  //
  // NOTE: We should use `e.code` instead of `e.keyCode`,
  // however, there's an Android issue that's causing `e.code` to be `undefined`
  switch (e.keyCode) {
    // 0
    case 48:
    case 96:
      array.push("0");
      break;

    // 1
    case 49:
    case 97:
      array.push("1");
      break;

    // 2
    case 50:
    case 98:
      array.push("2");
      break;

    // 3
    case 51:
    case 99:
      array.push("3");
      break;

    // 4
    case 52:
    case 100:
      array.push("4");
      break;

    // 5
    case 53:
    case 101:
      array.push("5");
      break;

    // 6
    case 54:
    case 102:
      array.push("6");
      break;

    // 7
    case 55:
    case 103:
      array.push("7");
      break;

    // 8
    case 56:
    case 104:
      array.push("8");
      break;

    // 9
    case 57:
    case 105:
      array.push("9");
      break;

    // backspace
    case 8:
      array.pop();
      break;

    // ignore
    default:
      return;
  }
}
