import getHours from "date-fns/getHours";
import startOfYesterday from "date-fns/startOfYesterday";
import startOfToday from "date-fns/startOfToday";
import { useResidentsApi, useUaPhotosApi } from "../apis";
import { UaPhoto } from "../classes/ua-photo";
import { convertLocalToUTC } from "../utils/date-helpers";
import { usePhotosGeneric } from "./usePhotosGeneric";

interface UseUaPhotosProps {
  residentId?: number;
  callback?: () => void;
}

export function useUaPhotos({ residentId, callback }: UseUaPhotosProps) {
  const { getResidentUaPhotos, addResidentUaPhoto } = useResidentsApi();
  const { deleteUaPhoto, updateUaPhoto: _updateUaPhoto } = useUaPhotosApi();

  const saveNewUaPhoto = (photo: UaPhoto) => {
    if (residentId === undefined) throw new Error("Resident ID is required");
    if (!photo.file) throw new Error("File is required");
    const uaPhotoData = new FormData();
    uaPhotoData.append("photo", photo.file);
    uaPhotoData.append("isPositive", photo.isPositive.toString());

    // If it's before 7am, apply yesterday's date
    let date: Date;
    if (getHours(new Date()) < 7) {
      date = convertLocalToUTC(startOfYesterday());
    } else {
      date = convertLocalToUTC(startOfToday());
    }

    uaPhotoData.append("date", date.toISOString());
    uaPhotoData.append("caption", photo.caption);

    return addResidentUaPhoto(residentId, uaPhotoData);
  };

  const saveRemoveUaPhoto = async (photo: UaPhoto) =>
    deleteUaPhoto(photo.id as number);

  const saveUpdateUaPhoto = async (photo: UaPhoto) =>
    _updateUaPhoto(photo.id as number, photo);

  const {
    photos: uaPhotos,

    addPhoto: addUaPhoto,
    removePhoto: removeUaPhoto,
    updatePhoto: updateUaPhoto,
  } = usePhotosGeneric<UaPhoto>({
    id: residentId,
    callback,
    fetchPhotosFunc: getResidentUaPhotos,
    PhotoClass: UaPhoto,

    saveNewPhoto: saveNewUaPhoto,
    saveRemovePhoto: saveRemoveUaPhoto,
    saveUpdatePhoto: saveUpdateUaPhoto,
  });

  return {
    uaPhotos,

    addUaPhoto,
    removeUaPhoto,
    updateUaPhoto,
  };
}
