/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */

import React from "react";
import styled, { css } from "styled-components";
import propTypes from "prop-types";
import { breakpoints } from "../constants";
import useSpacingProps from "../utils/spacing-props";
import useWidthProps from "../utils/width-props";
import useHeightProps from "../utils/height-props";

const Layout = styled(
  React.forwardRef(
    (
      {
        children,
        className,
        wrap,
        justifyContent,
        flex,
        alignItems,
        bgColor,
        shrink,
        direction,
        relative,
        stackOnMobile,
        ...otherProps
      },
      ref
    ) => (
      <div className={className} ref={ref} {...otherProps}>
        {children}
      </div>
    )
  )
)`
  ${useSpacingProps}
  ${useWidthProps}
  ${useHeightProps}
  display: ${(props) => (props.flex ? "flex" : "block")};
  align-items: ${(props) => props.alignItems || "stretch"};
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  flex-wrap: ${(props) => (props.wrap ? "wrap" : "nowrap")};
  background-color: ${(props) => props.bgColor || "transparent"};
  flex-shrink: ${(props) => props.shrink};
  flex-direction: ${(props) => props.direction};
  position: ${(props) => (props.relative ? "relative" : "static")};
  color: inherit;

  ${({ stackOnMobile }) =>
    stackOnMobile &&
    css`
      flex-direction: column;
      @media (min-width: ${breakpoints.md}) {
        flex-direction: row;
      }
    `}
`;

Layout.propTypes = {
  spacing: propTypes.string,
  flex: propTypes.bool,
  /** Width for the component in % */
  width: propTypes.oneOfType([propTypes.number, propTypes.string]),
  /** Width for the component on md (medium) breakpoint and UP in % */
  mdWidth: propTypes.oneOfType([propTypes.number, propTypes.string]),
  /** Width for the component on lg (large) breakpoint and UP in % */
  lgWidth: propTypes.oneOfType([propTypes.number, propTypes.string]),
  /** Width for the component in % */
  height: propTypes.oneOfType([propTypes.number, propTypes.string]),
  /** Width for the component on md (medium) breakpoint and UP in % */
  mdHeight: propTypes.oneOfType([propTypes.number, propTypes.string]),
  /** Width for the component on lg (large) breakpoint and UP in % */
  lgHeight: propTypes.oneOfType([propTypes.number, propTypes.string]),
  /** Set value of CSS align-items property */
  alignItems: propTypes.string,
  /** Set value of CSS justify-content property */
  justifyContent: propTypes.string,
  /** Set HEX code for background color (use colors from constants.js) */
  bgColor: propTypes.string,
  /** Set flex-wrap or not */
  wrap: propTypes.bool,
  /** Value for flex-shrink */
  shrink: propTypes.number,
  /** Value for flex-direction */
  direction: propTypes.string,
  /** Set Sets flex-direction to column on mobile breakpoints */
  stackOnMobile: propTypes.bool,
  /** Set position: relative  */
  relative: propTypes.bool,
};

const PageLayout = styled(({ children, ...props }) => (
  <Layout {...props}>{children}</Layout>
))`
  min-height: 100%;
  max-width: 1024px;
  margin: auto;
`;

export { PageLayout };

/** @component */
export default Layout;
