import React, { useState, useRef } from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Typography,
  TextField,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { useResidentsApi } from "../apis";
import { useGlobalLoading } from "./GlobalLoading";
import { Resident } from "../classes";

interface SendMessageModalProps {
  active: boolean;
  closeModal: () => void;
  residents: Resident[];
}

export default function SendMessageModal({
  active,
  closeModal,
  residents,
}: SendMessageModalProps) {
  const { setGlobalLoading } = useGlobalLoading();

  const { sendSmsToResident } = useResidentsApi();

  const [message, setMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const sendSmsMessagesEl = useRef<HTMLButtonElement>(null);

  const handleSave: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (submitting) return;
    setSubmitting(true);
    setGlobalLoading(true);

    const residentIds = residents.map((resident) => resident.id);

    try {
      await sendSmsToResident({ residents: residentIds, message });
    } catch (e) {
      if (e instanceof Error) alert(`Failed to send messages: ${e.message}`);
    }

    setSubmitting(false);
    setGlobalLoading(false);

    closeModal();
  };

  return (
    <Dialog open={active} onClose={closeModal} fullWidth>
      <DialogTitle>Send SMS Messages</DialogTitle>
      <DialogContent dividers>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Location</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {residents.map((resident) => (
                <TableRow key={resident.id}>
                  <TableCell>
                    {[resident.lastName, resident.firstName]
                      .filter(Boolean)
                      .join(", ")}
                  </TableCell>
                  <TableCell>{resident.location?.name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Grid container justifyContent="flex-end" mt={2}>
          <Typography>Sending to {residents.length} residents</Typography>
        </Grid>

        <form onSubmit={handleSave}>
          <Grid container direction="column" spacing={1} my={4}>
            <Grid item xs={12}>
              <TextField
                label="Message"
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                multiline
                minRows={3}
                fullWidth
              />
              <div>
                <Typography variant="caption">
                  {'"'}Do not reply automated message from Rising Sun Sober
                  Living:{'"'}
                </Typography>
              </div>
              <div>
                <Typography variant="caption">
                  is included before every message.
                </Typography>
              </div>
            </Grid>
          </Grid>

          {/* Used as a "submit" proxy for the DialogAction buttons */}
          <button
            ref={sendSmsMessagesEl}
            type="submit"
            style={{ display: "none" }}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          startIcon={<SaveIcon />}
          onClick={() => sendSmsMessagesEl.current?.click()}
          disabled={submitting || !message || residents.length === 0}
        >
          {submitting ? "Sending..." : "Send"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
