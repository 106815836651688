import React from "react";
import { TableSelectedSectionButton, TableSearchField } from "./index";

import { useNavigate } from "react-router-dom";
import { headerLinkHeight, bottomNavigationHeight } from "../constants";
import { faEye } from "@fortawesome/free-solid-svg-icons";

import { User } from "../classes/user";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { TableContainer } from "./TableNew";

interface SelectedSectionProps {
  row: RecordProps;
  tableBodyRef: React.RefObject<HTMLDivElement>;
}

const SelectedSection = ({ row, tableBodyRef }: SelectedSectionProps) => {
  const navigate = useNavigate();

  const goTo = (path: string) => () => {
    const tableBodyScrollTop = tableBodyRef.current?.scrollTop;
    console.log("### goTo tableBodyRef", tableBodyRef);
    navigate(`${path}/${row.user.id}`, {
      state: { tableBodyScrollTop },
    });
  };

  return (
    <TableSelectedSectionButton icon={faEye} onClick={goTo("view")}>
      View
    </TableSelectedSectionButton>
  );
};

interface RecordProps {
  user: User;
}

const columnHelper = createColumnHelper<RecordProps>();

const defaultColumns = [
  columnHelper.accessor(
    (row) => [row.user.lastName, row.user.firstName].filter(Boolean).join(", "),
    {
      header: "Name",
      cell: (info) => <strong>{info.getValue()}</strong>,
      size: 240,
    }
  ),
  columnHelper.accessor("user.disabled", {
    header: "Disabled",
    size: 80,
  }),
  columnHelper.accessor("user.email", {
    header: "Email",
    size: 240,
  }),
  columnHelper.accessor("user.roles", {
    header: "Admin",
    size: 80,
  }),
];

interface ManagerTableProps {
  rows: RecordProps[];
  total: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}

const ManagerTable = ({
  rows,
  total,
  setPage,
  search,
  setSearch,
}: ManagerTableProps) => {
  const table = useReactTable({
    data: rows,
    columns: defaultColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div>
      <TableSearchField
        value={search}
        onChange={(e) => {
          setPage(0);
          // If user selects from the existing search list,
          // value will be empty and textContent will have the search string
          e.target.value
            ? setSearch(e.target.value)
            : setSearch(e.target.textContent ?? "");
        }}
      />
      <TableContainer<RecordProps>
        table={table}
        SelectedRowComponent={SelectedSection}
        tableBodyHeight={`calc(100vh - ${headerLinkHeight} - ${bottomNavigationHeight} - 80px)`}
        total={total}
        onLoadMore={() => setPage((prev) => prev + 1)}
        emptyViewText="No managers"
      />
    </div>
  );
};

export default ManagerTable;
