import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Due from "./Due";
import CollectionOverdue from "./CollectionOverdue";
import Audit from "./Audit";
import Rejected from "./Rejected";
import Approved from "./Approved";
import { CollectionsTabs } from "../../../components";

export default function CollectionsRoutes() {
  return (
    <>
      <CollectionsTabs />
      <Routes>
        <Route path="/due" element={<Due />} />
        <Route path="/collection-overdue" element={<CollectionOverdue />} />
        <Route path="/audit" element={<Audit />} />
        <Route path="/rejected" element={<Rejected />} />
        <Route path="/approved" element={<Approved />} />
        <Route path="*" element={<Navigate to="due" />} />
      </Routes>
    </>
  );
}
