import React from "react";
import { Grid, TextField } from "@mui/material";

import { CollectionItem } from "../classes";
import { keyCodeToNumberChangeArray } from "../utils";
import formatDecimal from "../utils/formatDecimal";

interface CustomNumberProps {
  label?: string;
  onChange: (value: number) => void;
  value: number;
  maxValue?: number;
  decimalPlaces?: number;
  disabled?: boolean;
  size?: "small" | "medium";
  fullWidth?: boolean;
  pattern?: string;
}

export function CustomNumber({
  label,
  onChange,
  value,
  maxValue,
  decimalPlaces = 0,
  disabled,
  size,
  fullWidth,
  pattern = "[0-9]*",
}: CustomNumberProps) {
  const handleOnKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    const array = CollectionItem.numberToArray(value);

    keyCodeToNumberChangeArray(e, array);

    const newValue = CollectionItem.arrayToNumber(array);

    if (maxValue && newValue > maxValue) return;

    onChange(newValue);
  };

  const _pattern = decimalPlaces ? "[0-9]+([.,][0-9]+)?" : pattern;

  const _label = [
    label,
    maxValue ? `(Max ${formatDecimal(maxValue, decimalPlaces)})` : "",
  ]
    .filter((a) => Boolean(a))
    .join(" ");

  return (
    <Grid container alignItems="center" wrap="nowrap">
      <TextField
        size={size}
        variant="outlined"
        label={_label}
        onKeyDown={handleOnKeyDown}
        value={formatDecimal(value, decimalPlaces)}
        disabled={disabled}
        inputProps={{
          pattern: _pattern,
          inputMode: "numeric",
        }}
        style={{
          flex: 1,
        }}
        fullWidth={fullWidth}
      />
    </Grid>
  );
}
