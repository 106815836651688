/*
 * Phone numbers need to be in E.164 format (https://en.wikipedia.org/wiki/E.164)
 * and US numbers have 10 digits.
 * Example: +14155552671
 */
export function validatePhoneNumber(phoneNumber?: string): boolean {
  // Ignore empty phone numbers
  if (!phoneNumber || phoneNumber === "+1") return true;

  const phoneNumberRegex = /^\+?[1-9]\d{10}$/;
  return phoneNumberRegex.test(phoneNumber);
}
