import DatabaseObject, {
  DatabaseObjectConstructorType,
} from "./database-object";

export interface UserConstructorType extends DatabaseObjectConstructorType {
  firstName: string;
  lastName: string;
  email: string;
  address?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  phoneNumber?: string;
  roles: string[];
  disabled: boolean;
  expoPushToken?: string;
}

export class User extends DatabaseObject {
  firstName = "";
  lastName = "";
  email = "";
  address?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  phoneNumber?: string;
  roles: string[] = [];
  disabled = false;
  expoPushToken?: string;

  constructor(data?: UserConstructorType) {
    super(data);

    if (!data) return;

    if (data.firstName) this.firstName = data.firstName;
    if (data.lastName) this.lastName = data.lastName;
    if (data.email) this.email = data.email;

    if (data.address) this.address = data.address;
    if (data.city) this.city = data.city;
    if (data.state) this.state = data.state;
    if (data.zipcode) this.zipcode = data.zipcode;

    if (data.phoneNumber) this.phoneNumber = data.phoneNumber;
    if (data.roles) this.roles = data.roles;
    if (data.disabled) this.disabled = data.disabled;
    if (data.expoPushToken) this.expoPushToken = data.expoPushToken;
  }
}
