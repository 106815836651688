import React from "react";
import { useMe } from "../store/me";
import { NavLinkTab, NavLinkTabGroup } from "./NavLinkTab";

const CollectionsTabs = () => {
  const { isAdmin } = useMe();
  return (
    <NavLinkTabGroup>
      <NavLinkTab to="due">Due</NavLinkTab>
      <NavLinkTab to="collection-overdue">Overdue</NavLinkTab>
      <NavLinkTab to="rejected">Rejected</NavLinkTab>
      {isAdmin && <NavLinkTab to="audit">Audit</NavLinkTab>}
      {isAdmin && <NavLinkTab to="approved">Approved</NavLinkTab>}
    </NavLinkTabGroup>
  );
};

export default CollectionsTabs;
