import React from "react";
import format from "date-fns/format";
import {
  Table,
  TableRow,
  TableBody,
  TableHeader,
  TableSelectedSectionButton,
  TableCell,
  TableSearchField,
  Typography,
  CollectionLocationFilter,
  TableFilterModal,
  RowType,
} from "./index";
import { useNavigate } from "react-router-dom";

import { Collection } from "../classes/collection";
import { Location } from "../classes/location";
import { Resident } from "../classes/resident";

import { colors, tabHeight, bottomNavigationHeight } from "../constants";
import {
  faWrench,
  faUser,
  faAlignLeft,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatMoney } from "../utils";

interface RecordProps {
  resident: Resident;
  location?: Location;
  collection: Collection;
}

const Header = () => (
  <>
    <TableCell basis="200px">Name</TableCell>
    <TableCell basis="100px">Date Paid</TableCell>
    <TableCell basis="100px">Collection</TableCell>
    <TableCell basis="43px">Notes</TableCell>
  </>
);

const EmptyView = () => (
  <Typography type="h4" align="center" spacing="mt-8" color={colors.grey[5]}>
    No Collections
  </Typography>
);

interface RowProps {
  row: RowType;
  selected: boolean;
  onClick: () => void;
  tableBodyRef: any;
}

const Row = ({ row, ...props }: RowProps) => (
  <TableRow row={row} SelectedSectionElem={SelectedSection} {...props}>
    <TableCell basis="200px" semiBold>
      {row.resident && `${row.resident.lastName}, ${row.resident.firstName}`}
    </TableCell>
    <TableCell basis="100px" color={colors.grey[3]}>
      {row.collection &&
        row.collection.datePaid &&
        format(new Date(row.collection.datePaid), "P")}
    </TableCell>
    <TableCell basis="100px" color={colors.grey[3]}>
      {row.collection && formatMoney(row.collection.amountPaidOnlyCashCheck())}
    </TableCell>
    <TableCell basis="43px" color={colors.grey[3]}>
      {row.collection && row.collection.notes && (
        <FontAwesomeIcon icon={faAlignLeft} />
      )}
    </TableCell>
  </TableRow>
);

interface SelectedSectionProps {
  row: RecordProps;
  tableBodyScrollTop: number;
  onView: (row: RecordProps) => void;
}

const SelectedSection = ({
  row,
  tableBodyScrollTop,
  onView,
}: SelectedSectionProps) => {
  const navigate = useNavigate();

  const handleResident = () =>
    navigate(`/dashboard/residents/view/${row.resident.id}`, {
      state: { tableBodyScrollTop },
    });

  const handleFix = () => onView(row);

  return (
    <>
      <TableSelectedSectionButton icon={faUser} onClick={handleResident}>
        Resident
      </TableSelectedSectionButton>
      <TableSelectedSectionButton icon={faWrench} onClick={handleFix}>
        Fix
      </TableSelectedSectionButton>
    </>
  );
};

interface RejectedTableProps {
  rows: RecordProps[];
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  total: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  onView: (row: {
    resident?: Resident;
    location?: Location;
    collection?: Collection;
  }) => void;
}

export const RejectedTable = ({
  rows,
  search,
  setSearch,
  total,
  setPage,
  onView,
}: RejectedTableProps) => {
  const filterContent = [CollectionLocationFilter];

  return (
    <Table
      style={{
        height: `calc(100vh - ${tabHeight} - ${bottomNavigationHeight})`,
      }}
    >
      <TableFilterModal
        filterContent={filterContent}
        callback={() => setPage(0)}
      />
      <TableSearchField
        value={search}
        onChange={(e) => {
          setPage(0);
          // If user selects from the existing search list,
          // value will be empty and textContent will have the search string
          e.target.value
            ? setSearch(e.target.value)
            : setSearch(e.target.textContent ?? "");
        }}
        filterContent={filterContent}
      />
      <TableHeader>
        <Header />
      </TableHeader>
      <TableBody
        rows={rows}
        total={total}
        RowElem={Row}
        EmptyViewElem={EmptyView}
        onLoadMore={() => setPage((page) => page + 1)}
        onView={onView}
      />
    </Table>
  );
};
