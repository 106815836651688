import { useCallback } from "react";
import { UserComment } from "../classes";
import { useApi } from "../hooks";

export function useUserCommentsApi() {
  const { fetchPUT, fetchDELETE } = useApi();

  const updateUserComment = useCallback(
    async (id: number, body: object) => {
      const c = await fetchPUT(`user-comments/${id}`, body);
      return new UserComment(c);
    },
    [fetchPUT]
  );

  const deleteUserComment = useCallback(
    async (id: number) => {
      const c = await fetchDELETE(`user-comments/${id}`);
      return new UserComment(c);
    },
    [fetchDELETE]
  );

  return {
    updateUserComment,
    deleteUserComment,
  };
}
