import React, { useState, useCallback, useEffect } from "react";
import LocationForm from "../../../components/LocationForm";
import { useParams, useNavigate } from "react-router-dom";
import { Location } from "../../../classes";
import { useLocationsApi } from "../../../apis";

export default function EditLocation() {
  const { getLocation, updateLocation, deleteLocation } = useLocationsApi();
  const { id } = useParams();
  const navigate = useNavigate();

  const [location, setLocation] = useState<Location>();

  const fetchData = useCallback(async () => {
    const _location = await getLocation(Number(id));
    setLocation(new Location(_location));
  }, [id, getLocation]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onSave = async () => {
    if (!location) return;

    try {
      await updateLocation(location.id as number, location.prepareForApi());

      navigate(-1);
    } catch (e) {
      console.error(e);
    }
  };

  const onDelete = async () => {
    try {
      if (window.confirm("Are you sure you want to delete this location?")) {
        await deleteLocation(Number(id));
        navigate(-1);
      }
    } catch (e) {
      console.error(e);
    }
  };

  if (!location) return null;

  return (
    <LocationForm
      title="Edit Location"
      location={location}
      setLocation={setLocation}
      onSave={onSave}
      onDelete={onDelete}
    />
  );
}
