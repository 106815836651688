import React from "react";
import { convertLocalToUTC } from "./date-helpers";
import { Dayjs } from "dayjs";

export function handleDateChange<T>(
  key: string,
  object: T,
  setObject: React.Dispatch<React.SetStateAction<T>>,
  Class: new (...args: any[]) => T,
  onCallback?: (object: T) => void,
  ignoreTime = false
) {
  return (date: Dayjs | null) => {
    let adjustedDate = null;

    try {
      if (date) {
        adjustedDate = convertLocalToUTC(
          ignoreTime ? date.startOf("day").toDate() : date.toDate()
        );
      }

      const newObject = new Class({
        ...object,
        [key]: adjustedDate,
      });

      setObject(newObject);
      onCallback && onCallback(newObject);
    } catch (e) {
      console.log(e);
    }
  };
}

export function handleTextChange<T>(
  key: string,
  object: T,
  setObject: React.Dispatch<React.SetStateAction<T>>,
  Class: new (...args: any[]) => T,
  onCallback?: (object: T) => void
) {
  return (event: React.ChangeEvent<HTMLInputElement>) => {
    const newObject = new Class({
      ...object,
      [key]: event.target.value,
    });

    setObject(newObject);
    onCallback && onCallback(newObject);
  };
}

export function handleNumberChange<T>(
  key: string,
  object: T,
  setObject: React.Dispatch<React.SetStateAction<T>>,
  Class: new (...args: any[]) => T,
  onCallback?: (object: T) => void
) {
  return (event: React.ChangeEvent<HTMLInputElement>) => {
    const newObject = new Class({
      ...object,
      [key]: event.target.value ? parseInt(event.target.value) : undefined,
    });

    setObject(newObject);
    onCallback && onCallback(newObject);
  };
}

export function handlePriceChange<T>(
  key: string,
  object: T,
  setObject: React.Dispatch<React.SetStateAction<T>>,
  Class: new (...args: any[]) => T,
  onCallback?: (object: T) => void
) {
  return (price: number) => {
    const newObject = new Class({
      ...object,
      [key]: price,
    });

    setObject(newObject);
    onCallback && onCallback(newObject);
  };
}

export function handleOnChange<T, K extends keyof T>(
  key: K,
  object: T,
  setObject: React.Dispatch<React.SetStateAction<T>>,
  Class: new (...args: any[]) => T,
  isNumber: boolean,
  onCallback?: (object: T) => void
) {
  return (e: React.ChangeEvent<{ value?: string }>) => {
    const newObject = new Class({
      ...object,
      [key]: isNumber ? parseInt(e.target.value || "0") : e.target.value,
    });

    setObject(newObject);
    onCallback && onCallback(newObject);
  };
}
