import { useWorkOrderPhotosApi, useWorkOrdersApi } from "../apis";
import { WorkOrderPhoto } from "../classes";
import { usePhotosGeneric } from "./usePhotosGeneric";

interface UseWorkOrderPhotosProps {
  workOrderId?: number;
  callback?: () => void;
}

export function useWorkOrderPhotos({
  workOrderId,
  callback,
}: UseWorkOrderPhotosProps) {
  const { getWorkOrderPhotos, addWorkOrderPhoto } = useWorkOrdersApi();
  const { deleteWorkOrderPhoto, updateWorkOrderPhoto } =
    useWorkOrderPhotosApi();

  const saveNewPhoto = (photo: WorkOrderPhoto) => {
    if (workOrderId === undefined) throw new Error("Work Order ID is required");
    if (!photo.file) throw new Error("File is required");

    const photoData = new FormData();
    photoData.append("photo", photo.file);
    photoData.append("caption", photo.caption);

    return addWorkOrderPhoto(workOrderId, photoData);
  };

  const saveRemovePhoto = (photo: WorkOrderPhoto) =>
    deleteWorkOrderPhoto(photo.id as number);

  const saveUpdatePhoto = (photo: WorkOrderPhoto) =>
    updateWorkOrderPhoto(photo.id as number, photo);

  const {
    loading,
    photos,

    addPhoto,
    removePhoto,
    updatePhoto,
  } = usePhotosGeneric({
    id: workOrderId,
    fetchPhotosFunc: getWorkOrderPhotos,
    PhotoClass: WorkOrderPhoto,

    saveNewPhoto,
    saveRemovePhoto,
    saveUpdatePhoto,
    callback,
  });

  return {
    loading,
    photos,

    addPhoto,
    removePhoto,
    updatePhoto,
  };
}
