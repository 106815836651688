const handleSaveImage = async (image: File) => {
  if (!window.ReactNativeWebView) return;

  // save to camera roll
  const message = JSON.stringify({
    action: "save",
    url: URL.createObjectURL(image),
    dialogTitle: "Save Photo",
  });

  window.ReactNativeWebView.postMessage(message);
};

export default handleSaveImage;
