import DatabaseObject, {
  DatabaseObjectConstructorType,
} from "./database-object";
import { Resident, ResidentConstructorType } from "./resident";
import { User } from "./user";

export interface ResidentCommentConstructorType
  extends DatabaseObjectConstructorType {
  comment: string;
  resident?: ResidentConstructorType;
  residentId: number;
  creatorId?: number;
  creator?: User;
}

export class ResidentComment extends DatabaseObject {
  comment = "";
  resident?: Resident;
  residentId: number;
  creatorId?: number;
  creator?: User;

  constructor(data: ResidentCommentConstructorType) {
    super(data);

    this.residentId = data.residentId;
    this.comment = data.comment;
    if (data.resident) this.resident = new Resident(data.resident);
    if (data.creatorId) this.creatorId = data.creatorId;
    if (data.creator) this.creator = new User(data.creator);
  }

  prepareForApi() {
    const obj = super.prepareForApi() as Partial<ResidentComment>;

    delete obj.resident;
    delete obj.creator;

    return obj;
  }
}
