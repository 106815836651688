declare global {
  interface Window {
    ReactNativeWebView?: any;
  }
}

interface Message {
  action: string;
  [key: string]: string | number;
}

export default function postMessage(message: Message) {
  if (window?.ReactNativeWebView?.postMessage) {
    console.log("postMessage", JSON.stringify(message));
    window.ReactNativeWebView.postMessage(JSON.stringify(message));
  }
}
