// Resize the incoming image to a small resolution for:
// 1) Lower bandwidth when uploading images.
// 2) Lower storage usage.

export default function resizePhoto(incomingPhoto: File): Promise<File> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = function (readerEvent) {
      const img = document.createElement("img");
      img.onload = function () {
        // Dynamically create a canvas element
        const canvas = document.createElement("canvas");
        const max_size = 2560;
        let width = img.width;
        let height = img.height;
        if (width > height) {
          if (width > max_size) {
            height *= max_size / width;
            width = max_size;
          }
        } else {
          if (height > max_size) {
            width *= max_size / height;
            height = max_size;
          }
        }
        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext("2d");

        if (!ctx) return;

        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            if (!blob) return;

            // Get the file name without its extension more reliably
            const parts = incomingPhoto.name.split(".");
            const baseName = parts.slice(0, -1).join(".");
            const fileName = `${baseName}.jpg`;

            const photo = new File([blob], fileName, {
              type: "image/jpeg",
            });

            resolve(photo);
          },
          "image/jpeg",
          0.5
        );
      };

      if (!readerEvent.target) return;
      img.src = readerEvent.target.result as string;
    };
    reader.readAsDataURL(incomingPhoto);
  });
}
