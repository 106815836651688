import React, { useState, useCallback } from "react";
import { useWorkOrdersApi } from "../apis";
import { useWorkOrderCommentsApi } from "../apis/work-order-comments";
import { WorkOrderComment } from "../classes";
import { CommentBase } from "./CommentsBase";

export function WorkOrderComments({
  workOrderId,
  disabled,
}: {
  workOrderId?: number;
  disabled?: boolean;
}) {
  if (!workOrderId) return null;

  const [comments, setComments] = useState<WorkOrderComment[]>([]);

  const { getWorkOrderComments } = useWorkOrdersApi();
  const { deleteWorkOrderComment } = useWorkOrderCommentsApi();
  const { addWorkOrderComment } = useWorkOrdersApi();
  const { updateWorkOrderComment } = useWorkOrderCommentsApi();

  const fetchComments = useCallback(async () => {
    const _comments = await getWorkOrderComments(workOrderId);
    setComments(_comments.items);
  }, [getWorkOrderComments, workOrderId]);

  const onDelete = useCallback(
    async (comment: WorkOrderComment) => {
      if (window.confirm("Are you sure you want to delete this?")) {
        await deleteWorkOrderComment(comment.id as number);
        fetchComments();
      }
    },
    [deleteWorkOrderComment, fetchComments]
  );

  const onSave = useCallback(
    async (item: WorkOrderComment) => {
      if (item.id === undefined) {
        await addWorkOrderComment(workOrderId, item.prepareForApi());
      } else {
        await updateWorkOrderComment(item.id, item.prepareForApi());
      }
    },
    [addWorkOrderComment, updateWorkOrderComment, workOrderId]
  );

  return (
    <CommentBase<WorkOrderComment>
      labelPlural="Comments"
      labelSingular="Comment"
      id={workOrderId}
      disabled={disabled}
      items={comments}
      fetchItems={fetchComments}
      onDelete={onDelete}
      onSave={onSave}
      newClass={(item, text) =>
        new WorkOrderComment({
          ...item,
          comment: text ?? "",
        })
      }
      getTextFieldValue={(item) => item.comment}
    />
  );
}
