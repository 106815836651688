import { useUserPhotosApi, useUsersApi } from "../apis";
import { UserPhoto } from "../classes";
import { usePhotosGeneric } from "./usePhotosGeneric";

interface UseUserPhotosProps {
  userId?: number;
  callback?: () => void;
}

export function useUserPhotos({ userId, callback }: UseUserPhotosProps) {
  const { getUserPhotos, addUserPhoto } = useUsersApi();
  const { deleteUserPhoto, updateUserPhoto } = useUserPhotosApi();

  const saveNewPhoto = (photo: UserPhoto) => {
    if (userId === undefined) throw new Error("User ID is required");
    if (!photo.file) throw new Error("File is required");

    const photoData = new FormData();
    photoData.append("photo", photo.file);
    photoData.append("caption", photo.caption);

    return addUserPhoto(userId, photoData);
  };

  const saveRemovePhoto = (photo: UserPhoto) =>
    deleteUserPhoto(photo.id as number);

  const saveUpdatePhoto = (photo: UserPhoto) =>
    updateUserPhoto(photo.id as number, photo);

  const {
    loading,
    photos,

    addPhoto,
    removePhoto,
    updatePhoto,
  } = usePhotosGeneric({
    id: userId,
    fetchPhotosFunc: getUserPhotos,
    PhotoClass: UserPhoto,

    saveNewPhoto,
    saveRemovePhoto,
    saveUpdatePhoto,
    callback,
  });

  return {
    loading,
    photos,

    addPhoto,
    removePhoto,
    updatePhoto,
  };
}
