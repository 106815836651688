import React from "react";
import ResidentsFormPage from "../../../components/ResidentsFormPage";
import { useParams } from "react-router-dom";
import { useResidentsApi } from "../../../apis";
import { Resident } from "../../../classes/resident";

export default function MoveOut() {
  const { id: residentId } = useParams();
  const { updateResident } = useResidentsApi();

  const onSave = async (resident: Resident) =>
    updateResident(Number(residentId), resident.prepareForApi());

  return (
    <ResidentsFormPage
      title="Move Out"
      status="MOVED_OUT"
      residentId={Number(residentId)}
      onSave={onSave}
      saveButtonText="Save Move Out"
    />
  );
}
