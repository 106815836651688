import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { FormHeader } from "./FormHeader";
import ResidentsForm from "./ResidentsForm";
import ActivityList from "./ActivityList";
import CollectionsHistory from "./CollectionsHistory";
import { useResidentsApi } from "../apis";
import { useMe } from "../store/me";
import {
  Resident,
  PENDING,
  REQUEST_MOVE_IN,
  MOVED_IN,
  MOVED_OUT,
} from "../classes/resident";

import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import { Grid, Button } from "@mui/material";
import { convertLocalToUTC } from "../utils/date-helpers";
import getHours from "date-fns/getHours";
import startOfYesterday from "date-fns/startOfYesterday";
import startOfToday from "date-fns/startOfToday";
import startOfDay from "date-fns/startOfDay";
import { useFiles, usePhotos, useUaPhotos } from "../hooks";
import { useGlobalLoading } from "./GlobalLoading";

interface ResidentsFormPageProps {
  residentId?: number;
  status: string;
  title: string;
  saveButtonText: string;
  onSave: (resident: Resident) => Promise<Resident>;
  onDelete?: (resident: Resident) => Promise<void>;
}

export default function ResidentsFormPage({
  title,
  status,
  residentId,
  onSave,
  onDelete,
  saveButtonText,
}: ResidentsFormPageProps) {
  const navigate = useNavigate();
  const { setGlobalLoading } = useGlobalLoading();
  const { isAdmin } = useMe();

  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [resident, setResident] = useState<Resident>(new Resident({ status }));

  const { getResident } = useResidentsApi();

  const {
    uaPhotos,

    addUaPhoto,
    removeUaPhoto,
    updateUaPhoto,
  } = useUaPhotos({ residentId });

  const {
    photos,

    addPhoto,
    removePhoto,
    updatePhoto,
  } = usePhotos({ residentId });

  const {
    files,

    addFile,
    removeFile,
  } = useFiles({ residentId });

  const fetchResident = useCallback(async () => {
    setGlobalLoading(true);
    const _resident: Resident = await getResident(residentId as number);
    setGlobalLoading(false);

    let dirty = false;

    // If executing a user flow (i.e. moved_in, move_out)
    // change the status here.
    if (
      status === PENDING ||
      status === REQUEST_MOVE_IN ||
      status === MOVED_IN ||
      status === MOVED_OUT
    ) {
      _resident.status = status;
      dirty = true;
    }

    if (status === MOVED_IN) {
      if (!_resident.moveInDate) {
        // If it's before 7am, apply yesterday's date
        if (getHours(new Date()) < 7) {
          _resident.moveInDate = convertLocalToUTC(startOfYesterday());
        } else {
          _resident.moveInDate = convertLocalToUTC(startOfToday());
        }
      }
    }

    if (status === MOVED_OUT) {
      if (!_resident.moveOutDate) {
        // If it's before 7am, apply yesterday's date
        if (getHours(new Date()) < 7) {
          _resident.moveOutDate = convertLocalToUTC(startOfYesterday());
        } else {
          _resident.moveOutDate = convertLocalToUTC(startOfToday());
        }
      }
    }

    const newResident = new Resident(_resident);

    setResident(newResident);
    setIsDirty(dirty);
  }, [getResident, residentId, status]);

  useEffect(() => {
    if (residentId) {
      fetchResident();
    }
  }, [residentId, fetchResident]);

  // Have any background resident changes to be synced
  // with the user's UI. This is necessary because a manager
  // can add a collection when editing/viewing a Resident. When
  // adding a Collection that changes the Resident's `rentDueDate`,
  // it updates the Resident in the background. If the Manager
  // happens to "save" the Resident, it will overwrite any changes
  // that happened to the Resident in the background, for example,
  // the `rentDueDate` being cleared.

  const [isUploading, setIsUploading] = useState(false);

  const _onSave = async () => {
    try {
      if (!resident) return;

      setIsUploading(true);

      await onSave(resident);

      navigate(-1);
    } catch (e) {
      if (e instanceof Error) {
        alert(e.message);
      }
    } finally {
      setIsUploading(false);
    }
  };

  const _onDelete = async () => {
    if (!resident) return;
    onDelete && (await onDelete(resident));
    navigate(-1);
  };

  return (
    <Grid width="100%">
      <FormHeader
        title={title}
        primaryButton={{
          onClick: _onSave,
          text: isUploading
            ? "Uploading..."
            : saveButtonText
            ? saveButtonText
            : "Save",
          endIcon: <SaveIcon />,
          disabled: !isDirty || isUploading,
        }}
        isDirty={isDirty}
      />

      <ResidentsForm
        status={status}
        resident={resident}
        setResident={setResident}
        setResidentCallback={() => setIsDirty(true)}
        uaPhotos={uaPhotos}
        addUaPhoto={addUaPhoto}
        removeUaPhoto={removeUaPhoto}
        updateUaPhoto={updateUaPhoto}
        photos={photos}
        addPhoto={addPhoto}
        removePhoto={removePhoto}
        updatePhoto={updatePhoto}
        files={files}
        addFile={addFile}
        removeFile={removeFile}
        saveResident={async () => {
          if (isDirty) {
            const newResident = await onSave(resident);
            setResident(new Resident(newResident));
            setIsDirty(false);
          }
        }}
        updateResident={async () => {
          console.log("updateResident");
          const newResident = await getResident(residentId as number);
          setResident(new Resident(newResident));
          setIsDirty(false);
        }}
      />

      <CollectionsHistory resident={resident} fetchResident={fetchResident} />

      {residentId && <ActivityList residentId={residentId} />}

      {status === "VIEW" && isAdmin && (
        <Grid container justifyContent="center" sx={{ marginTop: 5 }}>
          <Button color="error" onClick={_onDelete} startIcon={<DeleteIcon />}>
            Delete Resident
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
