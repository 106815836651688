import { useCallback } from "react";
import {
  QueryParams,
  useApi,
  GetListInputType,
  GetListOutputType,
} from "../hooks";
import {
  Resident,
  Photo,
  UaPhoto,
  ResidentComment,
  ResidentReport,
} from "../classes";

export function useResidentsApi() {
  const {
    fetchGETList,
    fetchGET,
    fetchGETTyped,
    fetchPOST,
    fetchPOSTTyped,
    fetchPOSTMultiple,
    fetchPUT,
    fetchDELETE,
  } = useApi();

  const getResident = useCallback(
    (id: number) => fetchGET(`residents/${id}`),
    [fetchGET]
  );

  const getResidents = useCallback(
    (queryParams: QueryParams) => fetchGETList("residents", queryParams),
    [fetchGETList]
  );

  const sendSmsToResident = useCallback(
    (data: object) => fetchPOST("residents/send-sms", data),
    [fetchPOST]
  );

  const addResident = useCallback(
    (body: object) => fetchPOST("residents", body),
    [fetchPOST]
  );

  const updateResident = useCallback(
    (id: number, data: object) => fetchPUT(`residents/${id}`, data),
    [fetchPUT]
  );

  const deleteResident = useCallback(
    (id: number) => fetchDELETE(`residents/${id}`),
    [fetchDELETE]
  );

  const getResidentUaPhotos = useCallback(
    (id: number) => fetchGET(`residents/${id}/ua-photos`),
    [fetchGET]
  );

  const addResidentUaPhoto = useCallback(
    (id: number, data: object) =>
      fetchPOSTMultiple(`residents/${id}/ua-photos`, data),
    [fetchPOST]
  );

  const getResidentPhotos = useCallback(
    (id: number) => fetchGET(`residents/${id}/photos`),
    [fetchGET]
  );

  const addResidentPhoto = useCallback(
    (id: number, data: object) =>
      fetchPOSTMultiple(`residents/${id}/photos`, data),
    [fetchPOSTMultiple]
  );

  const getResidentComments = useCallback(
    async (id: number) => {
      const response = await fetchGETTyped<GetResidentCommentsOutputType>(
        `residents/${id}/comments`
      );

      response.items = response.items.map((i) => new ResidentComment(i));

      return response;
    },
    [fetchGETTyped]
  );

  const addResidentReport = useCallback(
    async (id: number, data: object) => {
      const response = await fetchPOSTTyped<ResidentReport>(
        `residents/${id}/reports`,
        data
      );

      return new ResidentReport(response);
    },

    [fetchPOSTTyped]
  );

  const getResidentReports = useCallback(
    async (id: number) => {
      const response = await fetchGETTyped<GetResidentReportsOutputType>(
        `residents/${id}/reports`
      );

      response.items = response.items.map((i) => new ResidentReport(i));

      return response;
    },
    [fetchGET]
  );

  const addResidentComment = useCallback(
    async (id: number, data: object) => {
      const response = await fetchPOSTTyped<ResidentComment>(
        `residents/${id}/comments`,
        data
      );

      return new ResidentComment(response);
    },

    [fetchPOST]
  );

  const getResidentFiles = useCallback(
    (id: number) => fetchGET(`residents/${id}/files`),
    [fetchGET]
  );

  const addResidentFile = useCallback(
    (id: number, data: object) =>
      fetchPOSTMultiple(`residents/${id}/files`, data),
    [fetchPOSTMultiple]
  );

  return {
    getResident,
    getResidents,
    sendSmsToResident,
    addResident,
    updateResident,
    deleteResident,
    getResidentUaPhotos,
    addResidentUaPhoto,
    getResidentPhotos,
    addResidentPhoto,
    getResidentComments,
    addResidentComment,
    addResidentReport,
    getResidentReports,
    getResidentFiles,
    addResidentFile,
  };
}

export interface GetResidentsInputType extends GetListInputType {
  statuses?: string[] | string;
  search?: string;
  locations?: number | number[];
  districts?: number | number[];
  rentDueDate__eq?: string;
  rentDueDate__lt?: string;
  isGeo?: boolean;
  hasNoRentDueDate?: boolean;
  includeNegativeBalance?: boolean;
  lastUaDate__order?: "asc" | "desc";
}

export type GetResidentsOutputType = GetListOutputType<Resident>;

export type GetResidentPhotosOutputType = GetListOutputType<Photo>;

export type GetResidentUaPhotosOutputType = GetListOutputType<UaPhoto>;

export type GetResidentCommentsOutputType = GetListOutputType<ResidentComment>;

export type GetResidentReportsOutputType = GetListOutputType<ResidentReport>;
