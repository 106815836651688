import DatabaseObject, {
  DatabaseObjectConstructorType,
} from "./database-object";
import { Resident } from "./resident";

export interface CurfewConstructorType extends DatabaseObjectConstructorType {
  date: Date | string;
  destination?: string;
  eta?: string;
  residentId: number;
  resident?: Resident;
}

export class Curfew extends DatabaseObject {
  date: Date;
  destination?: string;
  eta?: string;
  residentId: number;
  resident?: Resident;

  constructor(data: CurfewConstructorType) {
    super(data);

    this.residentId = data.residentId;
    this.date = new Date(data.date);

    if (data.destination) this.destination = data.destination;
    if (data.eta) this.eta = data.eta;
    if (data.resident) this.resident = data.resident;
  }

  prepareForApi() {
    const obj = super.prepareForApi() as Curfew;

    delete obj.resident;

    return obj;
  }
}
