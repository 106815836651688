import DatabaseObject, {
  DatabaseObjectConstructorType,
} from "./database-object";
import format from "date-fns/format";
import startOfDay from "date-fns/startOfDay";
import {
  CollectionItem,
  CollectionItemConstructorType,
  COLLECTION_ITEM_TYPES,
  PRODUCT_CODES,
} from "./collection-item";
import {
  CollectionPayment,
  CollectionPaymentConstructorType,
  COLLECTION_PAYMENT_TYPES,
} from "./collection-payment";
import { Resident, ResidentConstructorType } from "./resident";
import { User } from "./user";
import {
  bumpRentDueDateLocal,
  convertUTCToLocal,
  convertLocalToUTC,
} from "../utils/date-helpers";

export const CollectionStatusTypes = {
  DRAFT: "DRAFT",
  PENDING: "PENDING",
  PENDING_DELETE: "PENDING_DELETE",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
};

export interface CollectionConstructorType
  extends DatabaseObjectConstructorType {
  residentId?: number;
  resident?: ResidentConstructorType;
  notes?: string;
  rejectedNotes?: string;
  datePaid?: Date | string;
  dateApproved?: Date | string;
  status?: string;
  items?: CollectionItemConstructorType[];
  payments?: CollectionPaymentConstructorType[];
  creatorId?: number;
  creator?: User;
}

export class Collection extends DatabaseObject {
  residentId?: number;
  resident?: Resident;
  notes?: string = "";
  rejectedNotes?: string = "";
  datePaid?: Date;
  dateApproved?: Date;
  status?: string;
  items?: CollectionItem[];
  payments?: CollectionPayment[];
  creatorId?: number;
  creator?: User;

  constructor(data?: CollectionConstructorType) {
    super(data);

    if (!data) return;

    if (data.residentId) this.residentId = data.residentId;
    if (data.resident) this.resident = new Resident(data.resident);
    if (data.notes) this.notes = data.notes;
    if (data.rejectedNotes) this.rejectedNotes = data.rejectedNotes;
    if (data.datePaid) this.datePaid = new Date(data.datePaid);
    if (data.dateApproved) this.dateApproved = new Date(data.dateApproved);
    if (data.status) this.status = data.status;
    if (data.items) this.items = data.items.map((ci) => new CollectionItem(ci));
    if (data.payments)
      this.payments = data.payments.map((cp) => new CollectionPayment(cp));
    if (data.creatorId) this.creatorId = data.creatorId;
    if (data.creator) this.creator = new User(data.creator);
  }

  prepareForApi() {
    const obj = super.prepareForApi() as Partial<Collection>;

    delete obj.resident;
    delete obj.creator;

    return obj;
  }

  draft() {
    this.status = CollectionStatusTypes.DRAFT;
  }
  pending() {
    this.status = CollectionStatusTypes.PENDING;
  }
  pendingDelete() {
    this.status = CollectionStatusTypes.PENDING_DELETE;
  }
  reject() {
    this.status = CollectionStatusTypes.REJECTED;
  }
  approve() {
    this.status = CollectionStatusTypes.APPROVED;
  }

  isDraft() {
    return this.status === CollectionStatusTypes.DRAFT;
  }
  isPending() {
    return this.status === CollectionStatusTypes.PENDING;
  }
  isPendingDelete() {
    return this.status === CollectionStatusTypes.PENDING_DELETE;
  }
  isRejected() {
    return this.status === CollectionStatusTypes.REJECTED;
  }
  isApproved() {
    return this.status === CollectionStatusTypes.APPROVED;
  }

  addItem(item: CollectionItem) {
    if (!this.items) this.items = [];
    this.items = [...this.items, item];
  }

  removeItem(index: number): CollectionItem | number | null {
    if (!this.items || !this.items.length) return null;

    const itemToRemove = this.items[index];

    this.items = [
      ...this.items.slice(0, index),
      ...this.items.slice(index + 1),
    ];

    return itemToRemove;
  }

  replaceItem(
    index: number,
    item: CollectionItem
  ): CollectionItem | number | null {
    if (!this.items || !this.items.length) return null;

    const itemBeingReplaced = this.items[index];

    this.items = [
      ...this.items.slice(0, index),
      item,
      ...this.items.slice(index + 1),
    ];

    return itemBeingReplaced;
  }

  addPayment(payment: CollectionPayment) {
    if (!this.payments) this.payments = [];
    this.payments = [...this.payments, payment];
  }

  removePayment(index: number): CollectionPayment | number | null {
    if (!this.payments || !this.payments.length) return null;
    const paymentToRemove = this.payments[index];
    this.payments = [
      ...this.payments.slice(0, index),
      ...this.payments.slice(index + 1),
    ];

    return paymentToRemove;
  }

  replacePayment(
    index: number,
    payment: CollectionPayment
  ): CollectionPayment | number | null {
    if (!this.payments || !this.payments.length) return null;
    const paymentBeingReplaced = this.payments[index];
    this.payments = [
      ...this.payments.slice(0, index),
      payment,
      ...this.payments.slice(index + 1),
    ];

    return paymentBeingReplaced;
  }

  total() {
    if (!this.items || !this.items.length) return null;
    return this.items.reduce((sum, item) => (sum += item.price), 0);
  }

  totalIgnoringDebtAndPrepayment() {
    if (!this.items || !this.items.length) return null;

    return this.items
      .filter((item) => !item.isDebt())
      .filter((item) => !item.isPrepayment())
      .reduce((sum, item) => (sum += item.price), 0);
  }

  amountPaid() {
    if (!this.payments || !this.payments.length) return 0;
    return this.payments.reduce((sum, payment) => (sum += payment.amount), 0);
  }

  amountPaidOnlyCashCheck() {
    if (!this.payments || !this.payments.length) return 0;
    return this.payments
      .filter((payment) => {
        return payment.type === COLLECTION_PAYMENT_TYPES.CASH_CHECK;
      })
      .reduce((sum, payment) => (sum += payment.amount), 0);
  }

  amountDue() {
    const total = this.total() || 0;
    const amountPaid = this.amountPaid();
    return total - amountPaid;
  }

  amountBalance() {
    const amounPaidWithoutCredit = this.amountPaidOnlyCashCheck();
    const totalIgnoringDebtAndPrepayment =
      this.totalIgnoringDebtAndPrepayment() || 0;
    return totalIgnoringDebtAndPrepayment - amounPaidWithoutCredit;
  }

  getItem(index: number) {
    if (!this.items || !this.items.length) return null;
    return this.items[index];
  }

  getProductItem() {
    if (!this.items || !this.items.length) return null;
    return this.items.find(
      (item) => item.type === COLLECTION_ITEM_TYPES.PRODUCT
    );
  }

  hasProductAlready() {
    return !!this.getProductItem();
  }

  isProductCustom() {
    const collectionItem = this.getProductItem();

    if (!collectionItem) return false;

    return collectionItem.code === PRODUCT_CODES.CUSTOM;
  }

  setRentDueDate(resident: Resident, dateUTC?: Date) {
    if (!this.items || !this.items.length) return;

    const collectionItem = this.getProductItem();

    if (!collectionItem) {
      // If there's no item, there's no change to Rent Due Date.
      // Clear the rentDueDate. We don't know if and which item was
      // switched to a non-product, so we need to run through
      // all the collectionItems.
      this.items.forEach((collectionItem) => {
        if (collectionItem.rentDueDate) {
          collectionItem.rentDueDate = undefined;
        }
      });
      return;
    }

    let baseRentDueDateLocal;
    if (resident.rentDueDate) {
      baseRentDueDateLocal = convertUTCToLocal(resident.rentDueDate);
    } else {
      const moveInDateLocal = convertUTCToLocal(
        resident.moveInDate || new Date()
      );
      baseRentDueDateLocal = startOfDay(moveInDateLocal);
    }

    let dateLocal;
    if (dateUTC) dateLocal = convertUTCToLocal(dateUTC);

    const nextRentDueDateLocal = bumpRentDueDateLocal(
      baseRentDueDateLocal,
      collectionItem.code,
      dateLocal
    );

    collectionItem.rentDueDate = convertLocalToUTC(nextRentDueDateLocal);
  }

  static getOverallBalance(collections: Collection[]) {
    const _collections = collections
      // Filter on valid collections
      .filter((c) => c.isPending() || c.isApproved());

    const balance = _collections.reduce((acc, collection) => {
      return acc + collection.amountBalance();
    }, 0);

    return balance;
  }
}
