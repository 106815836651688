import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import SendSms from "./SendSms";

export default function MessagingRoutes() {
  return (
    <Routes>
      <Route path="/" element={<SendSms />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}
