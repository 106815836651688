import { useResidentsApi, usePhotosApi } from "../apis";
import { Photo } from "../classes";
import { usePhotosGeneric } from "./usePhotosGeneric";

interface UsePhotosProps {
  residentId?: number;
  callback?: () => void;
}

export function usePhotos({ residentId, callback }: UsePhotosProps) {
  const { getResidentPhotos, addResidentPhoto } = useResidentsApi();
  const { deletePhoto, updatePhoto: _updatePhoto } = usePhotosApi();

  const saveNewPhoto = (photo: Photo) => {
    if (residentId === undefined) throw new Error("Resident ID is required");
    if (!photo.file) throw new Error("File is required");

    const photoData = new FormData();
    photoData.append("photo", photo.file);
    photoData.append("caption", photo.caption);

    return addResidentPhoto(residentId, photoData);
  };

  const saveRemovePhoto = async (photo: Photo) =>
    deletePhoto(photo.id as number);

  const saveUpdatePhoto = async (photo: Photo) =>
    _updatePhoto(photo.id as number, photo);

  const {
    photos,

    addPhoto,
    removePhoto,
    updatePhoto,
  } = usePhotosGeneric<Photo>({
    id: residentId,
    callback,
    fetchPhotosFunc: getResidentPhotos,
    PhotoClass: Photo,

    saveNewPhoto,
    saveRemovePhoto,
    saveUpdatePhoto,
  });

  return {
    photos,

    addPhoto,
    removePhoto,
    updatePhoto,
  };
}
