import React from "react";
import { NavLinkTab, NavLinkTabGroup } from "./NavLinkTab";

const CollectionsTabs = () => {
  return (
    <NavLinkTabGroup>
      <NavLinkTab to="work-orders">Work Orders</NavLinkTab>
      <NavLinkTab to="contractors">Contractors</NavLinkTab>
    </NavLinkTabGroup>
  );
};

export default CollectionsTabs;
