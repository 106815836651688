import React from "react";
import ResidentsFormPage from "../../../components/ResidentsFormPage";
import { Resident } from "../../../classes/resident";
import { useResidentsApi } from "../../../apis";

export default function Pending() {
  const { addResident } = useResidentsApi();

  const onSave = async (resident: Resident) =>
    addResident(resident.prepareForApi());

  return (
    <ResidentsFormPage
      title="Pending"
      status="PENDING"
      onSave={onSave}
      saveButtonText="Save Pending"
    />
  );
}
