import React, { useState, useEffect, useMemo, useRef } from "react";
import debounce from "lodash/debounce";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Box,
  Grid,
  Typography,
  Button,
  IconButton,
  Badge,
  Autocomplete,
  TextField,
} from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";

import { useTableFilter, Filter } from "./TableFilter";
import { colors, roundCorners } from "../constants";
import { Collection } from "../classes/collection";
import { Location } from "../classes/location";
import { Resident } from "../classes/resident";
import { User } from "../classes/user";
import { Activity } from "../classes/activity";
import useLocalStorageState from "../hooks/useLocalStorageState";
import { WorkOrder } from "../classes/work-order";
import { Contractor } from "../classes/contractor";
import { Curfew, ResidentReport } from "../classes";

interface TableProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

export const Table = ({ children, style }: TableProps) => (
  <Grid container direction="column" wrap="nowrap" style={style}>
    {children}
  </Grid>
);

interface TableCellProps {
  children: React.ReactNode;
  basis?: string;
  color?: string;
  bold?: boolean;
  semiBold?: boolean;
  ellipsis?: boolean;
}

export const TableCell = ({
  children,
  basis,
  color,
  bold,
  semiBold,
  ellipsis,
}: TableCellProps) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      padding: "0 0.5rem",
      minHeight: 22,
      flexGrow: 0,
      flexShrink: 1,
      flexBasis: basis,

      overflow: "hidden",
    }}
  >
    <p
      style={{
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: ellipsis ? "ellipsis" : undefined,
        color: color,
        fontWeight: bold ? "bold" : semiBold ? 600 : undefined,
        height: 22,
        padding: 0,
        margin: 0,
      }}
    >
      {children}
    </p>
  </div>
);

interface TableHeaderProps {
  children: React.ReactNode;
}

export const TableHeader = ({ children }: TableHeaderProps) => (
  <div
    style={{
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
      borderBottom: "1px solid #111111",
    }}
  >
    {children}
  </div>
);

interface TableSelectedSectionButtonProps {
  icon: any;
  children: React.ReactNode;
  onClick: () => void;
}

export const TableSelectedSectionButton = ({
  icon,
  children,
  onClick,
}: TableSelectedSectionButtonProps) => (
  <Box
    sx={{
      mx: 1,
      p: 1,
      color: colors.font.light,
      fontSize: "0.8rem",
      border: `1px solid ${colors.white}`,
      borderRadius: roundCorners[0],
      cursor: "pointer",
      minWidth: "3rem",
      "&:hover": {
        backgroundColor: colors.green[2],
      },
    }}
    onClick={onClick}
  >
    <Grid container justifyContent="center">
      <FontAwesomeIcon icon={icon} />
    </Grid>
    <Typography variant="caption">{children}</Typography>
  </Box>
);

interface SelectedSectionProps {
  row: RowType;
  SelectedSectionElem: (props: any) => JSX.Element;
  tableBodyScrollTop: number;
  onView?: (record: RowType) => void;
  onAddCollection?: (resident: Resident, collection: Collection) => void;
}

const SelectedSection = ({
  SelectedSectionElem,
  ...props
}: SelectedSectionProps) => {
  return (
    <Grid
      container
      wrap="nowrap"
      justifyContent="center"
      alignItems="center"
      onClick={(e) => e.stopPropagation()}
      sx={{
        backgroundColor: colors.green[3],
        height: "60px",
      }}
    >
      <SelectedSectionElem {...props} />
    </Grid>
  );
};

export interface TableRowProps {
  row: RowType;
  selected: boolean;
  SelectedSectionElem: (props: any) => JSX.Element;
  onClick: () => void;
  tableBodyRef: any;
  children: React.ReactNode;
}

export const TableRow = ({
  row,
  selected,
  SelectedSectionElem,
  children,
  onClick,
  tableBodyRef,
  ...props
}: TableRowProps) => (
  <Grid
    container
    direction="column"
    wrap="nowrap"
    onClick={onClick}
    sx={{
      borderBottom: "1px solid #ddd",
      transition: "0.1s",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: colors.grey[6],
      },
      boxShadow: selected ? `inset 0 0 20px ${colors.green[3]}` : undefined,
    }}
  >
    <Grid
      container
      justifyContent="space-between"
      wrap="wrap"
      style={{
        minHeight: 66,
      }}
    >
      {children}
    </Grid>
    {selected && (
      <SelectedSection
        row={row}
        SelectedSectionElem={SelectedSectionElem}
        tableBodyScrollTop={tableBodyRef.current?.scrollTop}
        {...props}
      />
    )}
  </Grid>
);

export interface RowType {
  activity?: Activity;
  collection?: Collection;
  contractor?: Contractor;
  curfew?: Curfew;
  location?: Location;
  resident?: Resident;
  residentReport?: ResidentReport;
  user?: User;
  workOrder?: WorkOrder;
}

interface RowElemProps {
  row: RowType;
  style?: any;
  selected: boolean;
  onClick: () => void;
  tableBodyRef: any;
}

interface TableBodyProps {
  rows: RowType[];
  total: number;
  RowElem: (rowElem: RowElemProps) => JSX.Element;
  EmptyViewElem: () => JSX.Element;
  onLoadMore: () => void;
  onView?: (record: RowType) => void;
  onRowSelect?: (record: RowType) => void;
  onAddCollection?: (resident: Resident, collection: Collection) => void;
  onPaymentContract?: (resident: Resident) => void;
  fetchData?: () => Promise<void>;
}

export const TableBody = ({
  rows,
  total,
  RowElem,
  EmptyViewElem,
  onLoadMore,
  onRowSelect,
  ...props
}: TableBodyProps) => {
  const tableBodyRef = useRef<any>(null);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);

  useEffect(() => {
    if (rows.length === 0) return;

    const tableBodyScrollTop = localStorage.getItem("tableBodyScrollTop");

    if (tableBodyRef.current && tableBodyScrollTop) {
      tableBodyRef.current.scrollTop = Number(tableBodyScrollTop);
      localStorage.removeItem("tableBodyScrollTop");
    }
  }, [rows.length, tableBodyRef.current]);

  const onRowClick = (index: number) => {
    if (onRowSelect) {
      onRowSelect(rows[index]);
      return;
    }

    if (index === selectedIndex) {
      setSelectedIndex(-1);
    } else {
      setSelectedIndex(index);
    }
  };

  if (rows.length === 0 && EmptyViewElem) return <EmptyViewElem />;

  const hasNextPage = rows.length < total;

  return (
    <Grid item sx={{ flex: "auto", overflowY: "scroll" }} ref={tableBodyRef}>
      {rows.map((row, index) => (
        <RowElem
          key={index}
          row={row}
          selected={index === selectedIndex}
          tableBodyRef={tableBodyRef}
          onClick={() => onRowClick(index)}
          {...props}
        />
      ))}

      {hasNextPage && (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{
            height: "60px",
          }}
        >
          <Button variant="outlined" color="primary" onClick={onLoadMore}>
            Load More
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

interface TableFilterButtonProps {
  filterContent: Filter[];
}

const TableFilterButton = ({ filterContent }: TableFilterButtonProps) => {
  const { setFilterModalActive } = useTableFilter();

  const filterClick = () => setFilterModalActive(true);

  const isFilterActive = filterContent.reduce(
    (prev, curr) => prev || curr.isActive(),
    false
  );

  return (
    <IconButton onClick={filterClick}>
      <Badge color="secondary" variant="dot" invisible={!isFilterActive}>
        <FilterListIcon />
      </Badge>
    </IconButton>
  );
};

interface TableSearchFieldProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  filterContent?: Filter[];
}

export const TableSearchField = ({
  value,
  onChange,
  filterContent,
}: TableSearchFieldProps) => {
  const [searchValue, setSearchValue] = useState(value);
  const [searchItems, setSearchItems] = useLocalStorageState(
    "filter.searchItems",
    []
  );

  const [open, setOpen] = useState(false);

  const handleOnChangeAndSetSearchItems = (event: any, newValue: any) => {
    onChange(event);
    const newSearchItems = searchItems;
    if (newValue.trim().length > 0) {
      setOpen(false);
      if (!searchItems.includes(newValue.trim())) {
        newSearchItems.unshift(newValue);
        // keep only the recent 3 searches to avoid bloating the local cache
        if (newSearchItems.length > 3) {
          newSearchItems.pop();
        }
        setSearchItems(newSearchItems);
      }
    } else {
      setOpen(true);
    }
  };

  const debouncedOnChange = useMemo(
    () => debounce(handleOnChangeAndSetSearchItems, 500),
    []
  );
  // Cancel the debounced onChange if the component unmounts
  useEffect(() => debouncedOnChange.cancel(), []);

  const onSearchChange = (event: any, newValue: any) => {
    setSearchValue(newValue);
    debouncedOnChange(event, newValue);
  };

  const filterOptions = createFilterOptions({
    limit: 3,
  });

  return (
    <Grid
      container
      alignItems="center"
      sx={{
        backgroundColor: colors.grey[6],
      }}
    >
      <Grid
        container
        justifyContent="space-around"
        alignItems="center"
        wrap="nowrap"
        spacing={1}
        sx={{ padding: 1 }}
      >
        <Grid item style={{ width: "100%" }}>
          <Autocomplete
            id="search"
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            filterOptions={filterOptions}
            freeSolo={true}
            options={searchItems}
            filterSelectedOptions={true}
            groupBy={(searchItem: any) => "Recent Searches"}
            getOptionLabel={(searchItem: any) => searchItem}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search"
                InputProps={{
                  ...params.InputProps,
                }}
                size="small"
              />
            )}
            inputValue={searchValue}
            onInputChange={onSearchChange}
            style={{ background: "white" }}
            value={searchValue}
          />
        </Grid>
        {filterContent && filterContent.length > 0 && (
          <Grid item>
            <TableFilterButton filterContent={filterContent} />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

interface TableFilterOnlyFieldProps {
  filterContent?: Filter[];
}

export const TableFilterOnlyField = ({
  filterContent,
}: TableFilterOnlyFieldProps) => {
  return (
    <Grid
      container
      alignItems="center"
      sx={{
        backgroundColor: colors.grey[6],
      }}
    >
      <Grid
        container
        justifyContent="space-around"
        alignItems="center"
        wrap="nowrap"
        spacing={1}
        sx={{ padding: 1 }}
      >
        <Grid item style={{ width: "100%" }}></Grid>
        {filterContent && filterContent.length > 0 && (
          <Grid item>
            <TableFilterButton filterContent={filterContent} />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
