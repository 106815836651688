import { useCallback } from "react";
import { useApi } from "../hooks";

export function useUaPhotosApi() {
  const { fetchPUT, fetchDELETE } = useApi();

  const updateUaPhoto = useCallback(
    (id: number, body: object) => fetchPUT(`ua-photos/${id}`, body),
    [fetchPUT]
  );

  const deleteUaPhoto = useCallback(
    (id: number) => fetchDELETE(`ua-photos/${id}`),
    [fetchDELETE]
  );

  return {
    updateUaPhoto,
    deleteUaPhoto,
  };
}
