import DatabaseObject, {
  DatabaseObjectConstructorType,
} from "./database-object";

export interface CollectionPaymentConstructorType
  extends DatabaseObjectConstructorType {
  type?: string;
  organization?: string;
  amount?: number;
  checkNumber?: string;
  isGeo?: boolean;
}

export class CollectionPayment extends DatabaseObject {
  type: string = COLLECTION_PAYMENT_TYPES.CASH_CHECK;
  organization: string = COLLECTION_PAYMENT_ORGANIZATION_TYPES.DROP_BOX;
  amount = 0;
  checkNumber = "";
  isGeo = false;

  organizationDropBox() {
    this.organization = COLLECTION_PAYMENT_ORGANIZATION_TYPES.DROP_BOX;
  }
  organizationOnline() {
    this.organization = COLLECTION_PAYMENT_ORGANIZATION_TYPES.ONLINE;
  }
  organizationMail() {
    this.organization = COLLECTION_PAYMENT_ORGANIZATION_TYPES.MAIL;
  }

  constructor(data?: CollectionPaymentConstructorType) {
    super(data);

    if (!data) return;

    if (data.type) this.type = data.type;
    if (data.organization) this.organization = data.organization;
    if (data.amount) this.amount = data.amount;
    if (data.checkNumber) this.checkNumber = data.checkNumber;
    if (data.isGeo) this.isGeo = data.isGeo;
  }

  isCredit() {
    return this.type === COLLECTION_PAYMENT_TYPES.CREDIT;
  }
}

export const COLLECTION_PAYMENT_TYPES = {
  CASH_CHECK: "CASH_CHECK",
  VOUCHER: "VOUCHER",
  RENT_DUE_DATE: "RENT_DUE_DATE",
  CREDIT: "CREDIT",
  DIRECT_DEPOSIT: "DIRECT_DEPOSIT",
};

export const collectionPaymentTypeOptions = [
  { text: "Credit", value: COLLECTION_PAYMENT_TYPES.CREDIT, disabled: true },
  { text: "Cash/Check", value: COLLECTION_PAYMENT_TYPES.CASH_CHECK },
  { text: "Voucher", value: COLLECTION_PAYMENT_TYPES.VOUCHER },
  { text: "Direct Deposit", value: COLLECTION_PAYMENT_TYPES.DIRECT_DEPOSIT },
];

export const COLLECTION_PAYMENT_ORGANIZATION_TYPES = {
  DROP_BOX: "DROP_BOX",
  ONLINE: "ONLINE",
  MAIL: "MAIL",
};

export const collectionPaymentOrganizationOptions = [
  {
    text: "Drop Box",
    value: COLLECTION_PAYMENT_ORGANIZATION_TYPES.DROP_BOX,
  },
  {
    text: "Online",
    value: COLLECTION_PAYMENT_ORGANIZATION_TYPES.ONLINE,
  },
  {
    text: "Mail",
    value: COLLECTION_PAYMENT_ORGANIZATION_TYPES.MAIL,
  },
];
