import React, { useState, useEffect, useCallback } from "react";
import { useDistrictsApi, useLocationsApi } from "../apis";
import { useMe } from "../store/me";
import Toggle from "./Toggle";
import { Select } from "./Select";
import { UAPhoto as UAPhotoComponent } from "./UAPhotos";
import Photos, { PhotoButton } from "./Photos";
import { PayScheduleItem } from "./PayScheduleItem";
import { Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import format from "date-fns/format";
import {
  REQUEST_MOVE_IN,
  MOVED_IN,
  MOVED_OUT,
  VIEW,
  LOCATION_CHANGE,
  RESIDENT_STATUS_OPTIONS,
} from "../classes";
import { Resident } from "../classes/resident";
import { Photo } from "../classes/photo";
import { UaPhoto } from "../classes/ua-photo";
import { convertUTCToLocal } from "../utils/date-helpers";
import { handleDateChange, handleOnChange } from "../utils/handlers";
import { sortLocations } from "../utils/sortLocations";
import { ResidentFile } from "../classes/resident-file";
import Files from "./Files";
import CustomPrice from "./CustomPrice";
import { ResidentComments } from "./ResidentComments";
import { ResidentReports } from "./ResidentReports";
import { formatPhoneNumber } from "../utils/formatPhoneNumber";
import { validatePhoneNumber } from "../utils/validatePhoneNumber";

interface ResidentsFormProps {
  status: string;
  resident: Resident;
  setResident: React.Dispatch<React.SetStateAction<Resident>>;
  setResidentCallback: (resident: Resident) => void;
  forceDisable?: boolean;

  uaPhotos: UaPhoto[];

  addUaPhoto: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  removeUaPhoto: (photo: UaPhoto) => Promise<void>;
  updateUaPhoto: (photos: UaPhoto) => Promise<void>;

  photos: Photo[];

  addPhoto: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  removePhoto: (photo: Photo) => Promise<void>;
  updatePhoto: (photos: Photo) => Promise<void>;

  files: ResidentFile[];

  addFile: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  removeFile: (photo: ResidentFile) => Promise<void>;

  saveResident: () => Promise<void>;
  updateResident: () => Promise<void>;
}

export default function ResidentsForm({
  status,
  resident,
  setResident,
  setResidentCallback,
  forceDisable,

  uaPhotos,

  addUaPhoto,
  removeUaPhoto,

  updateUaPhoto,

  photos,

  updatePhoto,

  addPhoto,
  removePhoto,

  files,

  addFile,
  removeFile,

  saveResident,
  updateResident,
}: ResidentsFormProps) {
  const { isAdmin } = useMe();

  const { getLocations } = useLocationsApi();
  const [locationOptions, setLocationOptions] = useState<
    { text: string; value: string }[]
  >([]);
  const fetchLocations = useCallback(async () => {
    const _locations = await getLocations();
    const sortedLocations = sortLocations(_locations.items);

    const _locationOptions = sortedLocations.map((l) => ({
      text: l.name,
      value: String(l.id),
    }));
    setLocationOptions(_locationOptions);
  }, [getLocations]);

  useEffect(() => {
    fetchLocations();
  }, [fetchLocations]);

  const { getDistricts } = useDistrictsApi();
  const [districtOptions, setDistrictOptions] = useState<
    { text: string; value: string }[]
  >([]);
  const fetchDistricts = useCallback(async () => {
    const _locations = await getDistricts();
    const _districtOptions = _locations.items.map((l) => ({
      text: l.name,
      value: String(l.id),
    }));
    setDistrictOptions(_districtOptions);
  }, [getDistricts]);

  useEffect(() => {
    fetchDistricts();
  }, [fetchDistricts]);

  const handleChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      let value;
      if (e instanceof Date || !e) {
        value = e;
      } else {
        switch (e.target.type) {
          case "checkbox":
            value = e.target.checked;
            break;
          case "number":
            value = parseInt(e.target.value);
            break;
          case "tel":
            // Remove the +1 at the beginning
            value = e.target.value.replace("+1", "");

            // Remove all non-numeric characters
            value = e.target.value.replace(/[^0-9]/g, "");

            // Only accept 10 digits
            value = value.slice(0, 10);

            // Add the +1 back
            if (value.length > 0) {
              value = "+1" + value;
            }
            break;
          default:
            value = e.target.value;
        }
      }

      const newResident = new Resident({
        ...resident,
        [key]: value,
      });
      setResident(newResident);
      setResidentCallback(newResident);
    };

  const handleCollectChange = (value: number) => {
    const newResident = new Resident({
      ...resident,
      totalCollected: value,
    });
    setResident(newResident);
    setResidentCallback(newResident);
  };

  const handleSelectChange =
    (key: string) => (e: React.ChangeEvent<{ value: unknown }>) => {
      const value = e.target.value;

      const newResident = new Resident({
        ...resident,
        [key]: value,
      });
      setResident(newResident);
      setResidentCallback(newResident);
    };

  const disabled = (fieldName: string) => {
    // Disable Override
    if (forceDisable) return true;

    if (fieldName === "contactNumber") return false;

    if (fieldName === "birthdate") return false;
    if (fieldName === "drugOfChoice") return false;
    if (fieldName === "emergencyContact") return false;
    if (fieldName === "apartmentNumber") return false;
    if (fieldName === "isEmployed") return false;
    if (fieldName === "isInUaProgram") return false;

    // On VIEW, we allow everything for Admins.
    if (status === VIEW) {
      if (isAdmin) {
        return false;
      } else {
        return true;
      }
    }

    switch (fieldName) {
      default:
        return [MOVED_OUT, LOCATION_CHANGE].findIndex((a) => a === status) > -1;

      case "locationId":
        return [MOVED_OUT].findIndex((a) => a === status) > -1;

      case "status":
        return true;

      case "moveOutDate":
        return (
          [REQUEST_MOVE_IN, MOVED_IN, LOCATION_CHANGE].findIndex(
            (a) => a === status
          ) > -1
        );
    }
  };

  if (!locationOptions) return <></>;
  if (!districtOptions) return <></>;

  return (
    <>
      <form>
        <Grid
          container
          spacing={2}
          style={{
            padding: 16,
          }}
        >
          <Grid item container>
            <TextField
              label="First Name"
              name="firstName"
              value={resident.firstName}
              onChange={handleChange("firstName")}
              disabled={disabled("firstName")}
              fullWidth
            />
          </Grid>
          <Grid item container>
            <TextField
              label="Last Name"
              name="lastName"
              value={resident.lastName}
              onChange={handleChange("lastName")}
              disabled={disabled("lastName")}
              fullWidth
            />
          </Grid>
          <Grid item container>
            <Select
              label="Resident Status"
              name="status"
              options={RESIDENT_STATUS_OPTIONS}
              value={resident.status as string}
              onChange={handleSelectChange("status")}
              disabled={disabled("status")}
            />
          </Grid>
          <Grid item container>
            <TextField
              label="Supervision"
              name="supervision"
              value={resident.supervision}
              onChange={handleChange("supervision")}
              disabled={disabled("supervision")}
              fullWidth
            />
          </Grid>
          <Grid item container>
            <TextField
              label="Contact Number"
              name="contactNumber"
              value={formatPhoneNumber(resident.contactNumber)}
              onChange={handleChange("contactNumber")}
              disabled={disabled("contactNumber")}
              error={!validatePhoneNumber(resident.contactNumber)}
              helperText={
                !validatePhoneNumber(resident.contactNumber)
                  ? "Invalid phone number"
                  : ""
              }
              type="tel"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+1</InputAdornment>
                ),
              }}
              fullWidth
            />
          </Grid>
          <Grid item container>
            <Select
              label="Location"
              name="locationId"
              options={locationOptions}
              value={resident.locationId ? String(resident.locationId) : ""}
              onChange={handleOnChange(
                "locationId",
                resident,
                setResident,
                Resident,
                true,
                setResidentCallback
              )}
              disabled={disabled("locationId")}
              addChooseOption
            />
          </Grid>
          <Grid item container>
            <Select
              label="District"
              name="districtId"
              options={districtOptions}
              value={resident.districtId ? String(resident.districtId) : ""}
              onChange={handleOnChange(
                "districtId",
                resident,
                setResident,
                Resident,
                true,
                setResidentCallback
              )}
              disabled={disabled("districtId")}
              addChooseOption
            />
          </Grid>
          <Grid item container>
            <Toggle
              label="Needs UA"
              name="needsUa"
              value={resident.needsUa}
              onChange={handleChange("needsUa")}
              disabled={disabled("needsUa")}
            />
          </Grid>
          <Grid item container>
            <Photos<UaPhoto>
              photos={uaPhotos}
              updatePhoto={updateUaPhoto}
              addPhoto={addUaPhoto}
              removePhoto={removeUaPhoto}
              editable={
                !forceDisable &&
                !![REQUEST_MOVE_IN, MOVED_IN, VIEW].find((d) => d === status)
              }
              saveFirst={resident.id === undefined}
              PhotoButtonComponent={UAPhotoComponent}
              uaSaveResident={saveResident}
              uaUpdateResident={updateResident}
            />
            {resident.lastUaDate && (
              <Typography variant="body2">
                Last UA: {format(convertUTCToLocal(resident.lastUaDate), "P")}
              </Typography>
            )}
          </Grid>
          <Grid item container>
            <Toggle
              label="Is GEO"
              name="isGeo"
              value={resident.isGeo}
              onChange={handleChange("isGeo")}
              disabled={disabled("isGeo")}
            />
          </Grid>
          <Grid item container>
            <CustomPrice
              label="Collect"
              size="medium"
              onChange={handleCollectChange}
              value={resident.totalCollected}
              disabled={disabled("totalCollected")}
              fullWidth
            />
          </Grid>
          <Grid item container>
            <DatePicker
              label="Move In Date"
              inputFormat="MM/DD/YYYY"
              value={
                resident.moveInDate
                  ? convertUTCToLocal(resident.moveInDate)
                  : null
              }
              onChange={handleDateChange<Resident>(
                "moveInDate",
                resident,
                setResident,
                Resident,
                setResidentCallback
              )}
              disabled={disabled("moveInDate")}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </Grid>
          <Grid item container>
            <Toggle
              label="Will Contact To Schedule"
              name="contactToSchedule"
              value={resident.contactToSchedule}
              onChange={handleChange("contactToSchedule")}
              disabled={disabled("contactToSchedule")}
            />
          </Grid>
          <Grid item container>
            <DatePicker
              label="Rent Due Date"
              inputFormat="MM/DD/YYYY"
              value={
                resident.rentDueDate
                  ? convertUTCToLocal(resident.rentDueDate)
                  : null
              }
              onChange={handleDateChange<Resident>(
                "rentDueDate",
                resident,
                setResident,
                Resident,
                setResidentCallback,
                true
              )}
              disabled={disabled("rentDueDate")}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </Grid>
          <Grid item container>
            <PayScheduleItem
              paySchedule1={resident.paySchedule1}
              paySchedule2={resident.paySchedule2}
              onChangePaySchedule1={handleSelectChange("paySchedule1")}
              onChangePaySchedule2={handleSelectChange("paySchedule2")}
              disabled={disabled("paySchedule1")}
            />
          </Grid>
          <Grid item container>
            <Toggle
              label="No Payment Contract (NOPC)"
              name="noPaymentContract"
              value={resident.noPaymentContract}
              onChange={handleChange("noPaymentContract")}
              disabled={disabled("noPaymentContract")}
            />
          </Grid>
          <Grid item container>
            <Toggle
              label="Already Been Switched (ABS)"
              name="alreadyBeenSwitched"
              value={resident.alreadyBeenSwitched}
              onChange={handleChange("alreadyBeenSwitched")}
              disabled={disabled("alreadyBeenSwitched")}
            />
          </Grid>
          <Grid item container>
            <Toggle
              label="Cannot Pay Late (CPL)"
              name="cannotPayLate"
              value={resident.cannotPayLate}
              onChange={handleChange("cannotPayLate")}
              disabled={disabled("cannotPayLate")}
            />
          </Grid>
          <Grid item container>
            <Toggle
              label="Improper Payment (IP)"
              name="improperPayment"
              value={resident.improperPayment}
              onChange={handleChange("improperPayment")}
              disabled={disabled("improperPayment")}
            />
          </Grid>
          <Grid item container>
            <DatePicker
              label="Move Out Date"
              inputFormat="MM/DD/YYYY"
              value={
                resident.moveOutDate
                  ? convertUTCToLocal(resident.moveOutDate)
                  : null
              }
              onChange={handleDateChange<Resident>(
                "moveOutDate",
                resident,
                setResident,
                Resident,
                setResidentCallback
              )}
              renderInput={(params) => <TextField {...params} fullWidth />}
              disabled={disabled("moveOutDate")}
            />
          </Grid>
          <Grid item container>
            <Typography variant="h6">Emergency Information</Typography>
          </Grid>
          <Grid item container>
            <DatePicker
              label="Date of Birth"
              inputFormat="MM/DD/YYYY"
              value={
                resident.birthdate
                  ? convertUTCToLocal(resident.birthdate)
                  : null
              }
              onChange={handleDateChange<Resident>(
                "birthdate",
                resident,
                setResident,
                Resident,
                setResidentCallback
              )}
              renderInput={(params) => <TextField {...params} fullWidth />}
              disabled={disabled("birthdate")}
            />
          </Grid>
          <Grid item container>
            <TextField
              label="Drug of Choice"
              name="drugOfChoice"
              value={resident.drugOfChoice}
              onChange={handleChange("drugOfChoice")}
              disabled={disabled("drugOfChoice")}
              fullWidth
            />
          </Grid>
          <Grid item container>
            <TextField
              label="Emergency Contact Name and Number"
              name="emergencyContact"
              value={resident.emergencyContact}
              onChange={handleChange("emergencyContact")}
              disabled={disabled("emergencyContact")}
              fullWidth
              multiline
              minRows={3}
            />
          </Grid>
          <Grid item container>
            <TextField
              label="Apartment Number"
              name="apartmentNumber"
              value={resident.apartmentNumber}
              onChange={handleChange("apartmentNumber")}
              disabled={disabled("apartmentNumber")}
              fullWidth
            />
          </Grid>
          <Grid item container>
            <Toggle
              label="Is Employed"
              name="isEmployed"
              value={resident.isEmployed}
              onChange={handleChange("isEmployed")}
              disabled={disabled("isEmployed")}
            />
          </Grid>
          <Grid item container>
            <Toggle
              label="Is In UA Program"
              name="isInUaProgram"
              value={resident.isInUaProgram}
              onChange={handleChange("isInUaProgram")}
              disabled={disabled("isInUaProgram")}
            />
          </Grid>
          <Grid item container>
            <Typography variant="h6">General Photos</Typography>
            <Photos<Photo>
              photos={photos}
              updatePhoto={updatePhoto}
              addPhoto={addPhoto}
              removePhoto={removePhoto}
              editable={
                !forceDisable &&
                !![REQUEST_MOVE_IN, MOVED_IN, VIEW].find((d) => d === status) &&
                !!resident.id
              }
              saveFirst={resident.id === undefined}
              PhotoButtonComponent={PhotoButton<Photo>}
            />
          </Grid>
          <Grid item container>
            <Typography variant="h6">Files</Typography>
            <Files
              files={files}
              addFile={addFile}
              removeFile={removeFile}
              editable={!forceDisable && !!resident.id}
              saveFirst={resident.id === undefined}
            />
          </Grid>

          <ResidentComments residentId={resident.id} disabled={forceDisable} />
          <ResidentReports residentId={resident.id} disabled={forceDisable} />
        </Grid>
      </form>
    </>
  );
}
