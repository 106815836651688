import DatabaseObject, {
  DatabaseObjectConstructorType,
} from "./database-object";
import { Location } from "./location";
import { Photo } from "./photo";
import { District } from "./district";

export const PENDING = "PENDING";
export const REQUEST_MOVE_IN = "REQUEST_MOVE_IN";
export const MOVED_IN = "MOVED_IN";
export const MOVED_OUT = "MOVED_OUT";
export const VIEW = "VIEW";
export const LOCATION_CHANGE = "LOCATION_CHANGE";

export const RESIDENT_STATUS_OPTIONS = [
  { text: "Pending", value: PENDING },
  { text: "Request Move In", value: REQUEST_MOVE_IN },
  { text: "Moved In", value: MOVED_IN },
  { text: "Moved Out", value: MOVED_OUT },
];

export interface ResidentConstructorType extends DatabaseObjectConstructorType {
  firstName?: string;
  lastName?: string;
  status?: string;
  supervision?: string;
  contactNumber?: string;
  location?: Location;
  locationId?: number;
  district?: District;
  districtId?: number;
  isGeo?: boolean;
  totalCollected?: number;
  moveInDate?: Date | string;
  contactToSchedule?: boolean;
  moveOutDate?: Date | string;
  needsUa?: boolean;
  uaPhotos?: Photo[];
  rentDueDate?: Date | string;
  paySchedule1?: string;
  paySchedule2?: string;
  paymentContract?: string;
  noPaymentContract?: boolean;
  alreadyBeenSwitched?: boolean;
  cannotPayLate?: boolean;
  improperPayment?: boolean;
  birthdate?: Date | string;
  drugOfChoice?: string;
  emergencyContact?: string;
  apartmentNumber?: string;
  isEmployed?: boolean;
  isInUaProgram?: boolean;

  overallBalance?: number;
  lastUaDate?: Date | string;
}

export class Resident extends DatabaseObject {
  firstName?: string = "";
  lastName?: string = "";
  status?: string = "";
  supervision?: string = "";
  contactNumber?: string = "";
  location?: Location;
  locationId?: number;
  district?: District;
  districtId?: number;
  isGeo = false;
  totalCollected = 0;
  moveInDate?: Date;
  contactToSchedule = false;
  moveOutDate?: Date;
  needsUa = false;
  uaPhotos?: Photo[];
  rentDueDate?: Date;
  paySchedule1?: string = "";
  paySchedule2?: string = "";
  paymentContract?: string = "";
  noPaymentContract = false;
  alreadyBeenSwitched = false;
  cannotPayLate = false;
  improperPayment = false;
  birthdate?: Date;
  drugOfChoice?: string = "";
  emergencyContact?: string = "";
  apartmentNumber?: string = "";
  isEmployed = false;
  isInUaProgram = false;

  overallBalance = 0;
  lastUaDate?: Date;

  constructor(data?: ResidentConstructorType) {
    super(data);

    if (!data) return;

    if (data.firstName) this.firstName = data.firstName;
    if (data.lastName) this.lastName = data.lastName;
    if (data.status) this.status = data.status;
    if (data.supervision) this.supervision = data.supervision;
    if (data.contactNumber) this.contactNumber = data.contactNumber;
    if (data.location) this.location = new Location(data.location);
    if (data.locationId) this.locationId = data.locationId;
    if (data.district) this.district = new District(data.district);
    if (data.districtId) this.districtId = data.districtId;
    if (data.isGeo) this.isGeo = data.isGeo;
    if (data.totalCollected) this.totalCollected = data.totalCollected;
    if (data.moveInDate) this.moveInDate = new Date(data.moveInDate);
    if (data.contactToSchedule) this.contactToSchedule = data.contactToSchedule;
    if (data.moveOutDate) this.moveOutDate = new Date(data.moveOutDate);
    if (data.needsUa) this.needsUa = data.needsUa;
    if (data.uaPhotos) this.uaPhotos = data.uaPhotos;
    if (data.rentDueDate) this.rentDueDate = new Date(data.rentDueDate);
    if (data.paySchedule1) this.paySchedule1 = data.paySchedule1;
    if (data.paySchedule2) this.paySchedule2 = data.paySchedule2;
    if (data.paymentContract) this.paymentContract = data.paymentContract;
    if (data.noPaymentContract) this.noPaymentContract = data.noPaymentContract;
    if (data.alreadyBeenSwitched)
      this.alreadyBeenSwitched = data.alreadyBeenSwitched;
    if (data.cannotPayLate) this.cannotPayLate = data.cannotPayLate;
    if (data.improperPayment) this.improperPayment = data.improperPayment;
    if (data.birthdate) this.birthdate = new Date(data.birthdate);
    if (data.drugOfChoice) this.drugOfChoice = data.drugOfChoice;
    if (data.emergencyContact) this.emergencyContact = data.emergencyContact;
    if (data.apartmentNumber) this.apartmentNumber = data.apartmentNumber;
    if (data.isEmployed) this.isEmployed = data.isEmployed;
    if (data.isInUaProgram) this.isInUaProgram = data.isInUaProgram;

    if (data.overallBalance) this.overallBalance = data.overallBalance;
    if (data.lastUaDate) this.lastUaDate = new Date(data.lastUaDate);
  }

  prepareForApi() {
    const obj = super.prepareForApi() as Partial<Resident>;

    return obj;
  }
}
