import { useCallback } from "react";
import {
  QueryParams,
  useApi,
  GetListInputType,
  GetListOutputType,
} from "../hooks";
import { District, DistrictConstructorType } from "../classes";

export function useDistrictsApi() {
  const { fetchGETList, fetchGET, fetchPOST, fetchPUT, fetchDELETE } = useApi();

  const getDistrict = useCallback(
    async (id: number) => {
      const d = await fetchGET(`districts/${id}`);
      return new District(d);
    },
    [fetchGET]
  );

  const getDistricts = useCallback(
    async (queryParams?: QueryParams): Promise<GetDistrictsOutputType> => {
      const ds = await fetchGETList("districts", queryParams);
      ds.items = ds.items.map((c: DistrictConstructorType) => new District(c));
      return ds;
    },
    [fetchGETList]
  );

  const addDistrict = useCallback(
    async (data: object) => {
      const d = await fetchPOST("districts", data);
      return new District(d);
    },
    [fetchPOST]
  );

  const updateDistrict = useCallback(
    async (id: number, data: object) => {
      const d = await fetchPUT(`districts/${id}`, data);
      return new District(d);
    },
    [fetchPUT]
  );

  const deleteDistrict = useCallback(
    (id: number) => fetchDELETE(`districts/${id}`),
    [fetchDELETE]
  );

  return {
    getDistrict,
    getDistricts,
    addDistrict,
    updateDistrict,
    deleteDistrict,
  };
}

export interface GetDistrictsInputType extends GetListInputType {
  search?: string;
  ids?: number | number[];
}

export type GetDistrictsOutputType = GetListOutputType<District>;
