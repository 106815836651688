const handleShare = (url: string) => async () => {
  const fileSuffix = url.split(".").pop()?.toLowerCase();
  const fileType = `image/${fileSuffix}`;
  const fileName = `Photo.${fileSuffix}`;

  if (window.ReactNativeWebView) {
    const message = JSON.stringify({
      action: "share",
      url,
      dialogTitle: "Share Photo",
      UTI: `public.${fileSuffix}`,
      mimeType: fileType,
      fileName,
    });

    window.ReactNativeWebView.postMessage(message);
    return;
  }

  if (navigator.canShare?.()) {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const file = new File([blob], fileName, { type: fileType });

      navigator.share({
        title: "Photo",
        files: [file],
      });
    } catch (error) {
      alert(error);
    }

    return;
  }

  // open new tab with image
  window.open(url, "_blank");
};

export default handleShare;
