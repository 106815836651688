import { useCallback } from "react";
import {
  QueryParams,
  useApi,
  GetListInputType,
  GetListOutputType,
} from "../hooks";
import { Location } from "../classes/location";

export function useLocationsApi() {
  const {
    fetchGETList,
    fetchGET,
    fetchPOST,
    fetchPOSTMultiple,
    fetchPUT,
    fetchDELETE,
  } = useApi();

  const getLocation = useCallback(
    (id: number) => fetchGET(`locations/${id}`),
    [fetchGET]
  );

  const getLocations = useCallback(
    (queryParams?: QueryParams): Promise<GetLocationsOutputType> =>
      fetchGETList("locations", queryParams),
    [fetchGETList]
  );

  const addLocation = useCallback(
    (body: object) => fetchPOST("locations", body),
    [fetchPOST]
  );

  const updateLocation = useCallback(
    (id: number, body: object) => fetchPUT(`locations/${id}`, body),
    [fetchPUT]
  );

  const deleteLocation = useCallback(
    (id: number) => fetchDELETE(`locations/${id}`),
    [fetchDELETE]
  );

  const getLocationPhotos = useCallback(
    (id: number) => fetchGET(`locations/${id}/photos`),
    [fetchGET]
  );

  const addLocationPhoto = useCallback(
    (id: number, body: object) =>
      fetchPOSTMultiple(`locations/${id}/photos`, body),
    [fetchPOSTMultiple]
  );

  return {
    getLocation,
    getLocations,
    addLocation,
    updateLocation,
    deleteLocation,
    getLocationPhotos,
    addLocationPhoto,
  };
}

export interface GetLocationsInputType extends GetListInputType {
  search?: string;
  ids?: number | number[];
}

export interface GetLocationsOutputType extends GetListOutputType<Location> {
  totalAvailableBeds: number;
}
