import React from "react";
import "./App.css";
import Routes from "./routes";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import CssBaseline from "@mui/material/CssBaseline";

export default function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <CssBaseline />
      <Routes />
    </LocalizationProvider>
  );
}
