import React from "react";
import {
  Table,
  TableRow,
  TableBody,
  TableHeader,
  TableSelectedSectionButton,
  TableCell,
  Typography,
  LocationFilter,
  TableFilterModal,
  RowType,
  WorkOrderStatusesFilter,
  WorkOrderPrioritiesFilter,
  WorkOrderCategoriesFilter,
  TableSearchField,
} from "./index";

import format from "date-fns/format";
import {
  colors,
  headerLinkHeight,
  tabHeight,
  bottomNavigationHeight,
} from "../constants";
import { convertUTCToLocal } from "../utils/date-helpers";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { formatMoney } from "../utils";

const Header = () => (
  <>
    <TableCell basis="60px">ID</TableCell>
    <TableCell basis="120px">Location</TableCell>
    <TableCell basis="100px">Priority</TableCell>
    <TableCell basis="110px">Status</TableCell>
    <TableCell basis="126px">Category</TableCell>
    <TableCell basis="83px">Cost</TableCell>
    <TableCell basis="60px">Sched</TableCell>
  </>
);

const EmptyView = () => (
  <Typography type="h4" align="center" spacing="mt-8" color={colors.grey[5]}>
    No Work Orders
  </Typography>
);

interface RowProps {
  row: RowType;
  selected: boolean;
  onClick: () => void;
  tableBodyRef: React.RefObject<HTMLDivElement>;
}
const Row = ({ row, ...props }: RowProps) => {
  return (
    <TableRow row={row} SelectedSectionElem={SelectedSection} {...props}>
      <TableCell basis="60px">{row.workOrder?.id}</TableCell>
      <TableCell basis="120px" color={colors.grey[3]}>
        {row.workOrder?.locations?.map((location) => location.name).join(", ")}
      </TableCell>
      <TableCell basis="100px" color={colors.grey[3]}>
        {row.workOrder?.priority
          .split("_")
          .map((s) => s[0].toUpperCase() + s.slice(1).toLowerCase())
          .join(" ")}
      </TableCell>
      <TableCell basis="110px" color={colors.grey[3]} ellipsis>
        {row.workOrder?.status
          .split("_")
          .map((s) => s[0].toUpperCase() + s.slice(1).toLowerCase())
          .join(" ")}
      </TableCell>
      <TableCell basis="126px" color={colors.grey[3]} ellipsis>
        {row.workOrder?.category
          .split("_")
          .map((s) => s[0].toUpperCase() + s.slice(1).toLowerCase())
          .join(" ")}
      </TableCell>
      <TableCell basis="83px" color={colors.grey[3]}>
        {row.workOrder?.cost && formatMoney(row.workOrder?.cost)}
      </TableCell>
      <TableCell basis="60px" color={colors.grey[3]}>
        {row.workOrder?.scheduledDate &&
          format(convertUTCToLocal(row.workOrder.scheduledDate), "M/dd")}
      </TableCell>
    </TableRow>
  );
};

interface SelectedSectionProps {
  row: RowType;
  onView: (row: RowType) => void;
}
const SelectedSection = ({ row, onView }: SelectedSectionProps) => {
  const handleView = () => onView(row);

  return (
    <TableSelectedSectionButton icon={faEye} onClick={handleView}>
      View
    </TableSelectedSectionButton>
  );
};

interface WorkOrderTableProps {
  rows: RowType[];
  total: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  onView: (record: RowType) => void;
}

const WorkOrderTable = ({
  rows,
  total,
  search,
  setSearch,
  setPage,
  onView,
}: WorkOrderTableProps) => {
  const filterContent = [
    LocationFilter,
    WorkOrderPrioritiesFilter,
    WorkOrderStatusesFilter,
    WorkOrderCategoriesFilter,
  ];

  return (
    <Table
      style={{
        height: `calc(100vh - ${tabHeight} - ${headerLinkHeight} - ${bottomNavigationHeight})`,
      }}
    >
      <TableFilterModal
        filterContent={filterContent}
        callback={() => setPage(0)}
      />
      <TableSearchField
        value={search}
        onChange={(e) => {
          setPage(0);
          // If user selects from the existing search list,
          // value will be empty and textContent will have the search string
          e.target.value
            ? setSearch(e.target.value)
            : setSearch(e.target.textContent ?? "");
        }}
        filterContent={filterContent}
      />
      <TableHeader>
        <Header />
      </TableHeader>
      <TableBody
        rows={rows}
        total={total}
        RowElem={Row}
        EmptyViewElem={EmptyView}
        onLoadMore={() => setPage((page) => page + 1)}
        onView={onView}
      />
    </Table>
  );
};

export default WorkOrderTable;
