import React, { useState } from "react";
import {
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  CircularProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import DownloadIcon from "@mui/icons-material/Download";
import format from "date-fns/format";
import handleShare from "../utils/share-image";
import { ResidentFile } from "../classes/resident-file";
import { PhotoEmptyButton } from "./Photos";
import { useGlobalLoading } from "./GlobalLoading";
import { useMe } from "../store";

export interface FileButtonProps {
  file: ResidentFile;
  removeFile: (file: ResidentFile) => Promise<void>;
}

const FileButton = ({ file, removeFile }: FileButtonProps) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen(!open);

  return (
    <>
      <Button
        variant="text"
        color="primary"
        onClick={handleClick}
        sx={{
          width: "100%",
        }}
      >
        <Typography
          sx={{
            width: "100%",
            maxWidth: "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            textAlign: "left",
          }}
        >
          {file.fileName}
        </Typography>
      </Button>

      <FileViewer
        open={open}
        file={file}
        timestamp={file.createdAt}
        onClose={handleClick}
        removeFile={removeFile}
      />
    </>
  );
};

export interface FileViewerProps<T> {
  open: boolean;
  onClose: () => void;
  file: T;
  removeFile: (file: T) => Promise<void>;
  timestamp?: string;
}

export function FileViewer<
  T extends { url?: string; fileName?: string; fileType?: string }
>({ open, onClose, file, removeFile, timestamp }: FileViewerProps<T>) {
  const { setGlobalLoading } = useGlobalLoading();
  const [deleting, setDeleting] = useState(false);

  const { isAdmin } = useMe();

  const handleRemove = async () => {
    setDeleting(true);

    if (confirm("Are you sure you want to delete this photo?")) {
      setGlobalLoading("Deleting");
      await removeFile(file);
      setGlobalLoading(false);
    }

    setDeleting(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Typography
          sx={{
            marginBottom: 4,
            width: "100%",
            maxWidth: "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            textAlign: "left",
          }}
        >
          {file.fileName}
        </Typography>

        {file.fileType === "application/pdf" &&
          // make sure the url is external
          file.url?.includes("https://") && (
            <iframe
              style={{
                width: "100%",
                height: 300,
              }}
              src={`https://docs.google.com/gview?url=${file.url}&embedded=true`}
              title="PDF Viewer"
            />
          )}

        {file.fileType === "application/pdf" &&
          // make sure the url is external
          !file.url?.includes("https://") && (
            <>
              <Typography>PDF Viewer not available.</Typography>
              <Typography>You must save the resident first.</Typography>
            </>
          )}

        <Grid
          container
          flexDirection="column"
          spacing={1}
          style={{
            marginTop: 16,
          }}
        >
          {timestamp && (
            <Grid item>
              <Typography variant="body2">
                Created: {format(new Date(timestamp), "PPp")}
              </Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between" alignItems="center">
          <IconButton onClick={handleShare(file.url as string)}>
            <DownloadIcon />
          </IconButton>
          {isAdmin && (
            <Button
              color="error"
              variant="text"
              onClick={handleRemove}
              startIcon={deleting ? null : <DeleteIcon />}
              disabled={deleting}
            >
              {deleting ? <CircularProgress size={16} /> : "Delete"}
            </Button>
          )}
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

interface FileCreateButtonProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
}

const FileCreateButton = ({ onChange }: FileCreateButtonProps) => {
  const { setGlobalLoading } = useGlobalLoading();
  const [isUploading, setIsUploading] = useState(false);

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsUploading(true);
    setGlobalLoading("Uploading");
    await onChange(e);
    setGlobalLoading(false);
    setIsUploading(false);
  };

  return (
    <Grid item>
      <Button
        variant="contained"
        color="primary"
        aria-label="upload file"
        component="label"
        disabled={isUploading}
      >
        <input hidden type="file" onChange={handleUpload} accept="*" />
        {!isUploading && <CreateNewFolderIcon />}
        {isUploading && <CircularProgress size={16} />}
        <Typography sx={{ marginLeft: 1 }}>Add File</Typography>
      </Button>
    </Grid>
  );
};

interface FilesProps {
  files: ResidentFile[];

  addFile: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  removeFile: (file: ResidentFile) => Promise<void>;

  editable?: boolean;
  saveFirst?: boolean;
}

function Files({
  files,

  addFile,
  removeFile,

  editable,
  saveFirst,
}: FilesProps) {
  const fileButtons = files.map((file) => (
    <Grid item key={file.id} sx={{ width: "100%" }}>
      <FileButton file={file} removeFile={removeFile} />
    </Grid>
  ));

  return (
    <Grid container direction="column" spacing={1} alignItems="flex-start">
      {fileButtons}
      {editable && <FileCreateButton onChange={addFile} />}
      {files.length === 0 && !editable && (
        <Grid item>
          <PhotoEmptyButton />
        </Grid>
      )}
      {saveFirst && (
        <Grid item>
          <Typography variant="body2" color="error">
            Save first to add photos
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}

export default Files;
