import DatabaseObject, {
  DatabaseObjectConstructorType,
} from "./database-object";
import { Resident } from "./resident";

export interface PhotoConstructorType extends DatabaseObjectConstructorType {
  s3Key?: string;
  url?: string;
  caption?: string;
  resident?: Resident;
  residentId?: number;
  file?: File;
}

export class Photo extends DatabaseObject {
  s3Key?: string;
  url?: string;
  caption = "";
  resident?: Resident;
  residentId?: number;

  file?: File;

  constructor(data?: PhotoConstructorType) {
    super(data);

    if (!data) return;

    if (data.s3Key) this.s3Key = data.s3Key;
    if (data.url) this.url = data.url;
    if (data.caption) this.caption = data.caption;
    if (data.resident) this.resident = new Resident(data.resident);
    if (data.residentId) this.residentId = data.residentId;
    if (data.file) this.file = data.file;
  }
}
