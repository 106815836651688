import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import WorkOrders from "./WorkOrders";
import Contractors from "./Contractors";
import { MaintenanceTabs } from "../../../components";

export default function MaintenanceRoutes() {
  return (
    <>
      <MaintenanceTabs />
      <Routes>
        <Route path="/work-orders" element={<WorkOrders />} />
        <Route path="/contractors" element={<Contractors />} />
        <Route path="*" element={<Navigate to="work-orders" />} />
      </Routes>
    </>
  );
}
