import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import ActivityRoutes from "./Activity";
import CollectionRoutes from "./Collections";
import CurfewRoutes from "./Curfew";
import LocationRoutes from "./Locations";
import MaintenanceRoutes from "./Maintenance";
import ManagersRoutes from "./Managers";
import ResidentsRoutes from "./Residents";
import SendSmsRoutes from "./SendSms";

import SideMenuLayout from "../../components/SideMenuLayout";

import { useMe } from "../../store";
import { TableFilter } from "../../components/TableFilter";

export function DashboardRoutes() {
  const { isAdmin } = useMe();
  return (
    <TableFilter>
      <SideMenuLayout>
        <Routes>
          <Route path="/residents/*" element={<ResidentsRoutes />} />
          <Route path="/activity/*" element={<ActivityRoutes />} />
          <Route path="/locations/*" element={<LocationRoutes />} />

          {isAdmin && <Route path="/managers/*" element={<ManagersRoutes />} />}

          <Route path="/collections/*" element={<CollectionRoutes />} />

          <Route path="/maintenance/*" element={<MaintenanceRoutes />} />

          <Route path="/curfew/*" element={<CurfewRoutes />} />

          <Route path="/send-sms/*" element={<SendSmsRoutes />} />

          <Route path="*" element={<Navigate to="residents" />} />
        </Routes>
      </SideMenuLayout>
    </TableFilter>
  );
}
