import { Location } from "../classes/location";
// locations are of the form:
// * IF3
// * B22
// * B2
// * C1 Riverstone
//
// We should sort them like this:
// * B2
// * B22
// * C1 Riverstone
// * IF3
//
// The location names are broken into 3 pieces:
// * letters
// * numbers
// * suffix
//
// We sort by letters, then numbers, then suffix.

export function sortLocations(locations: Location[]): Location[] {
  return locations.sort((a, b) => {
    const aPieces = a.name.split(/(\d+)/);
    const bPieces = b.name.split(/(\d+)/);

    // compare letters
    const aLetters = aPieces[0];
    const bLetters = bPieces[0];
    if (aLetters < bLetters) {
      return -1;
    } else if (aLetters > bLetters) {
      return 1;
    }

    // compare numbers
    const aNumbers = parseInt(aPieces[1]);
    const bNumbers = parseInt(bPieces[1]);
    if (aNumbers < bNumbers) {
      return -1;
    } else if (aNumbers > bNumbers) {
      return 1;
    }

    // compare suffix
    const aSuffix = aPieces[2];
    const bSuffix = bPieces[2];
    if (aSuffix < bSuffix) {
      return -1;
    } else if (aSuffix > bSuffix) {
      return 1;
    }

    return 0;
  });
}
