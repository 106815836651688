import React, { useState, useRef, Dispatch, SetStateAction } from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { handleTextChange } from "../utils/handlers";
import { useCurfewsApi } from "../apis";
import { Curfew } from "../classes";

interface CurfewModalProps {
  active: boolean;
  closeModal: () => void;
  curfew: Curfew;
  setCurfew: Dispatch<SetStateAction<Curfew>>;
  onCallback: (item: Curfew) => void;
}

export default function CurfewModal({
  active,
  closeModal,
  curfew,
  setCurfew,
  onCallback,
}: CurfewModalProps) {
  const addCurfewEl = useRef<HTMLButtonElement>(null);
  const { addCurfew, updateCurfew, deleteCurfew } = useCurfewsApi();

  const [isDirty, setIsDirty] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = async () => {
    if (isDirty) {
      const confirm = window.confirm(
        "You have unsaved changes. Are you sure you want to leave?"
      );
      if (!confirm) return;
    }

    closeModal();
    setIsDirty(false);
    setIsSubmitting(false);
  };

  const handleSave: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (!isDirty) {
      onCallback(curfew);
      return;
    }

    setIsSubmitting(true);

    let newCurfew: Curfew;
    if (curfew.id === undefined) {
      // If the curfew has no ID and missing ETA and Destination, we can skip the API call.
      if (!curfew.eta && !curfew.destination) {
        setIsDirty(false);
        setIsSubmitting(false);
        onCallback(curfew);
        return;
      }

      newCurfew = await addCurfew(curfew.prepareForApi());
    } else if (!curfew.eta && !curfew.destination) {
      newCurfew = await deleteCurfew(curfew.id as number);
      // If the curfew was deleted, the newCurfew object will be the deleted curfew.
      // We need to reset the curfew object to a new instance of Curfew.
      newCurfew.id = undefined;
      newCurfew.eta = undefined;
      newCurfew.destination = undefined;
    } else {
      newCurfew = await updateCurfew(
        curfew.id as number,
        curfew.prepareForApi()
      );
    }

    setIsDirty(false);
    setIsSubmitting(false);

    onCallback(newCurfew);
  };

  return (
    <Dialog open={active} onClose={handleClose} fullWidth>
      <DialogTitle>
        {[curfew.resident?.lastName, curfew.resident?.firstName]
          .filter(Boolean)
          .join(", ")}
      </DialogTitle>
      <DialogContent dividers>
        <form onSubmit={handleSave}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Destination"
                name="destination"
                value={curfew.destination ?? ""}
                onChange={handleTextChange(
                  "destination",
                  curfew,
                  setCurfew,
                  Curfew,
                  () => setIsDirty(true)
                )}
                fullWidth
                disabled={isSubmitting}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="ETA"
                name="eta"
                value={curfew.eta ?? ""}
                onChange={handleTextChange(
                  "eta",
                  curfew,
                  setCurfew,
                  Curfew,
                  () => setIsDirty(true)
                )}
                fullWidth
                disabled={isSubmitting}
              />
            </Grid>
          </Grid>

          {/* Used as a "submit" proxy for the DialogAction buttons */}
          <button ref={addCurfewEl} type="submit" style={{ display: "none" }} />
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          variant="text"
          onClick={handleClose}
          disabled={isSubmitting}
        >
          Close
        </Button>
        <Button
          color="primary"
          variant="contained"
          endIcon={<ChevronRightIcon />}
          onClick={() => addCurfewEl.current?.click()}
          disabled={isSubmitting}
        >
          Next
        </Button>
      </DialogActions>
    </Dialog>
  );
}
