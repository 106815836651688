import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  CurfewModal,
  CurfewTable,
  DateNavigation,
  HeaderLink,
  RowType,
  useGlobalLoading,
  useTableFilter,
} from "../../../components";
import { Curfew } from "../../../classes";
import PrintIcon from "@mui/icons-material/Print";
import {
  useCurfewsApi,
  GetCurfewsInputType,
  GetCurfewsOutputType,
} from "../../../apis";
import format from "date-fns/format";
import getHours from "date-fns/getHours";
import startOfYesterday from "date-fns/startOfYesterday";
import isToday from "date-fns/isToday";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

export default function CurfewPage() {
  const { setGlobalLoading } = useGlobalLoading();
  const navigate = useNavigate();
  const { getCurfews } = useCurfewsApi();
  const { locations, districts, isGeo, hasNoRentDueDate, sortLastUaDate } =
    useTableFilter();
  const [searchParams, setSearchParams] = useSearchParams();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const [active, setActive] = useState(false);

  const [curfews, setCurfews] = useState<GetCurfewsOutputType>({
    items: [],
    total: 0,
  });

  const [selectedCurfew, setSelectedCurfew] = useState<Curfew>(
    new Curfew({
      residentId: 0,
      date: new Date(),
    })
  );

  const [page, setPage] = useState(0);
  const [take] = useState(100);

  // If user previously entered a search string, use that value for "search" variable below
  // When user navigates back to the activity page, their search string will be preserved in the UI
  const [search, setSearch] = useState(searchParams.get("search") ?? "");

  const [currentDate, setCurrentDate] = useState(() => {
    let date = new Date();
    // If it's before 7am, show yesterday's curfews
    if (getHours(new Date()) < 7) {
      date = startOfYesterday();
    }
    return date;
  });

  const fetchCurfewData = useCallback(async () => {
    const customQuery: GetCurfewsInputType = {
      skip: page * take,
      take,
      date: format(currentDate, "yyyy-MM-dd"),
    };

    if (locations.length > 0) {
      customQuery.locationIds = locations;
    }

    if (districts.length > 0) {
      customQuery.districtIds = districts;
    }

    if (isGeo) {
      customQuery.isGeo = isGeo;
    }

    if (hasNoRentDueDate) {
      customQuery.hasNoRentDueDate = hasNoRentDueDate;
    }

    if (search.trim()) {
      customQuery.search = search.trim();
      setSearchParams({ search: search.trim() });
    } else {
      setSearchParams({});
    }

    if (sortLastUaDate) {
      customQuery.lastUaDate__order = "asc";
    }

    setGlobalLoading(true);
    const _curfews = await getCurfews({ ...customQuery });
    setGlobalLoading(false);
    if (page === 0) {
      setCurfews(_curfews);
    } else {
      setCurfews({
        items: [...curfews.items, ..._curfews.items],
        total: _curfews.total,
      });
    }
  }, [
    page,
    take,
    locations,
    districts,
    isGeo,
    hasNoRentDueDate,
    sortLastUaDate,
    search,
    getCurfews,
    setCurfews,
    currentDate,
    setSearchParams,
  ]);

  useEffect(() => {
    fetchCurfewData();
  }, [fetchCurfewData]);

  const rows = curfews.items.map((activity) => ({
    curfew: new Curfew(activity),
  }));

  const handleView = async (record: RowType) => {
    setSelectedCurfew(record.curfew as Curfew);
    setActive(true);
  };

  const handlePrint = () => {
    const searchParams = new URLSearchParams();

    if (search) searchParams.append("search", search);
    if (currentDate)
      searchParams.append("date", format(currentDate, "yyyy-MM-dd"));
    if (isGeo) searchParams.append("isGeo", "true");
    if (hasNoRentDueDate) searchParams.append("hasNoRentDueDate", "true");
    if (sortLastUaDate) searchParams.append("sortLastUaDate", "true");
    if (locations.length > 0)
      searchParams.append("locations", locations.join(","));
    if (districts.length > 0)
      searchParams.append("districts", districts.join(","));

    console.log("searchParams", searchParams.toString());

    const url = `/print-curfew-report`;

    navigate(`${url}?${searchParams.toString()}`);
  };

  return (
    <>
      {matches && (
        <HeaderLink icon={<PrintIcon />} text="Print" onClick={handlePrint} />
      )}
      {/* <Grid */}
      {/*   container */}
      {/*   justifyContent="center" */}
      {/*   alignItems="center" */}
      {/*   sx={{ padding: 0.5 }} */}
      {/* > */}
      {/*   <Typography>{format(date, "PPP")}</Typography> */}
      {/* </Grid> */}
      <DateNavigation
        date={currentDate}
        onChange={(_currentDate: Date) => {
          setPage(0);
          setCurrentDate(_currentDate);
        }}
        rightDisabled={() => isToday(currentDate)}
      />
      <CurfewTable
        rows={rows}
        total={curfews.total}
        setPage={setPage}
        search={search}
        setSearch={setSearch}
        onRowSelect={handleView}
      />

      <CurfewModal
        active={active}
        closeModal={() => setActive(false)}
        curfew={selectedCurfew}
        setCurfew={setSelectedCurfew}
        onCallback={(curfew: Curfew) => {
          // Merge the selectedCurfew with the curfews list
          setCurfews({
            ...curfews,
            items: curfews.items.map((_curfew) =>
              _curfew.residentId === curfew.residentId ? curfew : _curfew
            ),
          });

          // Select next Curfew Item
          const index = curfews.items.findIndex(
            (_curfew) => _curfew.residentId === curfew.residentId
          );

          const nextIndex = (index + 1) % curfews.items.length;

          setSelectedCurfew(new Curfew(curfews.items[nextIndex]));
        }}
      />
    </>
  );
}
