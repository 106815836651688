import React, { useState, useEffect, useRef, useCallback } from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  OutlinedInput,
  SelectChangeEvent,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";

import { WorkOrder, WORKORDER_CATEGORIES } from "../classes/work-order";
import {
  handleOnChange,
  handlePriceChange,
  handleTextChange,
  handleDateChange,
} from "../utils/handlers";
import {
  useContractorsApi,
  useLocationsApi,
  useUsersApi,
  useWorkOrdersApi,
} from "../apis";
import { sortLocations } from "../utils/sortLocations";
import { Select } from "./Select";
import CustomPrice from "./CustomPrice";
import { PhotoButton } from "./Photos";
import { DatePicker } from "@mui/x-date-pickers";
import { convertUTCToLocal } from "../utils/date-helpers";
import { useNavigate } from "react-router-dom";
import Photos from "./Photos";
import { WorkOrderPhoto } from "../classes";
import { useWorkOrderPhotos } from "../hooks/useWorkOrderPhotos";
import { useGlobalLoading } from "./GlobalLoading";
import { WorkOrderComments } from "./WorkOrderComments";
import { format } from "date-fns";

const priorityOptions = [
  { text: "Low (>30 days)", value: "LOW" },
  { text: "Medium (8-29 days)", value: "MEDIUM" },
  { text: "High (1-7 days)", value: "HIGH" },
  { text: "Emergency (immediate)", value: "EMERGENCY" },
];

const statusOptions = [
  { text: "Pending", value: "PENDING" },
  { text: "Active", value: "ACTIVE" },
  { text: "Completed", value: "COMPLETED" },
];

const categoryOptions = [
  { text: "Appliance", value: WORKORDER_CATEGORIES.APPLIANCE },
  { text: "Cabinetry", value: WORKORDER_CATEGORIES.CABINETRY },
  { text: "Cleaning", value: WORKORDER_CATEGORIES.CLEANING },
  { text: "Concrete", value: WORKORDER_CATEGORIES.CONCRETE },
  { text: "Doors", value: WORKORDER_CATEGORIES.DOORS },
  { text: "Electrical", value: WORKORDER_CATEGORIES.ELECTRICAL },
  { text: "Excavation", value: WORKORDER_CATEGORIES.EXCAVATION },
  { text: "Extermination", value: WORKORDER_CATEGORIES.EXTERMINATION },
  { text: "Finish Work", value: WORKORDER_CATEGORIES.FINISH_WORK },
  { text: "Flooring", value: WORKORDER_CATEGORIES.FLOORING },
  { text: "Framing", value: WORKORDER_CATEGORIES.FRAMING },
  { text: "Furnishing", value: WORKORDER_CATEGORIES.FURNISHING },
  { text: "HVAC", value: WORKORDER_CATEGORIES.HVAC },
  { text: "Landscaping", value: WORKORDER_CATEGORIES.LANDSCAPING },
  { text: "Media", value: WORKORDER_CATEGORIES.MEDIA },
  { text: "Painting", value: WORKORDER_CATEGORIES.PAINTING },
  { text: "Plumbing", value: WORKORDER_CATEGORIES.PLUMBING },
  { text: "Preventative", value: WORKORDER_CATEGORIES.PREVENTATIVE },
  { text: "Roofing", value: WORKORDER_CATEGORIES.ROOFING },
  { text: "Tile", value: WORKORDER_CATEGORIES.TILE },
  { text: "Turnover", value: WORKORDER_CATEGORIES.TURNOVER },
  { text: "Utilities", value: WORKORDER_CATEGORIES.UTILITIES },
  { text: "Windows", value: WORKORDER_CATEGORIES.WINDOWS },
];

const useLoadData = () => {
  const { getUsers } = useUsersApi();
  const { getLocations } = useLocationsApi();
  const { getContractors } = useContractorsApi();

  const [loadingUsers, setLoadingUsers] = useState(true);
  const [loadingLocations, setLoadingLocations] = useState(true);
  const [loadingContractors, setLoadingContractors] = useState(true);

  const [loadingAll, setLoadingAll] = useState(true);

  useEffect(() => {
    setLoadingAll(loadingUsers || loadingLocations || loadingContractors);
  }, [loadingUsers, loadingLocations, loadingContractors]);

  const [userOptions, setUsers] = useState<{ text: string; value: string }[]>(
    []
  );
  const [locationOptions, setLocations] = useState<
    { text: string; value: number }[]
  >([]);

  const [contractorOptions, setContractors] = useState<
    { text: string; value: string }[]
  >([]);

  const fetchUsers = useCallback(async () => {
    setLoadingUsers(true);
    const _users = await getUsers();
    const _userOptions = _users.items.map((u) => ({
      text: `${u.firstName} ${u.lastName}`,
      value: String(u.id),
    }));
    setUsers(_userOptions);
    setLoadingUsers(false);
  }, [getUsers]);

  const fetchLocations = useCallback(async () => {
    setLoadingLocations(true);
    const _locations = await getLocations();
    const sortedLocations = sortLocations(_locations.items);

    const _locationOptions = sortedLocations.map((l) => ({
      text: l.name,
      value: l.id as number,
    }));
    setLocations(_locationOptions);
    setLoadingLocations(false);
  }, [getLocations]);

  const fetchContractors = useCallback(async () => {
    setLoadingContractors(true);
    const _contractors = await getContractors();
    const _contractorOptions = _contractors.items.map((c) => ({
      text: c.companyName,
      value: String(c.id),
    }));

    _contractorOptions.push({
      text: "+ Add New Contractor",
      value: "ADD_NEW_CONTRACTOR",
    });

    setContractors(_contractorOptions);
    setLoadingContractors(false);
  }, [getContractors]);

  useEffect(() => {
    fetchUsers();
    fetchLocations();
    fetchContractors();
  }, [fetchUsers, fetchLocations, fetchContractors]);

  return {
    loading: loadingAll,
    userOptions,
    locationOptions,
    contractorOptions,
  };
};

interface WorkOrderModalProps {
  active: boolean;
  closeModal: () => void;
  workOrder: WorkOrder;
  onCallback: () => Promise<void>;
}

export default function WorkOrderModal({
  active,
  closeModal,
  workOrder: _workOrder,
  onCallback,
}: WorkOrderModalProps) {
  const Navigate = useNavigate();
  const { addWorkOrder, updateWorkOrder, deleteWorkOrder } = useWorkOrdersApi();
  const [isDirty, setIsDirty] = useState(false);
  const { setGlobalLoading } = useGlobalLoading();

  const handleClose = async () => {
    if (isDirty) {
      const confirm = window.confirm(
        "You have unsaved changes. Are you sure you want to leave?"
      );
      if (!confirm) return;
    }

    closeModal();
    setIsDirty(false);
  };

  const {
    loading: loadingPhotos,
    photos,

    addPhoto,
    removePhoto,
    updatePhoto,
  } = useWorkOrderPhotos({
    workOrderId: _workOrder.id,
  });

  const [workOrder, setWorkOrder] = useState<WorkOrder>(_workOrder);
  const [submitting, setSubmitting] = useState(false);

  const { loading, userOptions, locationOptions, contractorOptions } =
    useLoadData();

  const addWorkOrderEl = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    setWorkOrder(_workOrder);
  }, [_workOrder]);

  const handleSave: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (submitting) return;
    setSubmitting(true);

    if (workOrder.isPersisted()) {
      await updateWorkOrder(workOrder.id as number, workOrder.prepareForApi());
    } else {
      await addWorkOrder(workOrder.prepareForApi());
    }

    setSubmitting(false);
    setIsDirty(false);

    closeModal();

    await onCallback();
  };

  const handleDelete = async () => {
    if (!workOrder.id) return;

    if (window.confirm("Are you sure you want to delete this?")) {
      await deleteWorkOrder(workOrder.id);

      handleClose();
    }
  };

  const handleWorkOrderChange = (e: SelectChangeEvent<number[]>) => {
    const newLocation = new WorkOrder({
      ...workOrder,
      [e.target.name]: (e.target.value as number[]).map((id: number) => ({
        id,
      })),
    });

    setWorkOrder(newLocation);
    setIsDirty(true);
  };

  useEffect(() => {
    setGlobalLoading(loading || loadingPhotos);
  }, [loading, loadingPhotos]);

  return (
    <Dialog open={active} onClose={handleClose} fullWidth>
      <DialogTitle>Work Order</DialogTitle>
      <DialogContent dividers>
        <form onSubmit={handleSave}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Description"
                name="description"
                value={workOrder.description ?? ""}
                onChange={handleTextChange(
                  "description",
                  workOrder,
                  setWorkOrder,
                  WorkOrder,
                  () => setIsDirty(true)
                )}
                multiline
                minRows={3}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                label="Status"
                name="status"
                options={statusOptions}
                value={workOrder.status}
                onChange={handleOnChange(
                  "status",
                  workOrder,
                  setWorkOrder,
                  WorkOrder,
                  false,
                  () => setIsDirty(true)
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                label="Priority"
                name="priority"
                options={priorityOptions}
                value={workOrder.priority}
                onChange={handleOnChange(
                  "priority",
                  workOrder,
                  setWorkOrder,
                  WorkOrder,
                  false,
                  () => setIsDirty(true)
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                label="Category"
                name="category"
                options={categoryOptions}
                value={workOrder.category}
                onChange={handleOnChange(
                  "category",
                  workOrder,
                  setWorkOrder,
                  WorkOrder,
                  false,
                  () => setIsDirty(true)
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomPrice
                label="Cost"
                size="medium"
                onChange={handlePriceChange(
                  "cost",
                  workOrder,
                  setWorkOrder,
                  WorkOrder,
                  () => setIsDirty(true)
                )}
                value={workOrder.cost ?? 0}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                label="Scheduled Date"
                inputFormat="MM/DD/YYYY"
                value={
                  workOrder.scheduledDate
                    ? convertUTCToLocal(workOrder.scheduledDate)
                    : null
                }
                onChange={handleDateChange<WorkOrder>(
                  "scheduledDate",
                  workOrder,
                  setWorkOrder,
                  WorkOrder,
                  () => setIsDirty(true)
                )}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="locations-label">Locations</InputLabel>
                <MuiSelect
                  labelId="locations-label"
                  name="locations"
                  variant="filled"
                  multiple
                  value={workOrder.locations.map((l) => l.id as number)}
                  input={<OutlinedInput label="Locations" />}
                  onChange={handleWorkOrderChange}
                >
                  {locationOptions.map(({ text, value }) => (
                    <MenuItem key={value} value={value}>
                      {text}
                    </MenuItem>
                  ))}
                </MuiSelect>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Select
                label="Contractor"
                name="contractorId"
                options={contractorOptions}
                value={String(workOrder.contractorId)}
                onChange={(e) => {
                  if (e.target.value === "ADD_NEW_CONTRACTOR") {
                    Navigate("/dashboard/maintenance/contractors");
                    return;
                  }

                  handleOnChange(
                    "contractorId",
                    workOrder,
                    setWorkOrder,
                    WorkOrder,
                    true,
                    () => setIsDirty(true)
                  )(e);
                }}
                addChooseOption
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                label="Creator"
                name="creatorId"
                options={userOptions}
                value={String(workOrder.creatorId)}
                onChange={handleOnChange(
                  "creatorId",
                  workOrder,
                  setWorkOrder,
                  WorkOrder,
                  true,
                  () => setIsDirty(true)
                )}
                addChooseOption
              />
            </Grid>
            <Grid item container>
              <Typography variant="h6">Photos</Typography>
              <Photos<WorkOrderPhoto>
                photos={photos}
                addPhoto={addPhoto}
                removePhoto={removePhoto}
                updatePhoto={updatePhoto}
                editable
                saveFirst={workOrder.id === undefined}
                PhotoButtonComponent={PhotoButton<WorkOrderPhoto>}
              />
            </Grid>

            <WorkOrderComments workOrderId={_workOrder.id} />

            {_workOrder.createdAt && (
              <Grid item>
                <Typography variant="caption">
                  Created: {format(new Date(_workOrder.createdAt), "PPp")}
                </Typography>
              </Grid>
            )}

            {workOrder.id && (
              <Grid item container justifyContent="center" xs={12}>
                <Button
                  color="error"
                  startIcon={<DeleteIcon />}
                  onClick={handleDelete}
                >
                  Delete
                </Button>
              </Grid>
            )}
          </Grid>

          {/* Used as a "submit" proxy for the DialogAction buttons */}
          <button
            ref={addWorkOrderEl}
            type="submit"
            style={{ display: "none" }}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          startIcon={<SaveIcon />}
          onClick={() => addWorkOrderEl.current?.click()}
          disabled={!isDirty || submitting}
        >
          {submitting ? "Saving..." : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
