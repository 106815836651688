import React, { useEffect, useState } from "react";
import {
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  TextField,
  CircularProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import format from "date-fns/format";
import { UaPhoto } from "../classes/ua-photo";
import Toggle from "./Toggle";
import { convertUTCToLocal } from "../utils/date-helpers";
import handleShare from "../utils/share-image";
import { useGlobalLoading } from "./GlobalLoading";
import { useMe } from "../store";

interface UAPhotoProps {
  photo: UaPhoto;
  updatePhoto: (photo: UaPhoto) => Promise<void>;
  removePhoto: (photo: UaPhoto) => Promise<void>;
  uaSaveResident?: () => Promise<void>;
  uaUpdateResident?: () => Promise<void>;
}

export const UAPhoto = ({
  photo,
  updatePhoto,
  removePhoto,
  uaSaveResident,
  uaUpdateResident,
}: UAPhotoProps) => {
  const { setGlobalLoading } = useGlobalLoading();
  const [open, setOpen] = useState(false);
  const handleClick = async () => {
    try {
      setGlobalLoading("Saving Resident");
      uaSaveResident && (await uaSaveResident());
    } catch (e) {
      alert("An error occurred while saving the resident. Please try again.");
    } finally {
      setGlobalLoading(false);
      setOpen(!open);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClick}
        sx={{
          height: 50,
          backgroundImage: `url(${photo.url})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          border: "3px solid",
          borderColor: photo.isPositive ? "error.main" : "success.main",
        }}
      />

      <UAPhotoViewer
        open={open}
        photo={photo}
        updatePhoto={updatePhoto}
        onClose={handleClick}
        removePhoto={removePhoto}
        uaUpdateResident={uaUpdateResident}
      />
    </>
  );
};

interface UAPhotoViewerProps {
  open: boolean;
  onClose: () => void;
  photo: UaPhoto;
  updatePhoto: (photo: UaPhoto) => Promise<void>;
  removePhoto: (photo: UaPhoto) => Promise<void>;
  timestamp?: string;
  uaUpdateResident?: () => Promise<void>;
}

const UAPhotoViewer = ({
  open,
  onClose: _onClose,
  photo: _photo,
  updatePhoto,
  removePhoto,
  uaUpdateResident,
}: UAPhotoViewerProps) => {
  const [photo, setPhoto] = useState(_photo);
  const [isDirty, setIsDirty] = useState(false);
  const { setGlobalLoading } = useGlobalLoading();
  const [deleting, setDeleting] = useState(false);

  const { isAdmin } = useMe();

  useEffect(() => {
    setPhoto(_photo);
    setIsDirty(false);
  }, [_photo]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const field = e.target.name;
    let _photo;
    switch (e.target.type) {
      case "checkbox":
        _photo = new UaPhoto({
          ...photo,
          [field]: e.target.checked,
        });
        break;
      default:
        _photo = new UaPhoto({
          ...photo,
          [field]: e.target.value,
        });
    }

    setPhoto(_photo);
    setIsDirty(true);
  };

  const onClose = async () => {
    if (isDirty) {
      setIsDirty(false);

      setGlobalLoading("Updating");
      await updatePhoto(photo);
      setGlobalLoading(false);
    }

    _onClose();
  };

  const handleRemove = async () => {
    setDeleting(true);

    if (confirm("Are you sure you want to delete this photo?")) {
      setGlobalLoading("Deleting");
      await removePhoto(photo);
      await uaUpdateResident?.();
      setGlobalLoading(false);
    }

    setDeleting(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent dividers>
        <img
          style={{
            width: "100%",
            maxHeight: 300,
            objectFit: "contain",
          }}
          src={photo.url}
          alt="ua"
        />
        <Grid
          container
          flexDirection="column"
          spacing={1}
          style={{
            marginTop: 16,
          }}
        >
          <Grid item>
            <Toggle
              label="Is Positive"
              name="isPositive"
              onChange={handleChange}
              value={photo.isPositive}
            />
          </Grid>

          <Grid item>
            <TextField
              label="Caption"
              name="caption"
              value={photo.caption}
              onChange={handleChange}
              multiline
              minRows={2}
              fullWidth
            />
          </Grid>

          {photo.date && (
            <Grid item>
              <Typography variant="body2">
                Created: {format(convertUTCToLocal(photo.date), "P")}
              </Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between" alignItems="center">
          <IconButton onClick={handleShare(photo.url as string)}>
            <DownloadIcon />
          </IconButton>
          {isAdmin && (
            <Button
              color="error"
              variant="text"
              onClick={handleRemove}
              startIcon={deleting ? null : <DeleteIcon />}
              disabled={deleting}
            >
              {deleting ? <CircularProgress size={16} /> : "Delete"}
            </Button>
          )}
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
