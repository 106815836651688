import { useCallback } from "react";
import { useApi } from "../hooks";

export function useWorkOrderPhotosApi() {
  const { fetchPUT, fetchDELETE } = useApi();

  const updateWorkOrderPhoto = useCallback(
    (id: number, body: object) => fetchPUT(`work-order-photos/${id}`, body),
    [fetchPUT]
  );

  const deleteWorkOrderPhoto = useCallback(
    (id: number) => fetchDELETE(`work-order-photos/${id}`),
    [fetchDELETE]
  );

  return {
    updateWorkOrderPhoto,
    deleteWorkOrderPhoto,
  };
}
