import { useCallback } from "react";
import { useApi } from "../hooks";

export function useUserPhotosApi() {
  const { fetchPUT, fetchDELETE } = useApi();

  const updateUserPhoto = useCallback(
    (id: number, body: object) => fetchPUT(`user-photos/${id}`, body),
    [fetchPUT]
  );

  const deleteUserPhoto = useCallback(
    (id: number) => fetchDELETE(`user-photos/${id}`),
    [fetchDELETE]
  );

  return {
    updateUserPhoto,
    deleteUserPhoto,
  };
}
