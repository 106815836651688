import React from "react";
import { Button, Paper } from "@mui/material";
import "./Login.css";
import Auth from "@aws-amplify/auth";

export function Login() {
  const login = () => Auth.federatedSignIn();

  return (
    <div className="login">
      <Paper
        elevation={10}
        sx={{
          width: 128,
          height: 128,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button onClick={login} variant="contained">
          Login
        </Button>
      </Paper>
    </div>
  );
}
