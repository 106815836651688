import React, { useCallback, useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Box,
  List,
  ListItemButton,
  Button,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import NotesIcon from "@mui/icons-material/Notes";
import { Location } from "../classes/location";
import {
  GetWorkOrdersInputType,
  GetWorkOrdersOutputType,
  useWorkOrdersApi,
} from "../apis/work-orders";
import { WorkOrder } from "../classes/work-order";
import formatMoney from "../utils/formatMoney";
import WorkOrderModal from "./WorkOrderModal";
import { useMe } from "../store";
import format from "date-fns/format";
import { colors } from "../constants";

const EmptyItem = () => (
  <Grid
    style={{ height: "3rem" }}
    container
    justifyContent="center"
    alignItems="center"
  >
    <Typography variant="h6" style={{ color: colors.grey[5] }}>
      No Work Orders
    </Typography>
  </Grid>
);

interface WorkOrderListProps {
  location: Location;
}

function WorkOrderList({ location }: WorkOrderListProps) {
  const { me } = useMe();
  const { getWorkOrders } = useWorkOrdersApi();

  const [workOrders, setWorkOrders] = useState<GetWorkOrdersOutputType>({
    items: [],
    total: 0,
  });

  const [active, setActive] = useState(false);
  const [workOrder, setWorkOrder] = useState<WorkOrder>(
    new WorkOrder({
      priority: "LOW",
      status: "PENDING",
      category: "PREVENTATIVE",
      locations: [],
    })
  );

  const fetchWorkOrders = useCallback(async () => {
    if (!location.id) return;

    const customQuery: GetWorkOrdersInputType = {
      locations: location.id,
    };
    const _workOrders = await getWorkOrders({ ...customQuery });
    setWorkOrders(_workOrders);
  }, [getWorkOrders]);

  useEffect(() => {
    fetchWorkOrders();
  }, [fetchWorkOrders]);

  const handleAdd = useCallback(() => {
    setActive(true);
    setWorkOrder(
      new WorkOrder({
        priority: "LOW",
        status: "PENDING",
        category: "PREVENTATIVE",
        locations: [location],
        creatorId: me?.id,
      })
    );
  }, [location.id]);

  return (
    <>
      <Box sx={{ mt: 4 }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h6">Maintenance</Typography>
          </Grid>

          <Grid item>
            {location.id && (
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddCircleIcon />}
                onClick={handleAdd}
              >
                Add
              </Button>
            )}

            {!location.id && (
              <Typography style={{ color: colors.grey[3] }}>
                Save Location first
              </Typography>
            )}
          </Grid>
        </Grid>

        {workOrders.items.length === 0 && <EmptyItem />}

        <List>
          {workOrders.items.map((workOrder) => (
            <ListItemButton
              key={workOrder.id}
              divider
              onClick={() => {
                setActive(true);
                setWorkOrder(workOrder);
              }}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item style={{ width: 43 }}>
                  <Typography>{workOrder.id}</Typography>
                </Grid>
                <Grid item style={{ width: 68 }}>
                  <Typography
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {workOrder.priority}
                  </Typography>
                </Grid>
                <Grid item style={{ width: 95 }}>
                  <Typography
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {workOrder.status}
                  </Typography>
                </Grid>
                <Grid item style={{ width: 82 }}>
                  <Typography
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {workOrder.category}
                  </Typography>
                </Grid>
                <Grid item style={{ width: 83 }}>
                  {workOrder.cost && (
                    <Typography>{formatMoney(workOrder.cost)}</Typography>
                  )}
                </Grid>
                <Grid item style={{ width: 60 }}>
                  <Typography>
                    {workOrder.scheduledDate &&
                      format(new Date(workOrder.scheduledDate), "M/dd")}
                  </Typography>
                </Grid>
                <Grid item style={{ width: 80 }}>
                  <Typography
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {workOrder.contractor?.companyName}
                  </Typography>
                </Grid>

                <Grid item style={{ width: 50 }}>
                  <Typography>{workOrder.creator?.firstName}</Typography>
                </Grid>

                <Grid item style={{ width: 42 }}>
                  {workOrder.description && <NotesIcon />}
                </Grid>
              </Grid>
            </ListItemButton>
          ))}
        </List>
      </Box>

      <WorkOrderModal
        active={active}
        closeModal={() => setActive(false)}
        workOrder={workOrder}
        onCallback={fetchWorkOrders}
      />
    </>
  );
}

export default WorkOrderList;
