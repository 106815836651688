import React, { useState, useCallback, useEffect } from "react";

import {
  Grid,
  List,
  ListItem,
  Divider,
  Typography,
  Button,
  ListItemButton,
} from "@mui/material";
import format from "date-fns/format";
import subHours from "date-fns/subHours";
import {
  GetActivitiesInputType,
  GetActivitiesOutputType,
  useActivitiesApi,
} from "../apis/activities";
import ActivityModal from "./ActivityModal";
import { Activity } from "../classes";

interface Props {
  residentId: number;
}

export default function ActivityList({ residentId }: Props) {
  const { getActivities } = useActivitiesApi();

  const [open, setOpen] = useState(false);
  const [activity, setActivity] = useState(new Activity());
  const [activities, setActivities] = useState<GetActivitiesOutputType>({
    items: [],
    total: 0,
  });

  const [page, setPage] = useState(0);
  const [take] = useState(20);

  const fetchActivities = useCallback(async () => {
    if (!residentId) return;

    const customQuery: GetActivitiesInputType = {
      residentId,
      skip: page * take,
      take,
    };

    const _activities = await getActivities({ ...customQuery });

    if (page === 0) {
      setActivities(_activities);
    } else {
      setActivities({
        items: [...activities.items, ..._activities.items],
        total: _activities.total,
      });
    }
  }, [getActivities, residentId, page, take]);

  const handleLoadMore = useCallback(() => {
    setPage(page + 1);
  }, [page]);

  useEffect(() => {
    fetchActivities();
  }, [fetchActivities]);

  const handleOpenModal = (activity: Activity) => {
    setActivity(activity);
    setOpen(true);
  };

  const hasNextPage = activities.items.length < activities.total;

  return (
    <Grid
      style={{
        marginTop: "1rem",
      }}
    >
      <Typography variant="h6" style={{ paddingLeft: "1rem" }}>
        Activity List
      </Typography>
      <List>
        {activities.items.map((activity) => (
          <React.Fragment key={activity.id}>
            <ListItemButton onClick={() => handleOpenModal(activity)}>
              <Grid container justifyContent="space-between" wrap="wrap">
                <Typography style={{ flexBasis: 150 }}>
                  {activity.type.replaceAll("_", " ").toUpperCase()}
                </Typography>
                <Typography style={{ flexBasis: 250 }}>
                  {activity?.type === "CURFEW" &&
                    activity.curfew &&
                    [activity.curfew.destination, activity.curfew.eta].join(
                      ", "
                    )}

                  {activity?.type === "RESIDENT_REPORT" &&
                    activity.residentReport?.report}

                  {activity?.type === "LOCATION_CHANGE" &&
                    activity.location?.name}

                  {activity?.type === "MOVED_OUT" && activity.location?.name}
                </Typography>
                {activity.createdAt && (
                  <Typography style={{ flexBasis: 200 }}>
                    {/* // Instead of the literal Activity Time, we only display the date */}
                    {/* // and the Activity timeframe is from 7am to 7am. */}
                    {/* // So we subtract 7 hours from the Activity Time to get the correct date. */}
                    {/* // */}
                    {/* // e.g. a 3am Activity Time will be displayed as the previous day's date. */}
                    {format(subHours(new Date(activity.createdAt), 7), "P")}
                  </Typography>
                )}
              </Grid>
            </ListItemButton>
            <Divider />
          </React.Fragment>
        ))}
      </List>

      {hasNextPage && (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{
            height: "60px",
          }}
        >
          <Button variant="outlined" color="primary" onClick={handleLoadMore}>
            Load More
          </Button>
        </Grid>
      )}

      <ActivityModal
        active={open}
        closeModal={() => setOpen(false)}
        activity={activity}
      />
    </Grid>
  );
}
