export interface Identifiable {
  id: number; // Assuming `id` is a number, adjust if needed
  [key: string]: any; // Other properties can be of any type
}

export type SubsetResult = "full" | "partial" | "none";

export function subsetStatus<T extends Identifiable>(
  arr1: T[],
  arr2: T[]
): SubsetResult {
  // Helper function to check if two objects are equal (based on all keys)
  const isEqual = (obj1: T, obj2: T): boolean => obj1.id === obj2.id;

  // Count how many objects in arr1 exist in arr2
  const matchingCount = arr1.filter((obj1) =>
    arr2.some((obj2) => isEqual(obj1, obj2))
  ).length;

  // Determine the result based on the matching count
  if (matchingCount === 0) {
    return "none"; // No objects in arr1 exist in arr2
  } else if (matchingCount === arr1.length) {
    return "full"; // All objects in arr1 are in arr2
  } else {
    return "partial"; // Some but not all objects in arr1 are in arr2
  }
}
