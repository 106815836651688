import React from "react";
import ResidentsFormPage from "../../../components/ResidentsFormPage";
import { useParams } from "react-router-dom";
import { Resident } from "../../../classes";
import { useResidentsApi } from "../../../apis";

export default function ResidentEdit() {
  const { id: residentId } = useParams();
  const { updateResident, deleteResident } = useResidentsApi();

  const onSave = async (resident: Resident) =>
    updateResident(Number(residentId), resident.prepareForApi());

  const onDelete = async (resident: Resident) => {
    if (window.confirm("Are you sure you want to delete this resident?")) {
      return deleteResident(resident.id as number);
    }
  };

  return (
    <ResidentsFormPage
      title="Resident"
      status="VIEW"
      residentId={Number(residentId)}
      onSave={onSave}
      onDelete={onDelete}
      saveButtonText="Save"
    />
  );
}
