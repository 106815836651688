import React from "react";
import { Link } from "react-router-dom";
import { Grid, Button } from "@mui/material";
import { colors } from "../constants";
import styled from "styled-components";

const GridStyles = styled(Grid)`
  position: sticky !important;
  top: 0;
  z-index: 11;

  background-color: ${colors.white} !important;
  border-bottom: 1px solid ${colors.grey[0]};
`;

interface HeaderLinkProps {
  to?: string;
  text: string;
  icon: JSX.Element;
  onClick?: React.MouseEventHandler;
}

const HeaderLink = ({ icon, text, to, onClick }: HeaderLinkProps) => {
  return (
    <GridStyles
      container
      justifyContent="flex-end"
      alignItems="center"
      height="4rem"
      sx={{
        paddingX: 1,
      }}
    >
      {to && (
        <Link to={to}>
          <Button variant="outlined" startIcon={icon}>
            {text}
          </Button>
        </Link>
      )}
      {onClick && (
        <Button variant="outlined" startIcon={icon} onClick={onClick}>
          {text}
        </Button>
      )}
    </GridStyles>
  );
};

export default HeaderLink;
