import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Grid,
  TextField,
  Select,
  FormControl,
  InputLabel,
  Card,
  CardContent,
  CardActions,
  SelectChangeEvent,
} from "@mui/material";

import { extractOptions } from "./index";

import { Collection } from "../classes/collection";
import { Resident } from "../classes/resident";
import {
  collectionPaymentOrganizationOptions,
  CollectionPayment,
  collectionPaymentTypeOptions,
  COLLECTION_PAYMENT_TYPES,
} from "../classes/collection-payment";
import CustomPrice from "./CustomPrice";
import Toggle from "./Toggle";

interface CollectionPaymentRowProps {
  index: number;
  payment: CollectionPayment;
  resident: Resident;
  collection: Collection;
  collections: { items: Collection[]; total: number; overallBalance: number };
  setCollection: React.Dispatch<React.SetStateAction<Collection>>;
  disabled?: boolean;
}

export default function CollectionPaymentRow({
  index,
  payment,
  resident,
  collection,
  collections,
  setCollection,
  disabled,
}: CollectionPaymentRowProps) {
  const handleInput = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent
  ) => {
    const newCollection = new Collection(collection);

    if (!newCollection.payments) return;

    newCollection.payments[index] = new CollectionPayment({
      ...payment,
      [e.target.name]: e.target.value,
    });

    setCollection(newCollection);
  };

  const handleChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newCollection = new Collection(collection);

    if (!newCollection.payments) return;

    newCollection.payments[index] = new CollectionPayment({
      ...payment,
      [e.target.name]: e.target.checked,
    });

    setCollection(newCollection);
  };

  const removePayment = () => {
    const newCollection = new Collection(collection);
    newCollection.removePayment(index);
    setCollection(newCollection);
  };

  const customPriceChange = (value: number) => {
    const newCollection = new Collection(collection);

    if (!newCollection.payments) return;

    newCollection.payments[index] = new CollectionPayment({
      ...payment,
      amount: value,
    });

    setCollection(newCollection);
  };

  const overallBalance = collections.overallBalance;

  let newCollectionPaymentTypeOptions = collectionPaymentTypeOptions;
  if (overallBalance <= 0 && !payment.isCredit()) {
    newCollectionPaymentTypeOptions = newCollectionPaymentTypeOptions.filter(
      (option) => option.value !== COLLECTION_PAYMENT_TYPES.CREDIT
    );
  }

  return (
    <Card style={{ marginTop: index > 0 ? "1rem" : "" }} variant="outlined">
      <CardContent>
        <FormControl
          fullWidth
          variant="outlined"
          disabled={disabled || payment.isCredit()}
          required
          style={{ margin: "0.5rem 0" }}
        >
          <InputLabel>Payment Type</InputLabel>
          <Select
            native
            label="Payment Type"
            value={payment.type}
            onChange={handleInput}
            name="type"
          >
            {extractOptions(newCollectionPaymentTypeOptions)}
          </Select>
        </FormControl>

        <Grid
          container
          style={{ paddingLeft: "1rem" }}
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
        >
          {!payment.isCredit() && (
            <>
              <Grid item>
                <Toggle
                  label="Is GEO Payment"
                  value={payment.isGeo}
                  onChange={handleChecked}
                  name="isGeo"
                  disabled={disabled}
                  style={{ marginTop: "0.5rem" }}
                />
              </Grid>

              <Grid item>
                <FormControl
                  fullWidth
                  variant="outlined"
                  disabled={disabled}
                  required
                  style={{ marginTop: "0.5rem", width: 150 }}
                  size="small"
                >
                  <InputLabel>Organization</InputLabel>
                  <Select
                    native
                    label="Organization"
                    value={payment.organization}
                    name="organization"
                    onChange={handleInput}
                  >
                    {extractOptions(collectionPaymentOrganizationOptions)}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item>
                <TextField
                  fullWidth
                  style={{ marginTop: "0.5rem", width: 150 }}
                  variant="outlined"
                  label="Check Number"
                  value={payment.checkNumber}
                  name="checkNumber"
                  onChange={handleInput}
                  disabled={disabled}
                  size="small"
                />
              </Grid>
            </>
          )}

          <Grid item style={{ marginTop: "0.5rem" }}>
            <CustomPrice
              value={payment.amount}
              maxValue={payment.isCredit() ? overallBalance : undefined}
              disabled={disabled || payment.isCredit()}
              onChange={customPriceChange}
            />
          </Grid>
        </Grid>
      </CardContent>

      {!disabled && !payment.isCredit() && (
        <CardActions>
          <Grid container justifyContent="flex-end">
            <Button color="secondary" size="small" onClick={removePayment}>
              Remove
            </Button>
          </Grid>
        </CardActions>
      )}
    </Card>
  );
}

CollectionPaymentRow.propTypes = {
  payment: PropTypes.instanceOf(CollectionPayment).isRequired,
  index: PropTypes.number.isRequired,
  resident: PropTypes.instanceOf(Resident).isRequired,
  collection: PropTypes.instanceOf(Collection).isRequired,
  setCollection: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
