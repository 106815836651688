import React, { useState } from "react";
import LocationForm from "../../../components/LocationForm";
import { useNavigate } from "react-router-dom";
import { Location } from "../../../classes";
import { useLocationsApi } from "../../../apis";

export default function AddLocation() {
  const { addLocation } = useLocationsApi();
  const navigate = useNavigate();

  const [location, setLocation] = useState<Location | undefined>(
    new Location({
      name: "",
      address: "",
      city: "",
      state: "ID",
      zipcode: "",
      phoneNumber: "",
      gender: "",
      code: "",
      houseManagers: [],
      totalBeds: 0,
      availableBeds: 0,
      usedBeds: 0,
      totalTopBunkBeds: 0,
      isOutlander: false,
      includeAvailableBeds: true,
    })
  );

  const onSave = async () => {
    if (!location) return;

    try {
      await addLocation(location);

      navigate(-1);
    } catch (e) {
      console.error(e);
    }
  };

  if (!location) return null;

  return (
    <LocationForm
      title="Add Location"
      location={location}
      setLocation={setLocation}
      onSave={onSave}
    />
  );
}
