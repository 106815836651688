import React from "react";
import isToday from "date-fns/isToday";
import addDays from "date-fns/addDays";
import subDays from "date-fns/subDays";
import format from "date-fns/format";
import { Grid, Typography, IconButton } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

interface DateNavigationProps {
  date: Date;
  onChange: (date: Date) => void;
  leftDisabled?: () => boolean;
  rightDisabled?: () => boolean;
}

const DateNavigation = ({
  date,
  onChange,
  leftDisabled = () => false,
  rightDisabled = () => false,
}: DateNavigationProps) => {
  const backwardDate = () => onChange(subDays(date, 1));
  const forwardDate = () => onChange(addDays(date, 1));
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ padding: 0.5 }}
    >
      <Grid
        item
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{
          maxWidth: "300px",
          width: "100%",
        }}
      >
        <IconButton onClick={backwardDate} disabled={leftDisabled()}>
          <ChevronLeftIcon />
        </IconButton>
        <Typography>{format(date, "PPP")}</Typography>
        <IconButton onClick={forwardDate} disabled={rightDisabled()}>
          <ChevronRightIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default DateNavigation;
