import styled from "styled-components";
import useSpaceProps from "../utils/spacing-props";
import { colors } from "../constants";

const Divider = styled.div`
  ${useSpaceProps}

  height: 1px;
  width: 100%;
  background-color: ${colors.grey[6]};
`;

export default Divider;
