import React from "react";
import styled from "styled-components";

const TextAreaField = styled(
  ({ className, label, name, type = "text", value, onChange, disabled }) => {
    return (
      <div className={className}>
        <label>
          {label}
          <textarea
            name={name}
            onChange={onChange}
            disabled={disabled}
            value={value}
          />
        </label>
      </div>
    );
  }
)`
  font-size: 12px;
  margin: 8px 0;

  & textarea {
    display: block;
    outline: none;
    width: calc(100% - 1em);
    font-size: 1rem;
    border: 1px solid #eeeeee;
    border-radius: 0.25rem;
    padding: 0.5em 0.5em;
  }

  & textarea:disabled {
    background-color: #eeeeee;
    cursor: not-allowed;
  }
`;

export default TextAreaField;
