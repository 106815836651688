import { useCallback } from "react";
import { useApi } from "../hooks";

export function useResidentFilesApi() {
  const { fetchDELETE } = useApi();

  const deleteFile = useCallback(
    (id: number) => fetchDELETE(`resident-files/${id}`),
    [fetchDELETE]
  );

  return {
    deleteFile,
  };
}
