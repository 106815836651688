import React from "react";
import { Grid, Typography, TextField, InputAdornment } from "@mui/material";

import { CollectionItem } from "../classes";
import { formatMoney, keyCodeToNumberChangeArray } from "../utils";

interface CustomPriceProps {
  label?: string;
  size?: "small" | "medium";
  onChange: (value: number) => void;
  value: number;
  maxValue?: number;
  disabled?: boolean;
  fullWidth?: boolean;
}

export default function CustomPrice({
  label: _label = "Price",
  size = "small",
  onChange,
  value,
  maxValue,
  disabled,
  fullWidth,
}: CustomPriceProps) {
  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const array = CollectionItem.numberToArray(value);

    keyCodeToNumberChangeArray(e, array);

    const newValue = CollectionItem.arrayToNumber(array);

    if (maxValue && newValue > maxValue) return;

    onChange(newValue);
  };

  const label = maxValue ? `${_label} (Max ${formatMoney(maxValue)})` : _label;

  return (
    <Grid item flex={1}>
      <TextField
        style={{
          width: !fullWidth ? 170 : undefined,
        }}
        size={size}
        variant="outlined"
        label={label}
        onKeyDown={handleOnKeyDown}
        value={formatMoney(value, true)}
        disabled={disabled}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        inputProps={{
          pattern: "[0-9]+([.,][0-9]+)?",
          inputMode: "numeric",
        }}
        fullWidth={fullWidth}
      />
    </Grid>
  );
}
