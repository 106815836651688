import { useCallback } from "react";
import { useApi } from "../hooks";

export function useResidentCommentsApi() {
  const { fetchPUT, fetchDELETE } = useApi();

  const updateResidentComment = useCallback(
    (id: number, body: object) => fetchPUT(`resident-comments/${id}`, body),
    [fetchPUT]
  );

  const deleteResidentComment = useCallback(
    (id: number) => fetchDELETE(`resident-comments/${id}`),
    [fetchDELETE]
  );

  return {
    updateResidentComment,
    deleteResidentComment,
  };
}
