import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import { DashboardRoutes } from "./Dashboard";
import { Login } from "./Login";

import { RequireAuth } from "../components/RequireAuth";
import { MeProvider } from "../store/me";
import PrintActivityPage from "./Print/PrintActivity";
import { GlobalLoadingProvider } from "../components/GlobalLoading";
import PrintCurfewReportPage from "./Print/PrintCurfewReport";

export default function AppRoutes() {
  return (
    <GlobalLoadingProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />

          <Route
            path="/dashboard/*"
            element={
              <RequireAuth>
                <MeProvider>
                  <DashboardRoutes />
                </MeProvider>
              </RequireAuth>
            }
          />

          <Route path="/print-activity" element={<PrintActivityPage />} />
          <Route
            path="/print-curfew-report"
            element={<PrintCurfewReportPage />}
          />

          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
      </BrowserRouter>
    </GlobalLoadingProvider>
  );
}
