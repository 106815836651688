import React from "react";
import {
  FormControl,
  NativeSelect,
  InputLabel,
  OutlinedInput,
} from "@mui/material";

const sortOptions = (options: OptionType[]) =>
  options.sort((a, b) => a.text.localeCompare(b.text));

const getOptionElems = (options: OptionType[]) => {
  return options.map((option) => (
    <option value={option.value} key={option.value} disabled={option.disabled}>
      {option.text}
    </option>
  ));
};

export const extractOptions = (options: any, sort?: boolean) => {
  let optionElems;
  if (Array.isArray(options)) {
    const sortedOptions = sort ? sortOptions(options) : options;

    optionElems = getOptionElems(sortedOptions);
  } else {
    const keys = Object.keys(options);

    optionElems = keys.map((key) => {
      const subOptions = options[key];
      const sortedOptions = sort ? sortOptions(subOptions) : subOptions;
      const subOptionElems = getOptionElems(sortedOptions);

      return (
        <optgroup key={key} label={key}>
          {subOptionElems}
        </optgroup>
      );
    });
  }

  return optionElems;
};

interface OptionType {
  value: string;
  text: string;
  disabled?: boolean;
}

interface SelectProps {
  label: string;
  name: string;
  options: OptionType[];
  value: string | string[];
  disabled?: boolean;
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
  multiple?: boolean;
  sort?: boolean;
  addChooseOption?: boolean;
  required?: boolean;
}

export const Select = ({
  label,
  name,
  options,
  value,
  disabled,
  onChange,
  multiple,
  sort,
  addChooseOption,
  required,
}: SelectProps) => {
  const optionElems = extractOptions(options, sort);

  let multipleLabel;
  if (multiple) {
    const texts = (value as string[])
      .map((v) => {
        const option = options.find((option) => option.value === v);
        if (option) {
          return option.text;
        }
      })
      // Now get rid of any undefined values
      .filter((a) => a);
    multipleLabel = (
      <div>
        <b>{texts.join(", ")}</b>
      </div>
    );
  }

  return (
    <FormControl fullWidth>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      {multipleLabel}
      <NativeSelect
        inputProps={{
          name,
          id: name,
          multiple,
        }}
        disabled={disabled}
        value={value}
        onChange={onChange}
        required={required}
        input={<OutlinedInput label={label} />}
      >
        {addChooseOption && <option value=""></option>}

        {optionElems}
      </NativeSelect>
    </FormControl>
  );
};
