import React from "react";
import { Typography } from "@mui/material";
import {
  Table,
  TableRow,
  TableBody,
  TableHeader,
  TableSelectedSectionButton,
  TableCell,
  TableSearchField,
  RowType,
} from "./Table";
import {
  LocationFilter,
  ManagersFilter,
  ResidentFilter,
  ResidentLastUaSort,
  TableFilterModal,
  DistrictFilter,
} from "./TableFilter";

import { colors, headerLinkHeight, bottomNavigationHeight } from "../constants";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { Curfew } from "../classes";

const Header = () => (
  <>
    <TableCell basis="250px">Resident</TableCell>
    <TableCell basis="150px">Destination</TableCell>
    <TableCell basis="150px">ETA</TableCell>
  </>
);

const EmptyView = () => (
  <Typography
    variant="h5"
    align="center"
    sx={{
      color: colors.grey[5],
      marginTop: 8,
    }}
  >
    No Curfew
  </Typography>
);

interface RowProps {
  row: RowType;
  selected: boolean;
  onClick: () => void;
  tableBodyRef: any;
}

const Row = ({ row, ...props }: RowProps) => (
  <TableRow row={row} SelectedSectionElem={SelectedSection} {...props}>
    <TableCell basis="250px" semiBold>
      {[row.curfew?.resident?.lastName, row.curfew?.resident?.firstName]
        .filter(Boolean)
        .join(", ")}
    </TableCell>
    <TableCell basis="150px" color={colors.grey[3]}>
      {row.curfew?.destination}
    </TableCell>
    <TableCell basis="150px" color={colors.grey[3]}>
      {row.curfew?.eta}
    </TableCell>
  </TableRow>
);

interface SelectedSectionProps {
  row: RecordProps;
  onView: (row: RowType) => void;
}

const SelectedSection = ({ row, onView }: SelectedSectionProps) => {
  const handleView = () => onView(row);

  return (
    <TableSelectedSectionButton icon={faEye} onClick={handleView}>
      View
    </TableSelectedSectionButton>
  );
};

interface RecordProps {
  curfew: Curfew;
}

interface CurfewTableProps {
  rows: RecordProps[];
  total: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  onRowSelect: (record: RowType) => void;
}

const CurfewTable = ({
  rows,
  total,
  setPage,
  search,
  setSearch,
  onRowSelect,
}: CurfewTableProps) => {
  const filterContent = [
    ResidentFilter,
    LocationFilter,
    DistrictFilter,
    ManagersFilter,
    ResidentLastUaSort,
  ];

  return (
    <Table
      style={{
        height: `calc(100vh - ${headerLinkHeight} - ${bottomNavigationHeight})`,
      }}
    >
      <TableFilterModal
        filterContent={filterContent}
        callback={() => setPage(0)}
      />
      <TableSearchField
        value={search}
        onChange={(e) => {
          setPage(0);
          // If user selects from the existing search list,
          // value will be empty and textContent will have the search string
          e.target.value
            ? setSearch(e.target.value)
            : setSearch(e.target.textContent ?? "");
        }}
        filterContent={filterContent}
      />
      <TableHeader>
        <Header />
      </TableHeader>
      <TableBody
        rows={rows}
        total={total}
        RowElem={Row}
        EmptyViewElem={EmptyView}
        onLoadMore={() => setPage((page) => page + 1)}
        onRowSelect={onRowSelect}
      />
    </Table>
  );
};

export default CurfewTable;
