import DatabaseObject, {
  DatabaseObjectConstructorType,
} from "./database-object";
import { Resident, ResidentConstructorType } from "./resident";
import { User } from "./user";

export interface ResidentReportConstructorType
  extends DatabaseObjectConstructorType {
  report: string;
  resident?: ResidentConstructorType;
  residentId: number;
  creatorId?: number;
  creator?: User;
}

export class ResidentReport extends DatabaseObject {
  report = "";
  resident?: Resident;
  residentId: number;
  creatorId?: number;
  creator?: User;

  constructor(data: ResidentReportConstructorType) {
    super(data);

    this.residentId = data.residentId;
    this.report = data.report;
    if (data.resident) this.resident = new Resident(data.resident);
    if (data.creatorId) this.creatorId = data.creatorId;
    if (data.creator) this.creator = new User(data.creator);
  }

  prepareForApi() {
    const obj = super.prepareForApi() as Partial<ResidentReport>;

    delete obj.resident;
    delete obj.creator;

    return obj;
  }
}
