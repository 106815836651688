import React from "react";
import {
  RadioButton,
  TableSearchField,
  TableSelectedSectionButton,
} from "./index";
import { headerLinkHeight, bottomNavigationHeight, colors } from "../constants";
import format from "date-fns/format";
import { convertUTCToLocal } from "../utils/date-helpers";

import {
  MOVED_IN,
  MOVED_OUT,
  PENDING,
  REQUEST_MOVE_IN,
  Resident,
} from "../classes/resident";

import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { TableContainer } from "./TableNew";
import { Grid, Typography } from "@mui/material";
import {
  LocationFilter,
  ManagersFilter,
  ResidentFilter,
  ResidentStatusFilter,
  TableFilterModal,
  DistrictFilter,
  useTableFilter,
} from "./TableFilter";
import { useNavigate } from "react-router-dom";
import {
  faEye,
  faHome,
  faSignInAlt,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";

interface RecordProps {
  resident: Resident;
}

const columnHelper = createColumnHelper<RecordProps>();

const defaultColumns = [
  columnHelper.accessor(
    (row) =>
      [row.resident.lastName, row.resident.firstName]
        .filter(Boolean)
        .join(", "),
    {
      header: "Name",
      cell: (info) => <strong>{info.getValue()}</strong>,
      size: 200,
    }
  ),
  columnHelper.accessor("resident.location.name", {
    header: "Location",
    size: 66,
  }),
  columnHelper.accessor("resident.supervision", {
    header: "Supervision",
    size: 120,
  }),
  columnHelper.accessor("resident.lastUaDate", {
    header: "Last UA",
    cell: (info) => {
      const value = info.getValue();
      return value && format(convertUTCToLocal(value), "M/dd/yy");
    },
    size: 86,
  }),
  columnHelper.accessor("resident.rentDueDate", {
    header: "Rent Due",
    cell: (info) => {
      const value = info.getValue();
      return value && format(convertUTCToLocal(value), "M/dd/yy");
    },
    size: 86,
  }),
];

interface SelectedSectionProps {
  row: RecordProps;
  tableBodyRef: React.RefObject<HTMLDivElement>;
}

const SelectedSection = ({ row, tableBodyRef }: SelectedSectionProps) => {
  const navigate = useNavigate();

  const goTo = (path: string) => () => {
    const tableBodyScrollTop = tableBodyRef.current?.scrollTop;
    console.log("### goTo tableBodyRef", tableBodyRef);
    navigate(`${path}/${row.resident.id}`, {
      state: { tableBodyScrollTop },
    });
  };

  return (
    <>
      {row.resident && row.resident.status === PENDING && (
        <>
          <TableSelectedSectionButton icon={faEye} onClick={goTo("view")}>
            View
          </TableSelectedSectionButton>
          <TableSelectedSectionButton
            icon={faSignInAlt}
            onClick={goTo("request-move-in")}
          >
            Req Move In
          </TableSelectedSectionButton>
        </>
      )}

      {row.resident && row.resident.status === REQUEST_MOVE_IN && (
        <>
          <TableSelectedSectionButton icon={faEye} onClick={goTo("view")}>
            View
          </TableSelectedSectionButton>
          <TableSelectedSectionButton
            icon={faSignInAlt}
            onClick={goTo("move-in")}
          >
            Move In
          </TableSelectedSectionButton>
        </>
      )}

      {row.resident && row.resident.status === MOVED_IN && (
        <>
          <TableSelectedSectionButton icon={faEye} onClick={goTo("view")}>
            View
          </TableSelectedSectionButton>
          <TableSelectedSectionButton
            icon={faHome}
            onClick={goTo("location-change")}
          >
            Loc Change
          </TableSelectedSectionButton>

          <TableSelectedSectionButton
            icon={faSignOutAlt}
            onClick={goTo("move-out")}
          >
            Move Out
          </TableSelectedSectionButton>
        </>
      )}

      {row.resident && row.resident.status === MOVED_OUT && (
        <TableSelectedSectionButton icon={faEye} onClick={goTo("view")}>
          View
        </TableSelectedSectionButton>
      )}
    </>
  );
};

interface StatusFilterProps {
  callback: () => void;
}
const StatusFilter = ({ callback }: StatusFilterProps) => {
  const { status: value, setStatus } = useTableFilter();

  const _setStatus = (status: string) => {
    setStatus([status]);
    callback && callback();
  };

  return (
    <div style={{ backgroundColor: colors.grey[6] }}>
      <Typography sx={{ marginLeft: 0.5, fontWeight: "bold" }}>
        Group Residents by:
      </Typography>
      <Grid
        container
        wrap="nowrap"
        sx={{ paddingX: 0.5, paddingY: 0.5, gap: "8px" }}
      >
        <RadioButton
          active={value && value[0] === PENDING}
          value={PENDING}
          onClick={_setStatus}
        >
          Pend
        </RadioButton>
        <RadioButton
          active={value && value[0] === REQUEST_MOVE_IN}
          value={REQUEST_MOVE_IN}
          onClick={_setStatus}
        >
          Rq Move In
        </RadioButton>
        <RadioButton
          active={value && value[0] === MOVED_IN}
          value={MOVED_IN}
          onClick={_setStatus}
        >
          Moved In
        </RadioButton>
        <RadioButton
          active={value && value[0] === MOVED_OUT}
          value={MOVED_OUT}
          onClick={_setStatus}
        >
          Moved Out
        </RadioButton>
      </Grid>
    </div>
  );
};

interface HeaderStatsProps {
  total: number;
  totalAvailableBeds: number;
}

const HeaderStats = ({ total, totalAvailableBeds }: HeaderStatsProps) => (
  <Grid
    container
    justifyContent="flex-end"
    gap={1}
    sx={{ backgroundColor: colors.grey[6], px: 1 }}
  >
    <Typography variant="body2">
      Available Beds: {totalAvailableBeds}
    </Typography>
    <Typography variant="body2">Count: {total}</Typography>
  </Grid>
);

interface ManagerTableProps {
  rows: RecordProps[];
  total: number;
  totalAvailableBeds: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  onRowClick?: (row: RecordProps) => void;
}

const ResidentTable = ({
  rows,
  total,
  totalAvailableBeds,
  setPage,
  search,
  setSearch,
  onRowClick,
}: ManagerTableProps) => {
  const filterContent = [
    ResidentStatusFilter,
    ResidentFilter,
    LocationFilter,
    DistrictFilter,
    ManagersFilter,
  ];

  const table = useReactTable({
    data: rows,
    columns: defaultColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div>
      <TableFilterModal
        filterContent={filterContent}
        callback={() => setPage(0)}
      />
      <TableSearchField
        value={search}
        onChange={(e) => {
          setPage(0);
          // If user selects from the existing search list,
          // value will be empty and textContent will have the search string
          e.target.value
            ? setSearch(e.target.value)
            : setSearch(e.target.textContent ?? "");
        }}
        filterContent={filterContent}
      />
      <StatusFilter callback={() => setPage(0)} />
      <HeaderStats total={total} totalAvailableBeds={totalAvailableBeds} />
      <TableContainer<RecordProps>
        table={table}
        SelectedRowComponent={SelectedSection}
        tableBodyHeight={`calc(100vh - ${headerLinkHeight} - ${bottomNavigationHeight} - 64px - 55px - 20px - 38px)`}
        total={total}
        onLoadMore={() => setPage((prev) => prev + 1)}
        emptyViewText="No residents"
      />
    </div>
  );
};

export default ResidentTable;
