import { useCallback } from "react";
import {
  QueryParams,
  useApi,
  GetListInputType,
  GetListOutputType,
} from "../hooks";
import { Curfew } from "../classes";

export function useCurfewsApi() {
  const {
    fetchGETList,
    fetchGET,
    fetchPOST,
    fetchPOSTTyped,
    fetchPUT,
    fetchDELETE,
  } = useApi();

  const getCurfew = useCallback(
    (id: number) => fetchGET(`curfews/${id}`),
    [fetchGET]
  );

  const getCurfews = useCallback(
    (queryParams?: QueryParams): Promise<GetCurfewsOutputType> =>
      fetchGETList("curfews", queryParams),
    [fetchGETList]
  );

  const addCurfew = useCallback(
    (body: object) => fetchPOSTTyped<Curfew>("curfews", body),
    [fetchPOST]
  );

  const updateCurfew = useCallback(
    (id: number, body: object) => fetchPUT(`curfews/${id}`, body),
    [fetchPUT]
  );

  const deleteCurfew = useCallback(
    (id: number) => fetchDELETE(`curfews/${id}`),
    [fetchDELETE]
  );

  return {
    getCurfew,
    getCurfews,
    addCurfew,
    updateCurfew,
    deleteCurfew,
  };
}

export interface GetCurfewsInputType extends GetListInputType {
  search?: string;
  locationIds?: number | number[];
  districtIds?: number | number[];
  rentDueDate__eq?: string;
  rentDueDate__lt?: string;
  isGeo?: boolean;
  hasNoRentDueDate?: boolean;
  includeNegativeBalance?: boolean;
  lastUaDate__order?: "asc" | "desc";
  date: string;
}

export type GetCurfewsOutputType = GetListOutputType<Curfew>;
