import DatabaseObject, {
  DatabaseObjectConstructorType,
} from "./database-object";
import { Location } from "./location";

export interface LocationPhotoConstructorType
  extends DatabaseObjectConstructorType {
  s3Key?: string;
  url?: string;
  caption?: string;
  location?: Location;
  locationId?: number;
  file?: File;
}

export class LocationPhoto extends DatabaseObject {
  s3Key?: string;
  url?: string;
  caption = "";
  location?: Location;
  locationId?: number;

  file?: File;

  constructor(data?: LocationPhotoConstructorType) {
    super(data);

    if (!data) return;

    if (data.s3Key) this.s3Key = data.s3Key;
    if (data.url) this.url = data.url;
    if (data.caption) this.caption = data.caption;
    if (data.location) this.location = new Location(data.location);
    if (data.locationId) this.locationId = data.locationId;
    if (data.file) this.file = data.file;
  }
}
