import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { Cell, flexRender, Header, Row, Table } from "@tanstack/react-table";
import { colors } from "../constants";

type TableProps<T> = {
  table: Table<T>;
  SelectedRowComponent?: React.FC<{
    row: T;
    tableBodyRef: React.RefObject<HTMLDivElement>;
  }>;
  onRowClick?: (row: T) => void;
  tableBodyHeight: string;
  total: number;
  onLoadMore: () => void;
  emptyViewText: string;
};

export function TableContainer<T>({
  table,
  SelectedRowComponent,
  onRowClick,
  tableBodyHeight,
  total,
  onLoadMore,
  emptyViewText,
}: TableProps<T>) {
  const [selectedIndex, setSelectedIndex] = React.useState<number | null>(null);
  const tableBodyRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (table.getRowModel().rows.length === 0) return;

    const tableBodyScrollTop = localStorage.getItem("tableBodyScrollTop");

    if (tableBodyRef.current && tableBodyScrollTop) {
      tableBodyRef.current.scrollTop = Number(tableBodyScrollTop);
      localStorage.removeItem("tableBodyScrollTop");
    }
  }, [table.getRowModel().rows.length]);

  return (
    <div>
      {table.getHeaderGroups().map((headerGroup) => (
        <TableHeaderRow key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <TableHeaderCell<T> key={header.id} header={header} />
          ))}
        </TableHeaderRow>
      ))}
      <div
        ref={tableBodyRef}
        style={{
          overflowY: "scroll",
          height: tableBodyHeight,
        }}
      >
        {table.getRowModel().rows.map((row, index) => (
          <TableBodyRow
            key={row.id}
            row={row}
            tableBodyRef={tableBodyRef}
            onClick={() => {
              SelectedRowComponent &&
                setSelectedIndex(() => {
                  if (selectedIndex === index) return null;
                  return index;
                });
              onRowClick && onRowClick(row.original);
            }}
            isSelected={selectedIndex === index}
            SelectedRowComponent={SelectedRowComponent}
          >
            {row.getVisibleCells().map((cell) => (
              <TableBodyCell key={cell.id} cell={cell} />
            ))}
          </TableBodyRow>
        ))}

        {table.getRowModel().rows.length < total && (
          <LoadMoreButton onClick={onLoadMore} />
        )}

        {table.getRowModel().rows.length === 0 && (
          <EmptyView text={emptyViewText} />
        )}
      </div>
    </div>
  );
}

type TableHeaderRowProps = {
  children: React.ReactNode;
};
export function TableHeaderRow({ children }: TableHeaderRowProps) {
  return (
    <Grid
      item
      container
      justifyContent="space-between"
      wrap="nowrap"
      borderBottom="1px solid #111111"
    >
      <Grid container justifyContent="space-between" wrap="wrap">
        {children}
      </Grid>
    </Grid>
  );
}

type TableHeaderCellProps<T> = {
  header: Header<T, unknown>;
};

export function TableHeaderCell<T>({ header }: TableHeaderCellProps<T>) {
  return (
    <Grid
      item
      container
      alignItems="center"
      flexGrow={0}
      flexShrink={1}
      flexBasis={header.getSize()}
      overflow="hidden"
      minHeight={22}
      paddingX={0.5}
    >
      <p
        style={{
          overflow: "hidden",
          whiteSpace: "nowrap",
          height: 22,
          padding: 0,
          margin: 0,
        }}
      >
        {header.isPlaceholder
          ? null
          : flexRender(header.column.columnDef.header, header.getContext())}
      </p>
    </Grid>
  );
}

type TableBodyRowProps<T> = {
  children: React.ReactNode;
  row: Row<T>;
  tableBodyRef: React.RefObject<HTMLDivElement>;
  onClick?: () => void;
  isSelected?: boolean;
  SelectedRowComponent?: React.FC<{
    row: T;
    tableBodyRef: React.RefObject<HTMLDivElement>;
  }>;
};

export function TableBodyRow<T>({
  children,
  row,
  tableBodyRef,
  onClick,
  isSelected,
  SelectedRowComponent,
}: TableBodyRowProps<T>) {
  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      borderBottom="1px solid #dddddd"
      onClick={onClick}
      sx={{
        transition: "0.1s",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: colors.grey[6],
        },
        boxShadow: isSelected ? `inset 0 0 20px ${colors.green[3]}` : undefined,
      }}
    >
      <Grid container justifyContent="space-between" wrap="wrap" minHeight={66}>
        {children}
      </Grid>

      {SelectedRowComponent && isSelected && (
        <Grid
          container
          wrap="nowrap"
          justifyContent="center"
          alignItems="center"
          onClick={(e) => e.stopPropagation()}
          sx={{
            backgroundColor: colors.green[3],
            height: "60px",
          }}
        >
          <SelectedRowComponent
            row={row.original}
            tableBodyRef={tableBodyRef}
          />
        </Grid>
      )}
    </Grid>
  );
}

type TableBodyCellProps<T> = {
  cell: Cell<T, unknown>;
};

export function TableBodyCell<T>({ cell }: TableBodyCellProps<T>) {
  return (
    <Grid
      item
      container
      alignItems="center"
      flexGrow={0}
      flexShrink={1}
      flexBasis={cell.column.getSize()}
      overflow="hidden"
      minHeight={22}
      paddingX={0.5}
    >
      <p
        style={{
          overflow: "hidden",
          whiteSpace: "nowrap",
          height: 22,
          padding: 0,
          margin: 0,
        }}
      >
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </p>
    </Grid>
  );
}

type LoadMoreButtonProps = {
  onClick: () => void;
};

export function LoadMoreButton({ onClick }: LoadMoreButtonProps) {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        height: "60px",
      }}
    >
      <Button variant="outlined" color="primary" onClick={onClick}>
        Load More
      </Button>
    </Grid>
  );
}

type EmptyViewProps = {
  text: string;
};
export function EmptyView({ text }: EmptyViewProps) {
  return (
    <Typography
      variant="h5"
      align="center"
      sx={{
        color: colors.grey[5],
        marginTop: 8,
      }}
    >
      {text}
    </Typography>
  );
}
