import { Amplify } from "aws-amplify";
import Auth from "@aws-amplify/auth";

const cognitoConfig = {
  Auth: {
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,

    cookieStorage: {
      domain: location.hostname,
      path: "/",
      expires: 365,
      secure: true,
    },
  },

  API: {
    endpoints: [
      {
        name: "prime",
        endpoint: process.env.REACT_APP_API_URL,
      },
    ],
  },
};

const cognitoAuthConfig = {
  domain: process.env.REACT_APP_COGNITO_HOSTED_UI_DOMAIN,
  scope: ["phone", "email", "openid"],
  redirectSignIn: location.origin + "/dashboard",
  redirectSignOut: location.origin + "/login",
  responseType: "code",
};

Amplify.configure(cognitoConfig);
Auth.configure({ oauth: cognitoAuthConfig });
