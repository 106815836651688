import React from "react";
import {
  Table,
  TableRow,
  TableBody,
  TableHeader,
  TableSelectedSectionButton,
  TableCell,
  Typography,
  RowType,
  TableSearchField,
} from "./index";

import {
  colors,
  headerLinkHeight,
  bottomNavigationHeight,
  tabHeight,
} from "../constants";
import { faEye } from "@fortawesome/free-solid-svg-icons";

const Header = () => (
  <>
    <TableCell basis="150px">Company</TableCell>
    <TableCell basis="100px">Contact</TableCell>
    <TableCell basis="120px">Phone</TableCell>
    <TableCell basis="60px">Trade</TableCell>
    <TableCell basis="60px">City</TableCell>
  </>
);

const EmptyView = () => (
  <Typography type="h4" align="center" spacing="mt-8" color={colors.grey[5]}>
    No Contractors
  </Typography>
);

interface RowProps {
  row: RowType;
  selected: boolean;
  onClick: () => void;
  tableBodyRef: React.RefObject<HTMLDivElement>;
}
const Row = ({ row, ...props }: RowProps) => {
  return (
    <TableRow row={row} SelectedSectionElem={SelectedSection} {...props}>
      <TableCell basis="150px" ellipsis>
        {row.contractor?.companyName}
      </TableCell>
      <TableCell basis="100px" ellipsis>
        {row.contractor?.contactPerson}
      </TableCell>
      <TableCell basis="120px" ellipsis>
        {row.contractor?.phoneNumber}
      </TableCell>
      <TableCell basis="60px" ellipsis>
        {row.contractor?.trade}
      </TableCell>
      <TableCell basis="60px" ellipsis>
        {row.contractor?.city}
      </TableCell>
    </TableRow>
  );
};

interface SelectedSectionProps {
  row: RowType;
  onView: (row: RowType) => void;
}
const SelectedSection = ({ row, onView }: SelectedSectionProps) => {
  const handleView = () => onView(row);

  return (
    <TableSelectedSectionButton icon={faEye} onClick={handleView}>
      View
    </TableSelectedSectionButton>
  );
};

interface ContractorTableProps {
  rows: RowType[];
  total: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  onView: (record: RowType) => void;
}

const ContractorTable = ({
  rows,
  total,
  search,
  setSearch,
  setPage,
  onView,
}: ContractorTableProps) => {
  return (
    <Table
      style={{
        height: `calc(100vh - ${tabHeight} - ${headerLinkHeight} - ${bottomNavigationHeight})`,
      }}
    >
      <TableSearchField
        value={search}
        onChange={(e) => {
          setPage(0);
          // If user selects from the existing search list,
          // value will be empty and textContent will have the search string
          e.target.value
            ? setSearch(e.target.value)
            : setSearch(e.target.textContent ?? "");
        }}
      />
      <TableHeader>
        <Header />
      </TableHeader>
      <TableBody
        rows={rows}
        total={total}
        RowElem={Row}
        EmptyViewElem={EmptyView}
        onLoadMore={() => setPage((page) => page + 1)}
        onView={onView}
      />
    </Table>
  );
};

export default ContractorTable;
