import React, { useState, useCallback, useEffect, useMemo } from "react";
import {
  DistrictFilter,
  HeaderLink,
  LocationFilter,
  ManagersFilter,
  ResidentFilter,
  ResidentLastUaSort,
  ResidentStatusFilter,
  TableFilterModal,
  TableSearchField,
  useTableFilter,
} from "../../../components";
import { colors } from "../../../constants";
import {
  useResidentsApi,
  GetResidentsInputType,
  GetResidentsOutputType,
} from "../../../apis/residents";
import ResidentTable from "../../../components/ResidentTableNew";
import SendMessagesModal from "../../../components/SendMessagesModal";
import { Location } from "../../../classes/location";
import SendIcon from "@mui/icons-material/Send";

import {
  useReactTable,
  getCoreRowModel,
  ColumnDef,
  flexRender,
  Row,
} from "@tanstack/react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Grid,
  Typography,
} from "@mui/material";
import { Resident } from "../../../classes";
import { convertUTCToLocal } from "../../../utils/date-helpers";
import { format } from "date-fns";
import { Identifiable, subsetStatus } from "../../../utils/subsetStatus";

// Define columns
const columns: ColumnDef<Resident>[] = [
  {
    accessorFn: (row) =>
      [row.lastName, row.firstName].filter(Boolean).join(", "),
    header: "Name",
  },
  {
    accessorKey: "location.name",
    header: "Location",
  },
  {
    accessorKey: "supervision",
    header: "Supervision",
  },
  {
    accessorKey: "lastUaDate",
    header: "Last UA",
    cell: (info) => {
      const value = info.getValue<Date | undefined>();
      return value && format(convertUTCToLocal(value), "M/dd/yy");
    },
  },
];

export default function Messaging() {
  const { getResidents } = useResidentsApi();
  const {
    status,
    locations,
    districts,
    isGeo,
    hasNoRentDueDate,
    sortLastUaDate,
  } = useTableFilter();
  const [active, setActive] = useState(false);
  const [residents, setResidents] = useState<GetResidentsOutputType>({
    items: [],
    total: 0,
  });

  const [page, setPage] = useState(0);
  const [take] = useState(100);
  const [search, setSearch] = useState("");

  const [selected, setSelected] = useState<Resident[]>([]);

  const fetchData = useCallback(async () => {
    const customQuery: GetResidentsInputType = {
      skip: page * take,
      take,
    };

    if (status.length > 0) {
      customQuery.statuses = status;
    }

    if (locations.length > 0) {
      customQuery.locations = locations;
    }

    if (districts.length > 0) {
      customQuery.districts = districts;
    }

    if (isGeo) {
      customQuery.isGeo = isGeo;
    }

    if (hasNoRentDueDate) {
      customQuery.hasNoRentDueDate = hasNoRentDueDate;
    }

    if (search.trim()) {
      customQuery.search = search.trim();
    }

    if (sortLastUaDate) {
      customQuery.lastUaDate__order = "asc";
    }

    const _residents = await getResidents({ ...customQuery });

    if (page === 0) {
      setResidents(_residents);
    } else {
      setResidents({
        items: [...residents.items, ..._residents.items],
        total: _residents.total,
      });
    }
  }, [
    page,
    take,
    status,
    locations,
    districts,
    isGeo,
    hasNoRentDueDate,
    search,
    sortLastUaDate,
    getResidents,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Initialize the table instance with the data and columns
  const table = useReactTable({
    data: residents.items,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleClick = (
    _event: React.MouseEvent<unknown>,
    row: Row<Resident>
  ) => {
    const resident = row.original;
    const selectedIndex = selected.findIndex((r) => r.id === resident.id);
    let newSelected: Resident[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, resident);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const filterContent = [
    ResidentStatusFilter,
    ResidentFilter,
    LocationFilter,
    DistrictFilter,
    ManagersFilter,
    ResidentLastUaSort,
  ];

  const subsetStatusResult = useMemo(
    () =>
      subsetStatus(
        residents.items as Identifiable[],
        selected as Identifiable[]
      ),
    [residents.items, selected]
  );

  console.log("subsetStatusResult", subsetStatusResult);
  console.log("selected", selected);
  console.log("residents.items", residents.items);

  const onSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      // Select All
      // Merge all of residents.items with selected, removing duplicates
      const newSelected = [
        ...new Set([...selected, ...residents.items]),
      ] as Resident[];
      setSelected(newSelected);
      return;
    }
    // Deselect all
    // Remove all of residents.items from selected
    const newSelected = selected.filter(
      (r) => !residents.items.some((resident) => resident.id === r.id)
    );
    setSelected(newSelected);
  };

  return (
    <Grid container flexDirection="column">
      <HeaderLink
        icon={<SendIcon />}
        text="Prepare SMS"
        onClick={() => setActive(true)}
      />

      <TableSearchField
        value={search}
        onChange={(e) => {
          setPage(0);
          // If user selects from the existing search list,
          // value will be empty and textContent will have the search string
          e.target.value
            ? setSearch(e.target.value)
            : setSearch(e.target.textContent ?? "");
        }}
        filterContent={filterContent}
      />

      <Grid
        container
        justifyContent="flex-end"
        gap={1}
        sx={{ backgroundColor: colors.grey[6], px: 1 }}
      >
        <Typography variant="body2">Selected: {selected.length}</Typography>
      </Grid>

      <TableFilterModal
        filterContent={filterContent}
        callback={() => setPage(0)}
      />

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ height: "calc(100vh - 200px)" }}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                {table.getHeaderGroups().map((headerGroup) => (
                  <React.Fragment key={headerGroup.id}>
                    <TableCell>
                      <Checkbox
                        color="primary"
                        // Check to see if `residents` and
                        indeterminate={subsetStatusResult === "partial"}
                        checked={subsetStatusResult === "full"}
                        onChange={onSelectAllClick}
                      />
                    </TableCell>
                    {headerGroup.headers.map((header) => (
                      <TableCell key={header.id}>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </TableCell>
                    ))}
                  </React.Fragment>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {table.getRowModel().rows.map((row, index) => {
                const isItemSelected = selected.some(
                  (r) => r.id === row.original.id
                );
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell>
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>

                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <SendMessagesModal
        active={active}
        closeModal={() => setActive(false)}
        residents={selected}
      />
    </Grid>
  );
}
