import DatabaseObject, {
  DatabaseObjectConstructorType,
} from "./database-object";
import { WorkOrder, WorkOrderConstructorType } from "./work-order";
import { User } from "./user";

export interface WorkOrderCommentConstructorType
  extends DatabaseObjectConstructorType {
  comment: string;
  workOrder?: WorkOrderConstructorType;
  workOrderId: number;
  creatorId?: number;
  creator?: User;
}

export class WorkOrderComment extends DatabaseObject {
  comment = "";
  workOrder?: WorkOrder;
  workOrderId: number;
  creatorId?: number;
  creator?: User;

  constructor(data: WorkOrderCommentConstructorType) {
    super(data);

    this.workOrderId = data.workOrderId;
    this.comment = data.comment;
    if (data.workOrder) this.workOrder = new WorkOrder(data.workOrder);
    if (data.creatorId) this.creatorId = data.creatorId;
    if (data.creator) this.creator = new User(data.creator);
  }

  prepareForApi() {
    const obj = super.prepareForApi() as Partial<WorkOrderComment>;

    delete obj.workOrder;
    delete obj.creator;

    return obj;
  }
}
