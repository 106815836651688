import React, { useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import DatabaseObject from "../classes/database-object";

interface CommentModalProps<T> {
  open: boolean;
  closeModal: () => void;
  item: T;
  callback: () => void;
  newClass: (item: T, text?: string) => T;
  onSave: (item: T) => Promise<void>;
  label: string;
  name: string;
  getTextFieldValue: (item: T) => string;
}

export function CommentModal<T extends DatabaseObject>({
  open,
  closeModal,
  item: _item,
  callback,
  newClass,
  onSave,
  label,
  name,
  getTextFieldValue,
}: CommentModalProps<T>) {
  const addCommentEl = useRef<HTMLButtonElement>(null);

  const [item, setItem] = useState<T>(_item);

  useEffect(() => {
    setItem(_item);
  }, [_item]);

  const handleItemChange = (e: React.ChangeEvent<{ value: string }>) => {
    setItem(newClass(item, e.target.value));
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    await onSave(item);

    closeModal();
    callback();
  };

  return (
    <Dialog fullWidth open={open} onClose={closeModal}>
      <DialogTitle>
        {item.id === undefined ? "Add" : "Edit"} {label}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            label={label}
            name={name}
            value={getTextFieldValue(item)}
            onChange={handleItemChange}
            multiline
            rows={3}
            fullWidth
            sx={{ marginTop: 1 }}
            required
          />
          {/* Used as a "submit" proxy for the Save button */}
          <button
            ref={addCommentEl}
            type="submit"
            style={{ display: "none" }}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button color="error" variant="text" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => addCommentEl.current?.click()}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
