import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { FormHeader } from "./FormHeader";
import { CustomNumber } from "./CustomNumber";
import Toggle from "./Toggle";

import { STATE_ABBREVIATIONS } from "../constants";
import { Location } from "../classes/location";
import { User } from "../classes/user";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import { useUsersApi } from "../apis";
import { useMe } from "../store/me";
import WorkOrderList from "./WorkOrderList";
import Photos, { PhotoButton } from "./Photos";
import { LocationPhoto } from "../classes";
import { useLocationPhotos } from "../hooks/useLocationPhotos";

interface LocationFormProps {
  title: string;
  location: Location;
  setLocation: React.Dispatch<React.SetStateAction<Location | undefined>>;
  onSave: () => void;
  onDelete?: () => void;
}

export default function LocationForm({
  title,
  location,
  setLocation,
  onSave,
  onDelete,
}: LocationFormProps) {
  const { getUsers } = useUsersApi();

  const addLocationEl = useRef<HTMLButtonElement>(null);
  const [isDirty, setIsDirty] = useState(false);
  const [users, setUsers] = useState<{ items: User[]; total: number }>({
    items: [],
    total: 0,
  });
  const { isAdmin } = useMe();

  const fetchUsers = useCallback(async () => {
    const _users = await getUsers();
    setUsers(_users);
  }, [getUsers]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const {
    photos,

    addPhoto,
    removePhoto,
    updatePhoto,
  } = useLocationPhotos({ locationId: location.id });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value;
    switch (e.target.type) {
      case "checkbox":
        console.log(e.target.checked);
        value = e.target.checked;
        break;
      case "number":
        value = parseInt(e.target.value);
        break;
      default:
        value = e.target.value;
        break;
    }

    const newLocation = new Location({
      ...location,
      [e.target.name]: value,
    });

    setLocation(newLocation);

    setIsDirty(true);
  };

  const setBeds = (field: string) => (value: number) => {
    const newLocation = new Location({
      ...location,
      [field]: value,
    });

    setLocation(newLocation);
    setIsDirty(true);
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const newLocation = new Location({
      ...location,
      [e.target.name]: e.target.value,
    });

    setLocation(newLocation);
    setIsDirty(true);
  };

  const handleHouseManagerChange = (e: SelectChangeEvent<number[]>) => {
    const newLocation = new Location({
      ...location,
      [e.target.name]: (e.target.value as number[]).map((id: number) => ({
        id,
      })),
    });

    setLocation(newLocation);
    setIsDirty(true);
  };

  const houseManagers = users.items.map((user) => ({
    text: `${user.firstName} ${user.lastName}`,
    value: user.id as number,
  }));

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    try {
      onSave();
      setIsDirty(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <FormHeader
        title={title}
        primaryButton={{
          onClick: () => addLocationEl.current?.click(),
          text: "Save",
          startIcon: <SaveIcon />,
          disabled: !isDirty,
        }}
        isDirty={isDirty}
      />
      <div className="content">
        <form onSubmit={handleSubmit}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <TextField
                label="Name"
                name="name"
                value={location.name}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>

            <Grid item>
              <TextField
                label="Address"
                name="address"
                value={location.address}
                onChange={handleChange}
                fullWidth
              />
            </Grid>

            <Grid item>
              <TextField
                label="City"
                name="city"
                value={location.city}
                onChange={handleChange}
                fullWidth
              />
            </Grid>

            <Grid item>
              <FormControl fullWidth>
                <InputLabel id="state-label">State</InputLabel>
                <Select
                  labelId="state-label"
                  name="state"
                  variant="filled"
                  value={location.state}
                  input={<OutlinedInput label="State" />}
                  onChange={handleSelectChange}
                >
                  {STATE_ABBREVIATIONS.map(({ text, value }) => (
                    <MenuItem key={value} value={value}>
                      {text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item>
              <TextField
                label="ZIP Code"
                name="zipcode"
                value={location.zipcode}
                onChange={handleChange}
                fullWidth
              />
            </Grid>

            <Grid item container>
              <Toggle
                label="Is Outlander"
                name="isOutlander"
                value={location.isOutlander}
                onChange={handleChange}
              />
            </Grid>

            <Grid item>
              <TextField
                label="Location Phone Number"
                name="phoneNumber"
                value={location.phoneNumber}
                onChange={handleChange}
                fullWidth
              />
            </Grid>

            <Grid item>
              <FormControl fullWidth required>
                <InputLabel id="gender-label">Gender</InputLabel>
                <Select
                  labelId="gender-label"
                  name="gender"
                  variant="filled"
                  value={location.gender}
                  input={<OutlinedInput label="Gender" />}
                  onChange={handleSelectChange}
                  required
                >
                  <MenuItem value="MALE">Male</MenuItem>
                  <MenuItem value="FEMALE">Female</MenuItem>
                  <MenuItem value="COED">Coed</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item>
              <TextField
                label="Short Name"
                name="code"
                value={location.code}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>

            <Grid item>
              <FormControl fullWidth>
                <InputLabel id="house-managers-label">
                  House Managers
                </InputLabel>
                <Select
                  labelId="house-managers-label"
                  name="houseManagers"
                  variant="filled"
                  multiple
                  value={location.houseManagers.map((user) => user.id || 0)}
                  input={<OutlinedInput label="House Managers" />}
                  onChange={handleHouseManagerChange}
                >
                  {houseManagers.map(({ text, value }) => (
                    <MenuItem key={value} value={value}>
                      {text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item container>
              <Typography variant="h6">Photos</Typography>
              <Photos<LocationPhoto>
                photos={photos}
                updatePhoto={updatePhoto}
                addPhoto={addPhoto}
                removePhoto={removePhoto}
                editable
                saveFirst={location.id === undefined}
                PhotoButtonComponent={PhotoButton<LocationPhoto>}
              />
            </Grid>

            <Grid item>
              <TextField
                label="Notes"
                name="notes"
                value={location.notes}
                onChange={handleChange}
                multiline
                minRows={3}
                fullWidth
              />
            </Grid>

            <Grid item>
              <CustomNumber
                label="Total Beds"
                value={location.totalBeds}
                onChange={setBeds("totalBeds")}
                fullWidth
              />
            </Grid>

            <Grid item container>
              <Toggle
                label="Include Available Beds"
                name="includeAvailableBeds"
                value={location.includeAvailableBeds}
                onChange={handleChange}
              />
            </Grid>

            <Grid item>
              <TextField
                label="Available Beds (Read Only)"
                name="availableBeds"
                type="number"
                InputProps={{
                  readOnly: true,
                }}
                value={location.totalBeds - location.usedBeds}
                fullWidth
              />
            </Grid>
            <Grid item>
              <CustomNumber
                label="Total Top Bunk Beds"
                value={location.totalTopBunkBeds}
                onChange={setBeds("totalTopBunkBeds")}
                fullWidth
              />
            </Grid>
          </Grid>
          {/* Used as a "submit" proxy for the Save button */}
          <button
            ref={addLocationEl}
            type="submit"
            style={{ display: "none" }}
          />
        </form>

        <WorkOrderList location={location} />

        {isAdmin && location.isPersisted() && (
          <Grid container justifyContent="center" sx={{ marginTop: 5 }}>
            <Button color="error" onClick={onDelete} startIcon={<DeleteIcon />}>
              Delete Location
            </Button>
          </Grid>
        )}
      </div>
    </>
  );
}
