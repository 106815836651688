import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Locations from "./Locations";
import AddLocation from "./AddLocation";
import EditLocation from "./EditLocation";

export default function LocationRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Locations />} />
      <Route path="/add" element={<AddLocation />} />
      <Route path="/view/:id" element={<EditLocation />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}
