import DatabaseObject, {
  DatabaseObjectConstructorType,
} from "./database-object";
import { WorkOrder } from "./work-order";

export interface WorkOrderPhotoConstructorType
  extends DatabaseObjectConstructorType {
  s3Key?: string;
  url?: string;
  caption?: string;
  workOrder?: WorkOrder;
  workOrderId?: number;
  file?: File;
}

export class WorkOrderPhoto extends DatabaseObject {
  s3Key?: string;
  url?: string;
  caption = "";
  workOrder?: WorkOrder;
  workOrderId?: number;

  file?: File;

  constructor(data?: WorkOrderPhotoConstructorType) {
    super(data);

    if (!data) return;

    if (data.s3Key) this.s3Key = data.s3Key;
    if (data.url) this.url = data.url;
    if (data.caption) this.caption = data.caption;
    if (data.workOrder) this.workOrder = new WorkOrder(data.workOrder);
    if (data.workOrderId) this.workOrderId = data.workOrderId;
    if (data.file) this.file = data.file;
  }
}
