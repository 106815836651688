import { useCallback } from "react";
import { WorkOrderComment } from "../classes";
import { useApi } from "../hooks";

export function useWorkOrderCommentsApi() {
  const { fetchPUT, fetchDELETE } = useApi();

  const updateWorkOrderComment = useCallback(
    async (id: number, body: object) => {
      const c = await fetchPUT(`work-order-comments/${id}`, body);
      return new WorkOrderComment(c);
    },
    [fetchPUT]
  );

  const deleteWorkOrderComment = useCallback(
    async (id: number) => {
      const c = await fetchDELETE(`work-order-comments/${id}`);
      return new WorkOrderComment(c);
    },
    [fetchDELETE]
  );

  return {
    updateWorkOrderComment,
    deleteWorkOrderComment,
  };
}
