import React, { useState, useEffect, useRef } from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";

import { Contractor } from "../classes/contractor";
import { handleTextChange } from "../utils/handlers";
import { useContractorsApi } from "../apis";

interface ContractorModalProps {
  active: boolean;
  closeModal: () => void;
  contractor: Contractor;
  onCallback: () => void;
}

export default function ContractorModal({
  active,
  closeModal,
  contractor: _contractor,
  onCallback,
}: ContractorModalProps) {
  const { addContractor, updateContractor, deleteContractor } =
    useContractorsApi();

  const [contractor, setContractor] = useState<Contractor>(_contractor);

  const addContractorEl = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    setContractor(_contractor);
  }, [_contractor]);

  const handleSave: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (contractor.isPersisted()) {
      await updateContractor(
        contractor.id as number,
        contractor.prepareForApi()
      );
    } else {
      await addContractor(contractor.prepareForApi());
    }

    closeModal();
    onCallback();
  };

  const handleDelete = async () => {
    if (!contractor.id) return;

    if (window.confirm("Are you sure you want to delete this?")) {
      await deleteContractor(contractor.id);

      closeModal();
      onCallback();
    }
  };

  return (
    <Dialog open={active} onClose={closeModal} fullWidth>
      <DialogTitle>Contractor</DialogTitle>
      <DialogContent dividers>
        <form onSubmit={handleSave}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Company Name"
                name="companyName"
                value={contractor.companyName ?? ""}
                onChange={handleTextChange(
                  "companyName",
                  contractor,
                  setContractor,
                  Contractor
                )}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Contact Person"
                name="contactPerson"
                value={contractor.contactPerson ?? ""}
                onChange={handleTextChange(
                  "contactPerson",
                  contractor,
                  setContractor,
                  Contractor
                )}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Email"
                name="email"
                value={contractor.email ?? ""}
                onChange={handleTextChange(
                  "email",
                  contractor,
                  setContractor,
                  Contractor
                )}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Address"
                name="address"
                value={contractor.address ?? ""}
                onChange={handleTextChange(
                  "address",
                  contractor,
                  setContractor,
                  Contractor
                )}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="City"
                name="city"
                value={contractor.city ?? ""}
                onChange={handleTextChange(
                  "city",
                  contractor,
                  setContractor,
                  Contractor
                )}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="State"
                name="state"
                value={contractor.state ?? ""}
                onChange={handleTextChange(
                  "state",
                  contractor,
                  setContractor,
                  Contractor
                )}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Zipcode"
                name="zipcode"
                value={contractor.zipcode ?? ""}
                onChange={handleTextChange(
                  "zipcode",
                  contractor,
                  setContractor,
                  Contractor
                )}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Phone Number"
                placeholder="(123)456-7890"
                name="phoneNumber"
                value={contractor.phoneNumber ?? ""}
                onChange={handleTextChange(
                  "phoneNumber",
                  contractor,
                  setContractor,
                  Contractor
                )}
                inputProps={{
                  type: "tel",
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Trade"
                name="trade"
                value={contractor.trade ?? ""}
                onChange={handleTextChange(
                  "trade",
                  contractor,
                  setContractor,
                  Contractor
                )}
                fullWidth
              />
            </Grid>

            <Grid item container justifyContent="center" xs={12}>
              <Button
                color="error"
                startIcon={<DeleteIcon />}
                onClick={handleDelete}
              >
                Delete
              </Button>
            </Grid>
          </Grid>

          {/* Used as a "submit" proxy for the DialogAction buttons */}
          <button
            ref={addContractorEl}
            type="submit"
            style={{ display: "none" }}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          startIcon={<SaveIcon />}
          onClick={() => addContractorEl.current?.click()}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
