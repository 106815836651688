import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled, { css } from "styled-components";
import useSpaceProps from "../utils/spacing-props";
import useWidthProps from "../utils/width-props";
import { colors, shadows } from "../constants";

const useTheme = ({ color = "green", outline, textOnly, iconOnly }) => {
  const theme = colors[color];

  if (textOnly || iconOnly) {
    return css`
      border: 1px solid transparent;
      background-color: transparent;
      color: ${theme[3]};

      &:disabled {
        color: ${colors.grey[6]} !important;
      }

      &:hover {
        color: ${theme[4]};
      }

      &:active {
        color: ${theme[2]};
      }
    `;
  }

  if (outline) {
    return css`
      border: 1px solid ${theme[3]};
      background-color: transparent;
      color: ${theme[3]};

      &:disabled {
        color: ${colors.grey[4]};
        border-color: ${colors.grey[4]};
      }

      &:hover {
        box-shadow: ${shadows[4]};
      }

      &:active {
        box-shadow: ${shadows[1]};
      }
    `;
  }

  return css`
    background-color: ${theme[3]};
    border: 1px solid ${theme[3]};
    color: ${colors.font.light};

    &:hover,
    &:active {
      background-color: ${theme[2]};
      border: 1px solid ${theme[2]};
    }

    &:hover {
      box-shadow: ${shadows[4]};
    }

    &:active {
      box-shadow: ${shadows[1]};
      background-color: ${theme[2]};
    }

    &:disabled {
      background-color: ${theme[6]};
      color: ${theme[4]};
      border: 1px solid ${theme[6]};
      box-shadow: ${shadows[2]};
    }
  `;
};

const Icon = styled(({ className, iconOnly, iconRight, ...props }) => {
  let newClassName = iconOnly ? `${className} icon-only` : className;
  if (iconRight) {
    newClassName = `${newClassName} icon-right`;
  } else {
    newClassName = `${newClassName} icon-left`;
  }

  return <FontAwesomeIcon className={newClassName} {...props} />;
})`
  &.icon-right {
    margin-left: 0.5rem;
  }

  &.icon-left {
    margin-right: 0.5rem;
  }

  &.icon-only {
    margin: 0;
  }
`;

const Button = styled(
  ({
    className,
    type = "button",
    icon,
    iconRight = false,
    iconOnly,
    textOnly,
    children,
    ...props
  }) => {
    const newClassName = `${className} ${iconOnly ? "icon-only" : ""}`;
    return (
      <button className={className} type={type} {...props}>
        {icon && !iconRight && <Icon icon={icon} iconOnly={iconOnly} />}

        {children}

        {icon && iconRight && (
          <Icon icon={icon} iconOnly={iconOnly} iconRight />
        )}
      </button>
    );
  }
)`
  ${useSpaceProps}
  ${useWidthProps}
  ${useTheme}

  align-items: center;
  border-radius: 0.25rem;
  cursor: pointer;
  display: flex;
  font-size: 0.875rem;
  font-weight: 500;
  outline: none;
  padding: 0.5rem 1rem;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.1s;

  &:disabled {
    cursor: not-allowed;
  }

  &.icon-only {
    padding: 0.5rem;
  }
`;

Button.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.object,
  iconOnly: PropTypes.bool,
  textOnly: PropTypes.bool,
  iconRight: PropTypes.object,
  children: PropTypes.node,
  onClick: PropTypes.func,
  type: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Button;
