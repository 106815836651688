export function formatPhoneNumber(phoneNumber?: string): string {
  if (!phoneNumber) return "";

  let _phoneNumber = phoneNumber;
  // Remove the +1 country code if it exists
  if (phoneNumber.startsWith("+1")) {
    _phoneNumber = phoneNumber.slice(2);
  }

  switch (_phoneNumber.length) {
    case 1:
    case 2:
    case 3:
      return `(${_phoneNumber}`;
    case 4:
    case 5:
    case 6:
      return `(${_phoneNumber.slice(0, 3)}) ${_phoneNumber.slice(3)}`;
    case 7:
    case 8:
    case 9:
    case 10:
      return `(${_phoneNumber.slice(0, 3)}) ${_phoneNumber.slice(
        3,
        6
      )}-${_phoneNumber.slice(6)}`;
    default:
      return _phoneNumber;
  }
}
