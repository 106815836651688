import React from "react";
import {
  Table,
  TableRow,
  TableBody,
  TableHeader,
  TableSelectedSectionButton,
  TableCell,
  TableSearchField,
  RowType,
} from "./index";

import { useNavigate } from "react-router-dom";
import { colors, headerLinkHeight, bottomNavigationHeight } from "../constants";
import { faEye } from "@fortawesome/free-solid-svg-icons";

import { Location } from "../classes/location";
import { TransformToTitleCase } from "../utils/casing-helpers";
import { Grid, Typography } from "@mui/material";

interface HeaderStatsProps {
  total: number;
  totalAvailableBeds: number;
}

const HeaderStats = ({ total, totalAvailableBeds }: HeaderStatsProps) => (
  <Grid
    container
    justifyContent="flex-end"
    gap={1}
    sx={{ backgroundColor: colors.grey[6], px: 1 }}
  >
    <Typography variant="body2">
      Available Beds: {totalAvailableBeds}
    </Typography>
    <Typography variant="body2">Count: {total}</Typography>
  </Grid>
);

const Header = () => (
  <>
    <TableCell basis="120px">Name</TableCell>
    <TableCell basis="60px">Gender</TableCell>
    <TableCell basis="110px">Phone Number</TableCell>
    <TableCell basis="300px">Address</TableCell>
    <TableCell basis="91px">Avail Beds</TableCell>
  </>
);

const EmptyView = () => (
  <Typography
    variant="h5"
    align="center"
    sx={{
      color: colors.grey[5],
      marginTop: 8,
    }}
  >
    No Location
  </Typography>
);

interface RecordProps {
  location: Location;
}

interface RowProps {
  row: RowType;
  selected: boolean;
  onClick: () => void;
  tableBodyRef: any;
}

const Row = ({ row, ...props }: RowProps) => (
  <TableRow row={row} SelectedSectionElem={SelectedSection} {...props}>
    <TableCell basis="120px" semiBold>
      {row.location?.name}
    </TableCell>
    <TableCell basis="60px" color={colors.grey[3]}>
      {row.location && TransformToTitleCase(row.location.gender)}
    </TableCell>
    <TableCell basis="110px" color={colors.grey[3]}>
      {row.location?.phoneNumber}
    </TableCell>
    <TableCell basis="300px" color={colors.grey[3]}>
      {row.location?.address}, {row.location?.city}, {row.location?.state}
    </TableCell>
    <TableCell basis="91px" color={colors.grey[3]}>
      {(row.location?.totalBeds ?? 0) - (row.location?.usedBeds ?? 0)}
    </TableCell>
  </TableRow>
);

interface SelectedSectionProps {
  row: RecordProps;
}

const SelectedSection = ({ row }: SelectedSectionProps) => {
  const navigate = useNavigate();

  const goTo = (path: string) => () => navigate(`${path}/${row.location.id}`);

  return (
    <TableSelectedSectionButton icon={faEye} onClick={goTo("view")}>
      View
    </TableSelectedSectionButton>
  );
};

interface LocationTableProps {
  rows: RecordProps[];
  total: number;
  totalAvailableBeds: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}

const LocationTable = ({
  rows,
  total,
  totalAvailableBeds,
  setPage,
  search,
  setSearch,
}: LocationTableProps) => {
  return (
    <Table
      style={{
        height: `calc(100vh - ${headerLinkHeight} - ${bottomNavigationHeight})`,
      }}
    >
      <TableSearchField
        value={search}
        onChange={(e) => {
          setPage(0);
          // If user selects from the existing search list,
          // value will be empty and textContent will have the search string
          e.target.value
            ? setSearch(e.target.value)
            : setSearch(e.target.textContent ?? "");
        }}
      />
      <HeaderStats total={total} totalAvailableBeds={totalAvailableBeds} />
      <TableHeader>
        <Header />
      </TableHeader>
      <TableBody
        rows={rows}
        total={total}
        RowElem={Row}
        EmptyViewElem={EmptyView}
        onLoadMore={() => setPage((page) => page + 1)}
      />
    </Table>
  );
};

export default LocationTable;
