import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";

const TimeField = styled(
  ({ className, label, name, date, onChange, disabled }) => {
    return (
      <div className={className}>
        <label>{label}</label>
        <DatePicker
          name={name}
          selected={date}
          onChange={onChange}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption="Time"
          dateFormat="h:mm aa"
          disabled={disabled}
          autoComplete="off"
        />
      </div>
    );
  }
)`
  font-size: 12px;
  margin: 8px 0;

  & .react-datepicker-wrapper {
    display: block;
  }

  & input {
    display: block;
    outline: none;
    width: calc(100% - 1em);
    font-size: 1rem;
    border: 1px solid #eeeeee;
    border-radius: 0.25rem;
    padding: 0.5em 0.5em;
  }

  & input:disabled {
    background-color: #eeeeee;
    cursor: not-allowed;
  }
`;

export default TimeField;
