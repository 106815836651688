import { css } from "styled-components";
import { breakpoints } from "../constants";

const DEFAULT_UNIT = "%";
const VALID_UNITS = {
  em: "em",
  ex: "ex",
  ch: "ch",
  rem: "rem",
  vw: "vw",
  vh: "vh",
  vmin: "vmin",
  vmax: "vmax",
  cm: "cm",
  "%": "%",
  mm: "mm",
  in: "in",
  px: "px",
  pt: "pt",
  pc: "pc",
};

const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);
const getValue = (value) => value.toString().replace(/[^-\d]/g, "");
const getUnit = (value) =>
  VALID_UNITS[value.toString().replace(/[-\d]/g, "")] || DEFAULT_UNIT;
const getValueWithUnit = (value) => getValue(value) + getUnit(value);

export default function useWidthProps(dimension) {
  return (props) => {
    const capitalizedDimension = capitalize(dimension);
    const mdDimension = `md${capitalizedDimension}`;
    const lgDimension = `lg${capitalizedDimension}`;
    if (!props[dimension] && !props[mdDimension] && !props[lgDimension])
      return null;

    return css`
      box-sizing: border-box;
      ${(props) =>
        props[dimension] &&
        css`
          ${dimension}: ${getValueWithUnit(props[dimension])};
        `}
      ${(props) =>
        props[mdDimension] &&
        css`
          @media (min-${dimension}: ${breakpoints.md}) {
            ${dimension}: ${getValueWithUnit(props[mdDimension])};
          }
        `}
    ${(props) =>
        props[lgDimension] &&
        css`
          @media (min-${dimension}: ${breakpoints.lg}) {
            ${dimension}: ${getValueWithUnit(props[lgDimension])};
          }
        `}
    `;
  };
}
