export interface DatabaseObjectConstructorType {
  id?: number;
  createdAt?: string;
  updatedAt?: string;
}
export default class DatabaseObject {
  id?: number;
  createdAt?: string;
  updatedAt?: string;

  constructor(data?: DatabaseObjectConstructorType) {
    if (!data) return;

    const { id, createdAt, updatedAt } = data;

    if (id) this.id = id;
    if (createdAt) this.createdAt = createdAt;
    if (updatedAt) this.updatedAt = updatedAt;
  }

  isPersisted() {
    return !!this.createdAt;
  }

  prepareForApi() {
    const obj = { ...this } as Partial<DatabaseObject>;

    delete obj.id;
    delete obj.createdAt;
    delete obj.updatedAt;

    return obj;
  }
}
