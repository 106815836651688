import React, { useState, useCallback } from "react";
import { useUsersApi } from "../apis";
import { useUserCommentsApi } from "../apis/user-comments";
import { UserComment } from "../classes";
import { CommentBase } from "./CommentsBase";

export function UserComments({
  userId: userId,
  disabled,
}: {
  userId?: number;
  disabled?: boolean;
}) {
  if (!userId) return null;

  const [comments, setComments] = useState<UserComment[]>([]);

  const { getUserComments } = useUsersApi();
  const { deleteUserComment } = useUserCommentsApi();
  const { addUserComment } = useUsersApi();
  const { updateUserComment } = useUserCommentsApi();

  const fetchComments = useCallback(async () => {
    const _comments = await getUserComments(userId);
    setComments(_comments.items);
  }, [getUserComments, userId]);

  const onDelete = useCallback(
    async (comment: UserComment) => {
      if (window.confirm("Are you sure you want to delete this?")) {
        await deleteUserComment(comment.id as number);
        fetchComments();
      }
    },
    [deleteUserComment, fetchComments]
  );

  const onSave = useCallback(
    async (item: UserComment) => {
      if (item.id === undefined) {
        await addUserComment(userId, item.prepareForApi());
      } else {
        await updateUserComment(item.id, item.prepareForApi());
      }
    },
    [addUserComment, updateUserComment, userId]
  );

  return (
    <CommentBase<UserComment>
      labelPlural="Comments"
      labelSingular="Comment"
      id={userId}
      disabled={disabled}
      items={comments}
      fetchItems={fetchComments}
      onDelete={onDelete}
      onSave={onSave}
      newClass={(item, text) =>
        new UserComment({
          ...item,
          comment: text ?? "",
        })
      }
      getTextFieldValue={(item) => item.comment}
    />
  );
}
