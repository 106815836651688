import DatabaseObject, {
  DatabaseObjectConstructorType,
} from "./database-object";

export interface CollectionItemConstructorType
  extends DatabaseObjectConstructorType {
  text?: string;
  price?: number;
  code?: string;
  type?: string;
  prevRentDueDate?: Date | string;
  rentDueDate?: Date | string;
}

export class CollectionItem extends DatabaseObject {
  text = "";
  price = 0;
  code = "";
  type = "";

  prevRentDueDate?: Date;
  rentDueDate?: Date;

  constructor(data?: CollectionItemConstructorType) {
    super(data);

    if (!data) return;

    if (data.text) this.text = data.text;
    if (data.price) this.price = data.price;
    if (data.code) this.code = data.code;
    if (data.type) this.type = data.type;

    if (data.prevRentDueDate)
      this.prevRentDueDate = new Date(data.prevRentDueDate);
    if (data.rentDueDate) this.rentDueDate = new Date(data.rentDueDate);
  }

  isProduct() {
    return this.type === COLLECTION_ITEM_TYPES.PRODUCT;
  }

  isCustomProduct() {
    return (
      this.type === COLLECTION_ITEM_TYPES.PRODUCT &&
      this.code === PRODUCT_CODES.CUSTOM
    );
  }

  isOther() {
    return this.type === COLLECTION_ITEM_TYPES.OTHER;
  }

  isDebt() {
    return this.type === COLLECTION_ITEM_TYPES.DEBT;
  }

  isPrepayment() {
    return this.type === COLLECTION_ITEM_TYPES.PREPAYMENT;
  }

  static makeFromOption(
    item: CollectionItem,
    option: {
      text: string;
      price: number;
      value: string;
      type: string;
    }
  ) {
    return new CollectionItem({
      ...item,
      text: option.text,
      price: option.price,
      code: option.value,
      type: option.type,
    });
  }

  static numberToArray(number: number) {
    return `${number}`.split("");
  }

  static arrayToNumber(array: string[]) {
    if (array.length === 0) return 0;
    return parseInt(array.join(""));
  }
}

export const COLLECTION_ITEM_TYPES = {
  ADDON: "ADDON",
  PRODUCT: "PRODUCT",
  OTHER: "OTHER",
  PREPAYMENT: "PREPAYMENT",
  DEBT: "DEBT",
};

export const ADDON_CODES = {
  UA__1000: "UA__1000",
  UA__2000: "UA__2000",
  LATE_FEE__2500: "LATE_FEE__2500",
  CHORE__000: "CHORE__000",
  CHORE__2000: "CHORE__2000",
  PROCESSING_FEE__5000: "PROCESSING_FEE__5000",

  // BPA not used anymore, but keeping here because of historical payments
  BPA_LATE_FEE__500: "BPA_LATE_FEE__500",
  BPA_LATE_FEE__1000: "BPA_LATE_FEE__1000",
};

export const PRODUCT_CODES = {
  MONTH_1__41000: "MONTH_1__41000",
  MONTH_1__50000: "MONTH_1__50000",
  MONTH_1__51000: "MONTH_1__51000",
  MONTH_1__8000: "MONTH_1__8000",
  MONTH_1__10000: "MONTH_1__10000",
  MONTH_1__75000__GEO: "MONTH_1__75000__GEO",
  MONTH_1__95000__GEO: "MONTH_1__95000__GEO",
  WEEK_1__11500: "WEEK_1__11500",
  WEEK_1__12500: "WEEK_1__12500",
  WEEK_1__13500: "WEEK_1__13500",
  WEEK_2__21000: "WEEK_2__21000",
  WEEK_2__25000: "WEEK_2__25000",
  WEEK_2__26000: "WEEK_2__26000",
  DAY_1__2000: "DAY_1__2000",
  DAY_1__2500: "DAY_1__2500",
  DAY_2__4000: "DAY_2__4000",
  DAY_2__5000: "DAY_2__5000",
  DAY_3__6000: "DAY_3__6000",
  DAY_3__7500: "DAY_3__7500",
  DAY_4__8000: "DAY_4__8000",
  DAY_4__10000: "DAY_4__10000",
  STORAGE_HALF_BAY_7500: "STORAGE_HALF_BAY_7500",
  STORAGE_FULL_BAY_10000: "STORAGE_FULL_BAY_10000",
  STORAGE_FULL_BAY_15000: "STORAGE_FULL_BAY_15000",
  CUSTOM: "CUSTOM",
};

export const OTHER_CODES = {
  EXISTING_DEBT: "EXISTING_DEBT",
  PARTIAL_PREPAYMENT: "PARTIAL_PREPAYMENT",
  PARTIAL_PREPAYMENT__GEO: "PARTIAL_PREPAYMENT__GEO",
  OTHER: "OTHER",
};

export const ADDON_OPTIONS = [
  {
    text: "UA - $20",
    value: ADDON_CODES.UA__2000,
    price: 2000,
    type: COLLECTION_ITEM_TYPES.ADDON,
  },
  {
    text: "Late Fee - $25",
    value: ADDON_CODES.LATE_FEE__2500,
    price: 2500,
    type: COLLECTION_ITEM_TYPES.ADDON,
  },
  {
    text: "Chore",
    value: ADDON_CODES.CHORE__000,
    price: 0,
    type: COLLECTION_ITEM_TYPES.ADDON,
  },
  {
    text: "Processing Fee - $50",
    value: ADDON_CODES.PROCESSING_FEE__5000,
    price: 5000,
    type: COLLECTION_ITEM_TYPES.ADDON,
  },
];

export const PRODUCT_OPTIONS = [
  {
    text: "1 Month - $510",
    value: PRODUCT_CODES.MONTH_1__51000,
    price: 51000,
    type: COLLECTION_ITEM_TYPES.PRODUCT,
  },
  {
    text: "1 Month - $750 (GEO)",
    value: PRODUCT_CODES.MONTH_1__75000__GEO,
    price: 75000,
    type: COLLECTION_ITEM_TYPES.PRODUCT,
  },
  {
    text: "1 Week - $135",
    value: PRODUCT_CODES.WEEK_1__13500,
    price: 13500,
    type: COLLECTION_ITEM_TYPES.PRODUCT,
  },
  {
    text: "2 Week - $260",
    value: PRODUCT_CODES.WEEK_2__26000,
    price: 26000,
    type: COLLECTION_ITEM_TYPES.PRODUCT,
  },
  {
    text: "1 Day - $25",
    value: PRODUCT_CODES.DAY_1__2500,
    price: 2500,
    type: COLLECTION_ITEM_TYPES.PRODUCT,
  },
  {
    text: "2 Day - $50",
    value: PRODUCT_CODES.DAY_2__5000,
    price: 5000,
    type: COLLECTION_ITEM_TYPES.PRODUCT,
  },
  {
    text: "3 Day - $75",
    value: PRODUCT_CODES.DAY_3__7500,
    price: 7500,
    type: COLLECTION_ITEM_TYPES.PRODUCT,
  },
  {
    text: "4 Day - $100",
    value: PRODUCT_CODES.DAY_4__10000,
    price: 10000,
    type: COLLECTION_ITEM_TYPES.PRODUCT,
  },
  {
    text: "Storage Half Bay - $75",
    value: PRODUCT_CODES.STORAGE_HALF_BAY_7500,
    price: 7500,
    type: COLLECTION_ITEM_TYPES.PRODUCT,
  },
  {
    text: "Storage Full Bay - $100",
    value: PRODUCT_CODES.STORAGE_FULL_BAY_10000,
    price: 10000,
    type: COLLECTION_ITEM_TYPES.PRODUCT,
  },
  {
    text: "Storage Full Bay - $150",
    value: PRODUCT_CODES.STORAGE_FULL_BAY_15000,
    price: 15000,
    type: COLLECTION_ITEM_TYPES.PRODUCT,
  },
  {
    text: "Custom Price & Date",
    value: PRODUCT_CODES.CUSTOM,
    type: COLLECTION_ITEM_TYPES.PRODUCT,
    price: 0,
  },
];

export const OTHER_OPTIONS = [
  {
    text: "Other",
    value: OTHER_CODES.OTHER,
    type: COLLECTION_ITEM_TYPES.OTHER,
    price: 0,
  },
];

export const PARTIAL_PREPAYMENT_OPTIONS = [
  {
    text: "Partial Prepayment",
    value: OTHER_CODES.PARTIAL_PREPAYMENT,
    type: COLLECTION_ITEM_TYPES.PREPAYMENT,
    price: 0,
  },
  {
    text: "Partial Prepayment (GEO)",
    value: OTHER_CODES.PARTIAL_PREPAYMENT__GEO,
    type: COLLECTION_ITEM_TYPES.PREPAYMENT,
    price: 0,
  },
];

export const DEBT_OPTIONS = [
  {
    text: "Existing Debt",
    value: OTHER_CODES.EXISTING_DEBT,
    type: COLLECTION_ITEM_TYPES.DEBT,
    price: 0,
    disabled: true,
  },
];

interface Option {
  text: string;
  value: string;
  type: string;
  price: number;
}

interface CollectionOptions {
  Addons: Option[];
  Products?: Option[];
  "Partial Prepayments": Option[];
  Debt?: Option[];
}

export const COLLECTION_OPTIONS: CollectionOptions = {
  Addons: ADDON_OPTIONS,
  Products: PRODUCT_OPTIONS,
  "Partial Prepayments": PARTIAL_PREPAYMENT_OPTIONS,
};

export function findOption(code: string) {
  let option = ADDON_OPTIONS.find((o) => o.value === code);
  if (option) return option;

  option = PRODUCT_OPTIONS.find((o) => o.value === code);
  if (option) return option;

  option = OTHER_OPTIONS.find((o) => o.value === code);
  if (option) return option;

  option = DEBT_OPTIONS.find((o) => o.value === code);
  if (option) return option;

  option = PARTIAL_PREPAYMENT_OPTIONS.find((o) => o.value === code);
  if (option) return option;

  return {
    text: "",
    value: "",
    type: "",
    price: 0,
  };
}
