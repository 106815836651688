import React from "react";
import { Grid, InputLabel, NativeSelect, OutlinedInput } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface PayScheduleItemProps {
  paySchedule1?: string;
  paySchedule2?: string;
  onChangePaySchedule1: React.ChangeEventHandler<HTMLSelectElement>;
  onChangePaySchedule2: React.ChangeEventHandler<HTMLSelectElement>;
  disabled: boolean;
}

export function PayScheduleItem({
  paySchedule1,
  paySchedule2,
  onChangePaySchedule1,
  onChangePaySchedule2,
  disabled,
}: PayScheduleItemProps) {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      wrap="nowrap"
    >
      <Grid item>
        <InputLabel htmlFor="paySchedule1">Pay Schedule</InputLabel>
      </Grid>
      <Grid
        item
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ maxWidth: 200 }}
      >
        <Grid item>
          <NativeSelect
            disabled={disabled}
            value={paySchedule1 || ""}
            onChange={onChangePaySchedule1}
            input={<OutlinedInput />}
            inputProps={{
              name: "paySchedule1",
              id: "paySchedule1",
            }}
          >
            <option value="">None</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
            <option value="13">13</option>
            <option value="14">14</option>
            <option value="15">15</option>
            <option value="16">16</option>
            <option value="17">17</option>
            <option value="18">18</option>
            <option value="19">19</option>
            <option value="20">20</option>
            <option value="21">21</option>
            <option value="22">22</option>
            <option value="23">23</option>
            <option value="24">24</option>
            <option value="25">25</option>
            <option value="26">26</option>
            <option value="27">27</option>
            <option value="28">28</option>
            <option value="29">29</option>
            <option value="30">30</option>
            <option value="30">30</option>
            <option value="31">31</option>
          </NativeSelect>
        </Grid>

        <Grid item>
          <CloseIcon />
        </Grid>

        <Grid item>
          <NativeSelect
            disabled={disabled}
            value={paySchedule2 || ""}
            onChange={onChangePaySchedule2}
            input={<OutlinedInput />}
            inputProps={{
              name: "paySchedule2",
              id: "paySchedule2",
            }}
          >
            <option value="">None</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
            <option value="13">13</option>
            <option value="14">14</option>
            <option value="15">15</option>
            <option value="16">16</option>
            <option value="17">17</option>
            <option value="18">18</option>
            <option value="19">19</option>
            <option value="20">20</option>
            <option value="21">21</option>
            <option value="22">22</option>
            <option value="23">23</option>
            <option value="24">24</option>
            <option value="25">25</option>
            <option value="26">26</option>
            <option value="27">27</option>
            <option value="28">28</option>
            <option value="29">29</option>
            <option value="30">30</option>
            <option value="30">30</option>
            <option value="31">31</option>
          </NativeSelect>
        </Grid>
      </Grid>
    </Grid>
  );
}
