import DatabaseObject, {
  DatabaseObjectConstructorType,
} from "./database-object";
import { Resident } from "./resident";

export interface ResidentFileConstructorType
  extends DatabaseObjectConstructorType {
  s3Key?: string;
  url?: string;
  fileType?: string;
  fileName?: string;
  caption?: string;
  resident?: Resident;
  residentId?: number;
  file?: File;
}

export class ResidentFile extends DatabaseObject {
  s3Key?: string;
  url?: string;
  fileType?: string;
  fileName?: string;
  caption = "";
  resident?: Resident;
  residentId?: number;

  file?: File;

  constructor(data?: ResidentFileConstructorType) {
    super(data);

    if (!data) return;

    if (data.s3Key) this.s3Key = data.s3Key;
    if (data.url) this.url = data.url;
    if (data.fileType) this.fileType = data.fileType;
    if (data.fileName) this.fileName = data.fileName;
    if (data.caption) this.caption = data.caption;
    if (data.resident) this.resident = new Resident(data.resident);
    if (data.residentId) this.residentId = data.residentId;
    if (data.file) this.file = data.file;
  }
}
