import { useCallback } from "react";
import {
  QueryParams,
  useApi,
  GetListInputType,
  GetListOutputType,
} from "../hooks";
import { Activity } from "../classes/activity";

export function useActivitiesApi() {
  const { fetchGETList, fetchGET, fetchPOST, fetchPUT, fetchDELETE } = useApi();

  const getActivity = useCallback(
    (id: number) => fetchGET(`activities/${id}`),
    [fetchGET]
  );

  const getActivities = useCallback(
    (queryParams: QueryParams) => fetchGETList("activities", queryParams),
    [fetchGETList]
  );

  const addActivity = useCallback(
    (body: object) => fetchPOST("activities", body),
    [fetchPOST]
  );

  const updateActivity = useCallback(
    (id: number, data: object) => fetchPUT(`activities/${id}`, data),
    [fetchPUT]
  );

  const deleteActivity = useCallback(
    (id: number) => fetchDELETE(`activities/${id}`),
    [fetchDELETE]
  );

  return {
    getActivity,
    getActivities,
    addActivity,
    updateActivity,
    deleteActivity,
  };
}

export interface GetActivitiesInputType extends GetListInputType {
  residentId?: number;
  search?: string;
  activityTypes?: string[] | string;
  locations?: number | number[];
  districts?: number | number[];
  dateTimeStart__gte?: string;
  dateTimeEnd__lte?: string;
}

export type GetActivitiesOutputType = GetListOutputType<Activity>;
