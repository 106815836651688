import React from "react";
import Auth from "@aws-amplify/auth";

import {
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import LogoutIcon from "@mui/icons-material/Logout";
import RefreshIcon from "@mui/icons-material/Refresh";

import { useMe } from "../store/me";

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
}

export default function SettingsDialog(props: SimpleDialogProps) {
  const { onClose, open } = props;

  const { me } = useMe();

  const logout = () => Auth.signOut();

  const refresh = () => window.location.reload();

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Hello {me?.firstName}</DialogTitle>
      <List>
        <ListItemButton onClick={refresh}>
          <ListItemIcon>
            <RefreshIcon />
          </ListItemIcon>
          <ListItemText primary="Refresh" />
        </ListItemButton>
        <ListItemButton onClick={logout}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItemButton>
        <ListItem>
          <ListItemText primary={process.env.REACT_APP_VERSION} />
        </ListItem>
      </List>
    </Dialog>
  );
}
