import React from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

import { Activity } from "../classes";

interface ActivityModalProps {
  active: boolean;
  closeModal: () => void;
  activity: Activity;
}

export default function ActivityModal({
  active,
  closeModal,
  activity,
}: ActivityModalProps) {
  return (
    <Dialog open={active} onClose={closeModal} fullWidth>
      <DialogTitle></DialogTitle>
      <DialogContent style={{ whiteSpace: "pre-line" }}>
        {activity.diff}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="text" onClick={closeModal}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
