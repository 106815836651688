import React from "react";
import styled, { css } from "styled-components";
import { colors } from "../constants";
import { Layout, Button } from "./index";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const Modal = styled(
  ({ closeModal, children, active, className, ...props }) => {
    const newClassName = `${className} ${active ? "active" : ""}`;
    return (
      <div className={newClassName} onClick={closeModal} {...props}>
        <ExitButton icon={faTimes} iconOnly />
        <Window onClick={(e) => e.stopPropagation()}>{children}</Window>
      </div>
    );
  }
)`
  position: absolute;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${colors.overlay};
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: 0.2s;

  &.active {
    visibility: visible;
    opacity: 1;
  }
`;

const ExitButton = styled(Button)`
  position: absolute;
  top: 1rem;
  right: 1rem;
`;

const ModalLayout = styled(Layout)`
  min-width: 300px;
`;

const Window = styled.div`
  background-color: ${colors.white};
  border-radius: 0.25rem;
  padding: 0.5rem;
`;

export { ModalLayout };

export default Modal;
