import { useResidentsApi, useResidentFilesApi } from "../apis";
import { ResidentFile } from "../classes/resident-file";
import { usePhotosGeneric } from "./usePhotosGeneric";

interface UseFilesProps {
  residentId?: number;
  callback?: () => void;
}

export function useFiles({ residentId, callback }: UseFilesProps) {
  const { getResidentFiles, addResidentFile } = useResidentsApi();
  const { deleteFile } = useResidentFilesApi();
  const saveNewFile = async (file: ResidentFile) => {
    if (residentId === undefined) throw new Error("Resident ID is required");
    if (!file.file) throw new Error("File is required");

    const fileData = new FormData();
    fileData.append("file", file.file);
    fileData.append("fileName", file.fileName ?? file.file.name);
    fileData.append("fileType", file.fileType || file.file.type);
    fileData.append("caption", file.caption);

    return addResidentFile(residentId, fileData);
  };

  const saveRemoveFile = async (file: ResidentFile) =>
    deleteFile(file.id as number);

  const {
    photos: files,

    addPhoto: addFile,
    removePhoto: removeFile,
  } = usePhotosGeneric<ResidentFile>({
    id: residentId,
    callback,
    fetchPhotosFunc: getResidentFiles,
    PhotoClass: ResidentFile,

    saveNewPhoto: saveNewFile,
    saveRemovePhoto: saveRemoveFile,
    saveUpdatePhoto: () => Promise.resolve(),
  });

  return {
    files,

    addFile,
    removeFile,
  };
}
