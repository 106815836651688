import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
} from "@mui/material";

import CreateIcon from "@mui/icons-material/Create";
import { Resident } from "../classes";
import { useResidentsApi } from "../apis/residents";

interface PaymentContractModalProps {
  active: boolean;
  closeModal: () => void;
  resident: Resident;
  onCallback: () => void;
}

export const PaymentContractModal = ({
  active,
  closeModal,
  resident: _resident,
  onCallback,
}: PaymentContractModalProps) => {
  const { updateResident } = useResidentsApi();
  const addCollectionEl = useRef<HTMLButtonElement>(null);

  const [paymentContract, setPaymentContract] = useState("");

  useEffect(() => {
    setPaymentContract(_resident.paymentContract || "");
  }, [_resident]);

  const setNotes: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setPaymentContract(e.target.value);
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    try {
      await updateResident(
        _resident.id as number,
        new Resident({
          ..._resident,
          paymentContract,
        }).prepareForApi()
      );

      closeModal();

      onCallback();
    } catch (e) {
      if (e instanceof Error) {
        alert(e.message);
      }
    }
  };

  return (
    <Dialog open={active} onClose={closeModal}>
      <DialogTitle>
        Payment Contract
        <Typography>
          {[_resident.lastName, _resident.firstName].filter(Boolean).join(", ")}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Notes"
            value={paymentContract}
            onChange={setNotes}
            sx={{
              marginTop: 0.5,
            }}
          />

          {/* Used as a "submit" proxy for the DialogAction buttons */}
          <button
            ref={addCollectionEl}
            type="submit"
            style={{ display: "none" }}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          startIcon={<CreateIcon />}
          onClick={() => addCollectionEl.current?.click()}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

PaymentContractModal.propTypes = {
  active: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  resident: PropTypes.instanceOf(Resident).isRequired,
};
