import React from "react";
import styled from "styled-components";
import { Grid, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import { colors, roundCorners } from "../constants";

const NavLinkStyled = styled(NavLink)`
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: uppercase;
  padding: 0.125rem 0.5rem;
  border: 1px solid ${colors.green[3]};
  background-color: ${colors.white};
  color: ${colors.grey[3]};

  transition: 0.1s;

  &:hover {
    background-color: ${colors.grey[6]};
  }

  &.active {
    background-color: ${colors.green[3]};
    color: ${colors.font.light};
  }

  &:first-child {
    border-radius: ${roundCorners[0]} 0 0 ${roundCorners[0]};
  }

  &:last-child {
    border-radius: 0 ${roundCorners[0]} ${roundCorners[0]} 0;
  }
`;

interface NavLinkTabProps {
  children: React.ReactNode;
  to: string;
}

export const NavLinkTab = ({ children, to }: NavLinkTabProps) => (
  <NavLinkStyled to={to}>
    <Typography variant="caption">{children}</Typography>
  </NavLinkStyled>
);

interface NavLinkTabGroupProps {
  children: React.ReactNode;
}

export const NavLinkTabGroup = ({ children }: NavLinkTabGroupProps) => (
  <Grid
    container
    justifyContent="center"
    alignItems="center"
    sx={{
      padding: 0.5,
      borderBottom: `1px solid ${colors.grey[5]}`,
    }}
  >
    {children}
  </Grid>
);
