import { useCallback } from "react";
import { useApi } from "../hooks";

export function usePhotosApi() {
  const { fetchPUT, fetchDELETE } = useApi();

  const updatePhoto = useCallback(
    (id: number, body: object) => fetchPUT(`photos/${id}`, body),
    [fetchPUT]
  );

  const deletePhoto = useCallback(
    (id: number) => fetchDELETE(`photos/${id}`),
    [fetchDELETE]
  );

  return {
    updatePhoto,
    deletePhoto,
  };
}
