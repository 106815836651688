import React, { useState, useCallback } from "react";
import { useResidentReportsApi, useResidentsApi } from "../apis";
import { ResidentReport } from "../classes";
import { CommentBase } from "./CommentsBase";

export function ResidentReports({
  residentId,
  disabled,
}: {
  residentId?: number;
  disabled?: boolean;
}) {
  const [reports, setReports] = useState<ResidentReport[]>([]);

  const { getResidentReports, addResidentReport } = useResidentsApi();

  const { deleteResidentReport, updateResidentReport } =
    useResidentReportsApi();

  const fetchReports = useCallback(async () => {
    if (!residentId) return;
    const _reports = await getResidentReports(residentId);
    setReports(_reports.items);
  }, [getResidentReports, residentId]);

  const onDelete = async (report: ResidentReport) => {
    if (window.confirm("Are you sure you want to delete this?")) {
      await deleteResidentReport(report.id as number);
      fetchReports();
    }
  };

  const onSave = async (item: ResidentReport) => {
    if (!residentId) return;

    if (item.id === undefined) {
      await addResidentReport(residentId, item.prepareForApi());
    } else {
      await updateResidentReport(item.id, item.prepareForApi());
    }
  };

  return (
    <CommentBase<ResidentReport>
      labelPlural="Reports"
      labelSingular="Report"
      id={residentId}
      disabled={disabled}
      items={reports}
      fetchItems={fetchReports}
      onDelete={onDelete}
      onSave={onSave}
      newClass={(item, text) =>
        new ResidentReport({
          ...item,
          report: text ?? "",
          residentId: residentId ?? 0,
        })
      }
      getTextFieldValue={(item) => item.report}
    />
  );
}
